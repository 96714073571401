<template>
  <v-dialog
    v-model="dialog"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
    overlay-opacity="0.90"
    :content-class="$vuetify.breakpoint.mdAndUp ? 'elevation-0' : 'dialogo--custom-fs'
      "
    scrollable
  >
    <v-card
      class="transparent"
      :rounded="$vuetify.breakpoint.smAndDown ? '0' : 'lg'"
    >
      <v-card-title
        class="white px-0 py-2"
        height="78"
      >
        <v-list-item
          style="max-width: 100%"
          class=""
        >
          <v-list-item-content>
            <v-list-item-title
              :class="$vuetify.breakpoint.smAndDown ? 'body-1' : 'text-h5'"
              class="font-weight-black"
            >Elige usuario</v-list-item-title>
          </v-list-item-content>
          <v-btn
            v-if="$vuetify.breakpoint.smAndDown"
            fab
            :height="$vuetify.breakpoint.smAndDown ? 48 : 68"
            :width="$vuetify.breakpoint.smAndDown ? 48 : 68"
            color="primary"
            @click="dialog = false"
            top
            right
          >
            <v-icon
              :size="$vuetify.breakpoint.smAndDown ? 36 : 44"
              color="black"
            >mdi-close</v-icon>
          </v-btn>
        </v-list-item>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text
        style="min-height: 400px"
        class="pa-2 pa-sm-4 blue-grey lighten-5"
      >
        <v-container
          fluid
          class=""
        >
          <v-row>
            <v-col
              class=""
              cols="12"
              md="8"
              lg="9"
              order="2"
              order-md="1"
            >
              <v-row class="pa-1">
                <v-col
                  cols="6"
                  sm="3"
                  v-for="(usuario, i) in usuarios"
                  :key="usuario.id"
                  :value="usuario.id"
                  class="pa-2"
                >
                  <v-card
                    class="text-center"
                    :class="eleccion && eleccion.id == usuario.id
                      ? 'white--text'
                      : 'black--text'
                      "
                    elevation="4"
                    @click="elegirUsuario(usuario)"
                    :color="eleccion && eleccion.id == usuario.id
                      ? 'success'
                      : 'primary'
                      "
                    rounded="lg"
                  >
                    <v-responsive
                      :aspect-ratio="1.618"
                      class="align-center justify-center body-1 font-weight-black text-uppercase"
                    >
                      {{ usuario.nombre }}
                      <v-icon
                        color="yellow"
                        v-if="usuario.tpvAdmin"
                      >mdi-star</v-icon>
                    </v-responsive>
                  </v-card>
                </v-col>

                <v-col
                  cols="6"
                  sm="3"
                  v-if="$store.state.auth.user.role == 'tpv-comandero'"
                  class="pa-2"
                >
                  <v-card
                    class="white--text text-center body-1 font-weight-black text-uppercase"
                    elevation="4"
                    @click="logout"
                    color="red"
                    rounded="lg"
                  >
                    <v-responsive
                      :aspect-ratio="1.618"
                      class="align-center justify-center"
                    >
                      Salir
                    </v-responsive>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <!-- <v-divider v-if="$vuetify.breakpoint.mdAndUp" vertical class="order-md-2"></v-divider> -->
            <v-col
              cols="12"
              md="4"
              lg="3"
              order="1"
              order-md="3"
            >
              <v-text-field
                :disabled="!eleccion"
                :readonly="$vuetify.breakpoint.mdAndUp"
                outlined
                hide-details
                solo
                label="Contraseña"
                pattern="[0-9]*"
                inputmode="numeric"
                maxlength="4"
                v-model="passwordUsuario"
                class="rounded-lg mb-0 mb-sm-4 title"
                height="64"
                @input="$v.passwordUsuario.$touch()"
                @blur="$v.passwordUsuario.$touch()"
                @keypress.native="inputOnKey"
                v-if="dialog == true"
                ref="passUsuarioInput"
                :error-messages="respuesta == 'error' ? 'Contraseña incorrecta' : ''
                  "
                :success-messages="respuesta == 'ok' ? 'Inicio correcto' : ''"
                :append-icon="respuesta == 'ok'
                  ? 'mdi-check-bold'
                  : respuesta == 'error'
                    ? 'mdi-close'
                    : ''
                  "
              ></v-text-field>

              <div
                :class="!eleccion ? 'disabled' : ''"
                v-if="$vuetify.breakpoint.mdAndUp"
              >
                <KeyboardNumMini
                  ref="keyboardRefMini"
                  @onChange="onChange"
                  @onKeyPress="onKeyPress"
                  :input="passwordUsuario"
                  :maxLength="4"
                />
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import KeyboardNumMini from "./KeyboardNumMini";
import moment from 'moment'
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  validations: {
    passwordUsuario: { required, maxLength: maxLength(4) },
  },
  components: {
    KeyboardNumMini,
  },
  data() {
    return {
      eleccion: null,
      mostrarError: false,
      passwordUsuario: "",
      respuesta: "",
    };
  },
  watch: {
    async passwordUsuario(v) {
      if (this.passwordUsuario.length == 4) {
        if (this.eleccion && this.eleccion.codigo == this.passwordUsuario) {
          const ip = await this.$store.dispatch('getIp');
          this.$store.dispatch('restaurantes/guardarUsuario', {
            ip: ip,
            ultimoLogin: moment().unix(),
            id: this.eleccion.id
          })
          this.respuesta = "ok";
          setTimeout(() => {
            this.$store.commit("usuarios/setUsuarioActual", this.eleccion);
            this.dialog = false;
          }, 1000);
        } else {
          this.respuesta = "error";
          setTimeout(() => {
            this.respuesta = "";
            this.passwordUsuario = "";
          }, 1000);
        }
      }
    },
    dialog(v) {
      if (!v) {
        this.eleccion = null;
        this.passwordUsuario = "";
        this.respuesta = "";
      }
    },
  },
  computed: {
    usuarios() {
      return this.$store.getters["usuarios/getUsuariosListado"];
    },
    dialog: {
      get() {
        return (
          !this.$store.getters["zCierreDialogGetters"] &&
          (this.$store.state.usuarios.eleccionUsuarioDialog ||
            (!this.$store.state.usuarios.usuarioActualObj.id &&
              this.$store.getters["auth/isComandero"]))
        );
      },
      set(v) {
        this.$store.commit("usuarios/setEleccionUsuarioDialog", v);
      },
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.passwordUsuario.$dirty) return errors;
      !this.$v.passwordUsuario.required && errors.push("Contraseña requerida.");
      !this.$v.passwordUsuario.maxLength &&
        errors.push("El password debe tener 4 caracteres como máximo.");
      return errors;
    },
  },
  methods: {
    ...mapActions({
      logout: "auth/firebaseLogout",
    }),
    async elegirUsuario(usuario) {
      setTimeout(() => {
        if (this.dialog) this.$refs.passUsuarioInput.focus();
      }, 500);
      this.eleccion = usuario;
      if (usuario.id === "sin-user") {
        this.$store.commit("usuarios/setUsuarioActual", usuario);
        this.dialog = false;
      } else {
        // var uid = firebase.auth().currentUser.uid;

        this.mostrarError = true;
        this.passwordUsuario = "";
      }
      return;
    },
    inputOnKey() {
      if (this.mostrarError) this.mostrarError = false;
      this.$refs.passUsuarioInput.focus();
    },
    onChange(input) {
      if (this.eleccion) {
        if (this.mostrarError) this.mostrarError = false;
        if (input.charAt(input.length - 1) == "C") {
          this.passwordUsuario = "";
        } else {
          this.passwordUsuario = input;

          setTimeout(() => {
            if (this.dialog) this.$refs.passUsuarioInput.focus();
          }, 100);
        }
      }
    },
    onKeyPress(button) {
      if (this.eleccion) {
        if (button == "C") {
          this.passwordUsuario = "";
          setTimeout(() => {
            this.$refs.passUsuarioInput.focus();
          }, 100);
        }
      }
    },
  },
};
</script>

<style lang="scss">
div.disabled {
  background: #ccc;
  /* for "disabled" effect */
  opacity: .5;
  pointer-events: none;
}

.btn--flex-column {
  .v-btn__content {
    flex-direction: column;
  }
}
</style>