<template v-if="productos && productos.length > 0">
  <div
    class=""
    :class="!editarProductoObj ? 'pt-0' : ''"
    :style="`height: ${$vuetify.breakpoint.smAndDown || !!pagoDividido
      ? 'calc(100% - 54px)'
      : '100%'
      };`"
  >


    <template v-if="editarProductoObj">
      <v-card
        class="transparent fill-height"
        elevation="0"
        :class="$vuetify.breakpoint.mdAndUp ? '' : 'rounded-0'"
      >
        <v-card-title class="rounded-t-lg py-0 align-start">
          <v-list-item
            class="pa-0"
            two-line
            style="max-width: 100%"
          >
            <v-list-item-content>

              <v-list-item-title class="align-self-start">
                <span class="font-weight-bold">
                  {{
                    !!editarProductoObj.nombreEs
                    ? editarProductoObj.nombreEs
                    : editarProductoObj.nombre
                  }}
                </span>
              </v-list-item-title>

              <v-list-item-subtitle
                class="mt-1"
                v-if="!!editarProductoObj.cantidadCobrada || !!editarProductoObj.descuentoPorcentaje"
              >
                <v-chip
                  v-if="editarProductoObj.descuentoPorcentaje"
                  small
                  :outlined="!editarProductoObj.descuentoPersonalizado"
                  :color="editarProductoObj.descuentoPersonalizado ? 'yellow darken-1' : 'pink'"
                  class="black--text border font-weight-bold mr-1"
                >
                  {{
                    (editarProductoObj.descuentoPorcentaje)
                  }}% <span class="ml-1 text-decoration-line-through">
                    {{
                      mostrarMoneda(editarProductoObj.precioProductoTotalPrevio) }}€
                  </span></v-chip>
                <v-chip
                  v-if="!!editarProductoObj.cantidadCobrada"
                  outlined
                  small
                  color="info"
                  class="white--text border  font-weight-bold"
                >Cobrado x{{ editarProductoObj.cantidadCobrada }}</v-chip>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action class="my-1 align-self-center">
              <v-list-item-action-text class="subtitle-1 font-weight-black black--text">
                {{
                  mostrarMoneda(
                    editarProductoObj.cantidad *
                    editarProductoObj.precioProductoTotalUnitario
                  )
                }}€
              </v-list-item-action-text>
              <v-chip
                small
                color="primary"
                class="black--text border"
                v-if="editarProductoObj.cantidad !== 1"
              >
                {{
                  mostrarMoneda(editarProductoObj.precioProductoTotalUnitario)
                }}€ x{{ editarProductoObj.cantidad }}</v-chip>
            </v-list-item-action>
          </v-list-item>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text
          style=""
          class="pa-3 grey lighten-4 fill-height"
        >
          <v-row
            no-gutters
            class=""
          >
            <v-col
              cols="12"
              v-if="editarProductoDescuento"
            >
              <v-row
                no-gutters
                class="mb-3"
                justify="center"
              >
                <v-col
                  cols="3"
                  class="px-1"
                  align="center"
                >
                  <v-alert
                    color="pink"
                    outlined
                    class="text-h5 white--text text-center  mb-1 px-0"
                    width="100%"
                  >
                    <span
                      label
                      class="text-h6 success--text font-weight-bold"
                      color="success"
                    >{{ - mostrarMoneda(editarProductoDescuentoCalculado) }} </span>

                  </v-alert>
                  <span class="subtitle-2 mt-3">Descuento</span>

                </v-col>
                <!-- <v-col
                  cols="3"
                  class="px-1"
                  align="center"
                >

                  <v-alert
                    color="pink"
                    outlined
                    class="text-h5 white--text text-center  mb-1 px-0"
                    width="100%"
                  >
                    <span
                      label
                      class="text-h6 success--text font-weight-bold"
                      color="success"
                    >{{ mostrarMoneda(editarProductoDescuentoCalculadoPrecio) }} </span>

                  </v-alert>
                  <span class="subtitle-2 mt-3">Unitario</span>

                </v-col> -->
                <v-col
                  cols="3"
                  class="px-1"
                  align="center"
                >
                  <v-alert
                    color="pink"
                    outlined
                    class="text-h5 white--text text-center  mb-1 px-0"
                    width="100%"
                  >
                    <span
                      label
                      class="text-h6 success--text font-weight-bold"
                      color="success"
                    >{{ mostrarMoneda(editarProductoDescuentoCalculadoPrecio * editarProductoObj.cantidad) }} </span>

                  </v-alert>
                  <span class="subtitle-2 mt-3">Total</span>

                </v-col>
                <v-col
                  cols="3"
                  class="px-1"
                  align="center"
                >
                  <v-alert
                    color="red"
                    @click="quitarDescuento"
                    outlined
                    style="cursor: pointer;"
                    class="text-h5 white--text text-center  mb-1 px-0"
                    width="100%"
                  >
                    <v-icon
                      :disabled="!editarProductoObj.descuentoPersonalizado"
                      color="red"
                      class="my-0"
                    >mdi-delete</v-icon>

                  </v-alert>
                  <span class="subtitle-2 mt-3">Quitar</span>

                </v-col>
                <v-col
                  cols="3"
                  class="px-1 text-center"
                  align="center"
                >
                  <v-alert
                    width="100%"
                    color="yellow darken-1"
                    class="text-h6 black--text text-center px-0"
                  >
                    {{ editarProductoDescuentoCantidad }}<span class="text-center ml-1 subtitle-2">%</span>
                  </v-alert>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              v-else
            >
              <v-row
                no-gutters
                class="mb-3"
              >
                <v-col
                  cols="3"
                  class="px-1"
                >
                  <v-btn
                    @click="activarDescuento()"
                    block
                    large
                    :outlined="!editarProductoObj.descuentoPorcentaje"
                    height="60"
                    :color="editarProductoObj.descuentoPersonalizado ? 'yellow' : 'pink'"
                    class="black--text rounded-lg"
                  >
                    <v-icon>mdi-sale-outline</v-icon>
                  </v-btn>
                </v-col>
                <v-col
                  cols="3"
                  class="px-1"
                >
                  <v-btn
                    @click="abrirOpcionesEditarProducto()"
                    block
                    large
                    height="60"
                    color="info white--text"
                    class="black--text rounded-lg"
                    :disabled="!editarProductoObj.puedeOpciones"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </v-col>
                <v-col
                  cols="3"
                  class="px-1"
                >
                  <v-btn
                    :disabled="editarProductoObj.cantidadCobrada > 0"
                    v-if="editarProductoObj.cantidad == 1 ||
                      !!editarProductoObj.porPeso
                      "
                    block
                    large
                    height="60"
                    class="black--text rounded-lg"
                    color="red lighten-1"
                    @click="borrar($store.state.pedidos.editarProductoObj)"
                  >
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn>
                  <v-btn
                    :disabled="editarProductoObj.cantidadCobrada ==
                      editarProductoObj.cantidad
                      "
                    v-else
                    block
                    large
                    height="60"
                    class="black--text rounded-lg"
                    color="primary"
                    @click="cambioProductoCantidad('menos')"
                  >
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                </v-col>
                <v-col
                  cols="3"
                  class="px-1"
                >
                  <v-btn
                    v-if="!editarProductoObj.porPeso"
                    block
                    large
                    height="60"
                    class="black--text rounded-lg"
                    color="primary"
                    @click="cambioProductoCantidad('mas')"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>

            <v-col
              cols="12"
              class="keyboard--transparent-bg"
            >
              <KeyboardNumerico
                keyboardClass="keyboard-cambio-carrito-integrado"
                noDividir
                ref="keyboardRefNumerico"
                @onChange="onChangeTecladoNuevoProductoNumerico"
                @onKeyPress="onKeyPressTecladoNuevoProductoNumerico"
                :input="cambioProductoPrecioInput"
              />
            </v-col>
            <template v-if="!editarProductoDescuento">
              <v-col
                cols="6"
                class="mt-4 px-1"
              >
                <v-btn
                  block
                  class="black--text"
                  :outlined="cambioProductoPrecioOrCantidad == 'cantidad'"
                  @click="cambiarOpcionPrecioCantidad('precio')"
                  :color="cambioProductoPrecioOrCantidad == 'precio' ? 'primary' : ''"
                >Precio</v-btn>
              </v-col>

              <v-col
                cols="6"
                class="mt-4 px-1"
              >
                <v-btn
                  :outlined="cambioProductoPrecioOrCantidad == 'precio'"
                  class="black--text"
                  block
                  @click="cambiarOpcionPrecioCantidad('cantidad')"
                  :color="cambioProductoPrecioOrCantidad == 'cantidad' ? 'primary' : ''"
                >Cantidad</v-btn>
              </v-col>
              <v-col
                cols="12"
                class="mt-4"
              >
                <v-textarea
                  hide-details
                  class="rounded-lg white"
                  outlined
                  label="Comentario cocina..."
                  v-model="editarProductoObj.comentarioCocina"
                  ref="comentariosProducto"
                  :rows="mostrarTeclado == true ? 3 : 1"
                  no-resize
                  @click="focusComentarioCocinaProducto"
                />
              </v-col>
            </template>
          </v-row>
        </v-card-text>
        <v-card-actions
          :class="$vuetify.breakpoint.mdAndUp ? 'rounded-b-lg' : ''"
          class="pa-2 card-actions--bottom"
        >
          <v-row no-gutters>

            <v-col
              cols="6"
              class="pr-1"
            >
              <v-btn
                @click="cancelarEdicion"
                block
                large
                class="black--text rounded-lg"
                color="red lighten-1"
              ><v-icon color="">mdi-arrow-left</v-icon></v-btn>
            </v-col>
            <v-col
              cols="6"
              class="pl-1"
            >
              <v-btn
                @click="cambioProductoGuardar"
                block
                large
                class="black--text rounded-lg"
                color="primary lighten-1"
              ><v-icon color="black">mdi-check</v-icon></v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </template>

    <!--MENU-->
    <template v-else-if="editarProductoMenuObj">
      <!--       <v-alert color="info" outlined rounded icon="mdi-info" v-if="false">
        Está editando el producto {{ editarProductoObj.nombre }}
      </v-alert> -->
      <v-card
        class="transparent fill-height"
        elevation="0"
        :class="$vuetify.breakpoint.mdAndUp ? '' : 'rounded-0'"
      >
        <v-card-title class="rounded-t-lg py-0 align-start">
          <v-list-item
            class="pa-0"
            two-line
            style="max-width: 100%"
          >
            <v-list-item-content>
              <v-list-item-title class="align-self-start">
                <span class="font-weight-bold">
                  {{ editarProductoMenuObj.nombre }}
                </span>
              </v-list-item-title>

              <v-list-item-subtitle
                :key="prod.id"
                v-for="prod in editarProductoMenuObjCalculado.productosMostrar"
              >
                <span>{{ prod.nombre }}: </span><span
                  class="font-weight-black"
                  v-if="prod.eleccion"
                >{{
                  prod.eleccion.nombre
                }}</span><v-icon
                  color="red"
                  small
                  v-else
                >mdi-close</v-icon>
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="!!editarProductoMenuObj.cantidadCobrada">
                <v-chip
                  small
                  color="info"
                  class="white--text border"
                >Cobrado x{{ editarProductoMenuObj.cantidadCobrada }}</v-chip>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action
              class="my-1 align-self-center"
              v-if="editarProductoMenuObjCalculado"
            >
              <v-list-item-action-text class="subtitle-1 font-weight-black black--text">
                {{
                  mostrarMoneda(
                    editarProductoMenuObjCalculado.cantidad *
                    editarProductoMenuObjCalculado.precioProductoTotalUnitario
                  )
                }}
                €
              </v-list-item-action-text>
              <v-chip
                small
                color="primary"
                class="black--text border"
                v-if="editarProductoMenuObj.cantidad !== 1"
              >
                {{
                  mostrarMoneda(
                    editarProductoMenuObjCalculado.precioProductoTotalUnitario
                  )
                }}€ x{{ editarProductoMenuObjCalculado.cantidad }}</v-chip>
            </v-list-item-action>
          </v-list-item>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text class="pa-3 grey lighten-4 fill-height">
          <v-row
            no-gutters
            class=""
          >
            <v-col
              cols="12"
              class=""
            >
              <v-row no-gutters>
                <v-col
                  cols="12"
                  class="mb-3 pr-1"
                  v-if="!!editarProductoMenuObj && $vuetify.breakpoint.smAndDown"
                >
                  <v-btn
                    @click="
                      drawerComandero = false"
                    block
                    large
                    color="info white--text"
                    class="black--text rounded-lg"
                  >
                    PRODUCTOS
                    <v-icon v-if="false">mdi-pencil</v-icon>
                  </v-btn>
                </v-col>
                <v-col
                  cols="5"
                  class=""
                  v-if="!editarProductoMenuObjCambiandoOpciones"
                >
                  <v-btn
                    block
                    large
                    class="black--text rounded-lg"
                    color="red lighten-1"
                    @click="eliminarMenu()"
                  >
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  class="px-1"
                  v-if="false && !!editarProductoMenuObjCambiandoOpciones"
                >
                  <v-btn
                    @click="abrirOpcionesEditarMenuOk()"
                    block
                    large
                    color="primary"
                    class="black--text rounded-lg"
                  >
                    OK
                    <v-icon>mdi-check</v-icon>
                  </v-btn>
                </v-col>
                <v-col
                  v-if="false"
                  cols="4"
                  class="px-1"
                >


                  <v-btn
                    :disabled="editarProductoMenuObj.cantidadCobrada > 0"
                    v-if="editarProductoMenuObj.cantidad == 1"
                    block
                    large
                    class="black--text rounded-lg"
                    color="red lighten-1"
                    @click="borrarMenu()"
                  >
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn>
                  <v-btn
                    :disabled="editarProductoMenuObj.cantidadCobrada ==
                      editarProductoMenuObj.cantidad
                      "
                    v-else
                    block
                    large
                    class="black--text rounded-lg"
                    color="primary"
                    @click="cambioProductoCantidad('menos')"
                  >
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                </v-col>
                <v-col
                  v-if="false"
                  cols="4"
                  class="px-1"
                >
                  <v-btn
                    block
                    large
                    class="black--text rounded-lg"
                    color="primary"
                    @click="cambioProductoCantidad('mas')"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              class="keyboard--transparent-bg"
              v-if="true || !editarProductoMenuObjCambiandoOpciones"
            >
              <KeyboardNumerico
                keyboardClass="keyboard-cambio-carrito-integrado"
                noDividir
                ref="keyboardRefNumerico"
                @onChange="onChangeTecladoNuevoProductoNumerico"
                @onKeyPress="onKeyPressTecladoNuevoProductoNumerico"
                :input="cambioProductoPrecioInput"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions
          :class="$vuetify.breakpoint.mdAndUp ? 'rounded-b-lg' : ''"
          class="pa-2 card-actions--bottom"
          v-if="true || !editarProductoMenuObjCambiandoOpciones"
        >
          <v-row>
            <v-col
              cols="6"
              md="6"
              class="pr-1"
            >
              <v-btn
                :disabled="editarProductoMenuObj.cantidadCobrada > 0"
                @click="eliminarMenu"
                block
                large
                class="black--text rounded-lg"
                color="red "
              ><v-icon
                  large
                  color="white"
                >mdi-delete-outline</v-icon></v-btn>
            </v-col>

            <v-col
              cols="6"
              md="6"
              class="pl-1"
            >
              <v-btn
                @click="cambioProductoMenuGuardar"
                block
                large
                class="black--text rounded-lg"
                color="primary"
              ><v-icon
                  large
                  color="black"
                >mdi-check</v-icon></v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </template>

    <template v-if="editarProductoInternoObj">
      <v-card
        class="transparent fill-height"
        elevation="0"
        :class="$vuetify.breakpoint.mdAndUp ? '' : 'rounded-0'"
      >
        <v-card-title class="rounded-t-lg py-0 align-start">
          <v-list-item
            class="pa-0"
            two-line
            style="max-width: 100%"
          >
            <v-list-item-content>
              <v-list-item-title class="align-self-start">
                <span class="font-weight-bold">
                  {{ editarProductoInternoObj.nombre }}
                </span>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action class="my-1 align-self-center">
              <v-list-item-action-text
                v-if="false"
                class="subtitle-1 font-weight-black black--text"
              >
                {{
                  mostrarMoneda(
                    editarProductoObj.cantidad *
                    editarProductoObj.precioProductoTotalUnitario
                  )
                }}€
              </v-list-item-action-text>
              <v-chip
                small
                color="primary"
                class="black--text border"
                v-if="editarProductoInternoObj.cantidad !== 1"
              >
                x{{ editarProductoInternoObj.cantidad }}</v-chip>
            </v-list-item-action>
          </v-list-item>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-4">
          <v-row
            justify="center"
            class="pt-lg-4"
          >
            <v-col cols="11">
              <v-row justify="end">
                <v-col
                  cols="4"
                  class="px-1"
                >
                  <v-btn
                    block
                    large
                    class="black--text rounded-lg"
                    color="red lighten-1"
                    @click="
                      borrar($store.state.internos.editarProductoInternoObj)
                      "
                  >
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn></v-col>
                <v-col
                  cols="4"
                  class="px-1"
                >
                  <v-btn
                    :disabled="editarProductoInternoObj.cantidad <= 1"
                    block
                    large
                    class="black--text rounded-lg"
                    color="primary"
                    @click="cambioProductoCantidad('menos')"
                  >
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                </v-col>
                <v-col
                  cols="4"
                  class="px-1"
                >
                  <v-btn
                    block
                    large
                    class="black--text rounded-lg"
                    color="primary"
                    @click="cambioProductoCantidad('mas')"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              class="keyboard--transparent-bg pt-0"
            >
              <KeyboardNumerico
                keyboardClass="keyboard-cambio-carrito-integrado"
                noDividir
                ref="keyboardRefNumerico"
                @onChange="onChangeTecladoNuevoProductoNumerico"
                @onKeyPress="onKeyPressTecladoNuevoProductoNumerico"
                :input="cambioProductoPrecioInput"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions
          :class="$vuetify.breakpoint.mdAndUp ? 'rounded-b-lg' : ''"
          class="pa-4 card-actions--bottom"
        >
          <v-row>
            <v-col
              cols="6"
              md="6"
            >
              <v-btn
                @click="cancelarEdicion"
                block
                large
                class="black--text rounded-lg"
                color="red lighten-1"
              ><v-icon
                  large
                  color="white"
                >mdi-close</v-icon></v-btn>
            </v-col>
            <v-col
              cols="6"
              md="6"
            >
              <v-btn
                @click="cambioProductoInternoGuardar"
                block
                large
                class="black--text rounded-lg"
                color="primary lighten-1"
              ><v-icon
                  large
                  color="black"
                >mdi-check</v-icon></v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </template>

    <swipe-list
      ref="list"
      :disabled="!enabled"
      :items="productosComputed"
      item-key="id"
      v-else-if="productosComputed.length >= 1 && noEditando"
    >
      <template v-slot="{ item, index, revealLeft, revealRight, close }">
        <!-- item is the corresponding object from the array -->
        <!-- index is clearly the index -->
        <!-- revealLeft is method which toggles the left side -->
        <!-- revealRight is method which toggles the right side -->
        <!-- close is method which closes an opened side -->
        <v-list-item
          :disabled="(editarProductoObj && !item.editando) || !!pagoDividido"
          class="py-0"
          two-line
          :class="!!item.menuId ? 'orange lighten-4' : ''"
          @click="clickProducto(revealRight, revealLeft, close, item, index)"
        >
          <v-list-item-content>
            <v-list-item-title class="align-self-start">
              <span
                class="font-weight-bold"
                :class="botonGrande ? 'text-h6' : ''"
              >
                {{ !!item.nombreTpv ? item.nombreTpv : item.nombre }}
              </span>

              <!--  <span v-if="item.cantidad > 1" class="text-caption"> (x{{ item.cantidad }}) </span> -->
            </v-list-item-title>

            <v-list-item-subtitle
              v-if="!!item.menuId && !!item.productosMostrar"
              v-for=" prod  in  item.productosMostrar "
            >
              <span>{{ prod.nombre }}: </span><span
                class="font-weight-black"
                v-if="prod.eleccion"
              >{{
                prod.eleccion.nombre
              }}</span><v-icon
                color="red"
                small
                v-else
              >mdi-close</v-icon>

            </v-list-item-subtitle>
            <v-list-item-subtitle
              class="pl-1"
              v-for="( op, i ) in  item.opcionesMostrar "
              :key="i"
            >
              {{ op.cantidad > 1 ? op.cantidad + "x" : "--" }}

              {{ op.key }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="mt-1">

            </v-list-item-subtitle>

            <v-list-item-subtitle
              v-if="!!item.cantidadCobrada && Array.isArray(item.opcionesMostrar) || item.descuentoPorcentaje && !item.menuId"
              :class="item.opcionesMostrar.length > 1 ? 'mt-1' : ''"
            >
              <v-chip
                v-if="item.descuentoPorcentaje"
                small
                :outlined="!item.descuentoPersonalizado"
                :color="item.descuentoPersonalizado ? 'yellow darken-1' : 'pink'"
                class="black--text border font-weight-bold mr-1"
              >
                {{
                  (item.descuentoPorcentaje)
                }}% <span class="ml-1 text-decoration-line-through">
                  {{
                    mostrarMoneda(item.precioProductoTotalPrevio) }}€
                </span></v-chip>
              <v-chip
                outlined
                v-if="item.cantidadCobrada"
                small
                color="info"
                class="border font-weight-bold"
              >Cobrado x{{ item.cantidadCobrada }}</v-chip>
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="!!item.menuId && (item.cantidadCobrada || item.descuentoPorcentaje)">
              <v-chip
                v-if="item.descuentoPorcentaje"
                small
                :outlined="!item.descuentoPersonalizado"
                :color="item.descuentoPersonalizado ? 'yellow darken-1' : 'pink'"
                class="black--text border font-weight-bold mr-1"
              >
                {{
                  (item.descuentoPorcentaje)
                }}% <span class="ml-1 text-decoration-line-through">{{
  mostrarMoneda(item.precioProductoTotalPrevio) }}€</span></v-chip>
              <v-chip
                v-if="item.cantidadCobrada"
                small
                outlined
                color="info"
                class="white--text border font-weight-bold"
              >Menú cobrado</v-chip>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action class="my-1 align-self-center">
            <v-list-item-action-text
              v-if="!item.interno"
              class="subtitle-1 font-weight-black black--text"
              :class="botonGrande ? 'text-h6' : ''"
            >{{ item.precioTotalMostrar }}€</v-list-item-action-text>
            <v-chip
              small
              color="primary"
              :class="botonGrande ? 'text-h6' : ''"
              class="black--text border"
              v-if="item.cantidad !== 1"
            >
              {{
                !!item.interno
                ? ""
                : item.precioProductoTotalUnitarioMostrar + " €"
              }}
              x{{ item.cantidad }}</v-chip>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
      </template>

      <template
        v-slot:right="{ item, close }"
        v-if="accion == 'deslizar'"
      >
        <v-col
          class="swipeout-action red darken-2"
          align="center"
          @click="borrar(item)"
        >
          <!-- place icon here or what ever you want -->
          <v-icon class="white--text">mdi-delete</v-icon>
        </v-col>
        <v-col
          class="swipeout-action blue lighten-2"
          align="center"
          @click="editar(item)"
        >
          <v-icon class="white--text">mdi-pencil</v-icon>
        </v-col>
        <v-col
          class="swipeout-action grey fill-height"
          align="center"
          @click="close"
        >
          <!-- place icon here or what ever you want -->

          <v-icon class="white--text">mdi-close</v-icon>
        </v-col>
      </template>
    </swipe-list>
    <v-row
      v-else-if="noEditando"
      align="center"
      justify="center"
      class="mx-0"
      style="height: 100%"
    >
      <v-col cols="10">
        <v-alert
          type="info"
          rounded="lg"
          border="left"
          elevation="2"
          outlined
        >
          Por favor, seleccione algún producto para empezar.
        </v-alert>
      </v-col>
    </v-row>
    <template v-if="!esconderComentarios && noEditando">
      <v-fade-transition
        origin="bottom center 0"
        mode="in-out"
        group
      >
        <div
          class="pa-2 comentario-contenedor"
          v-if="cuenta &&
            cuenta.comentariosCocinaMuestra &&
            productosComputed.length >= 1
            "
          :class="mostrarTeclado == true ? 'comentario-keyboard-activo pt-4' : ''
            "
          key="1"
        >
          <v-textarea
            outlined
            hide-details
            class="rounded-lg"
            v-model="comentarios"
            ref="comentarios"
            label="Comentario cocina"
            :rows="mostrarTeclado == true ? 3 : 1"
            no-resize
            @click="focusComentarioCocina"
            @click:clear="clearComentarioCocina"
            clearable
          />
        </div>
      </v-fade-transition>
    </template>
    <v-dialog
      v-model="motivoEliminarProductoDialog"
      max-width="600px"
    >
      <v-card><v-card-title>Seleccione motivo eliminación</v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item-group
              v-model="motivoEliminarProducto"
              color="primary"
            >
              <v-list-item
                v-for="( item, i ) in 
  $store.state.productos
    .motivosEliminarProducto
"
                :key="item.value"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list> </v-card-text><v-card-actions>
          <v-btn
            dark
            x-large
            color="red"
            @click="
              motivoEliminarProductoDialog = false;
            motivoEliminarProductoResolve(false);
            "
          >
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!motivoEliminarProducto"
            x-large
            color="primary"
            class="black--text"
            @click="
              motivoEliminarProductoDialog = false;
            motivoEliminarProductoResolve(true);
            "
          >
            Eliminar
          </v-btn>
        </v-card-actions></v-card></v-dialog>
  </div>
</template>

<script>
import { SwipeList, SwipeOut } from "vue-swipe-actions";
import KeyboardNumerico from "./KeyboardNumerico";
import { mapGetters, mapState } from "vuex";
import {
  mostrarIdioma,
  mostrarMoneda,
  isFloat,
  chunkArray,
  redondeo,
} from "@/utils/helper.utils.js";
require("vue-swipe-actions/dist/vue-swipe-actions.css");
export default {
  data() {
    return {
      cambioProductoPrecioOrCantidad: 'precio',

      motivoEliminarProductoDialog: false,
      motivoEliminarProductoResolve: null,
      motivoEliminarProductoReject: false,
      motivoEliminarProducto: null,
      cambioProductoPrecioInput: "0",
      cambioProductoCampoCambiando: "precioProductoTotalUnitario",
      cambioProductoDialog: false,

      //editarProductoObj: null,
      enabled: true,
    };
  },
  props: {
    productos: {
      require: true,
    },
    botones: {
      require: true,
      default: true,
    },
    accion: {
      require: false,
      default: "deslizar",
    },
    esconderComentarios: {
      require: false,
      default: false,
    },
    pagoDividido: {
      require: false,
      default: false,
    },
  },
  components: {
    SwipeOut,
    SwipeList,
    KeyboardNumerico,
  },
  watch: {
    editarProductoObj(v) {
      if (!v) {
        this.cambioProductoPrecioOrCantidad = 'precio'
        this.cambioProductoCampoCambiando = 'precioProductoTotalUnitario'
        this.editarProductoDescuento = false;
        this.editarProductoDescuentoCantidad = 10;
      }
    },
    cobroPorProductos(v) {
      this.cerrarTodo();
    },
    inputTeclado(v) {
      this.$store.state.teclado.campoEditarObj[
        this.$store.state.teclado.campoEditarPropiedad
      ] = v;
    },
    cambioProductoDialog(v) {
      if (!v) {
        this.$refs.keyboardRefNumerico.reset();
      } else {
        this.$store.commit("teclado/setLanzarNumerico");
      }
    },
    editarProductoDescuento(v) {
      if (v) {
        if (this.editarProductoObj.descuentoPersonalizado) {
          this.editarProductoDescuentoCantidad = this.editarProductoObj.descuentoPorcentaje;
        }
      }
    }
  },
  computed: {
    editarProductoObjPrecioProductoTotalUnitario() {
      return this.editarProductoObj.precioProductoTotalUnitarioPrevio ? this.editarProductoObj.precioProductoTotalUnitarioPrevio : this.editarProductoObj.precioProductoTotalUnitario
    },
    editarProductoDescuentoCalculadoPrecio() {
      return redondeo(this.editarProductoObjPrecioProductoTotalUnitario - this.editarProductoDescuentoCalculado)
    },
    editarProductoDescuentoCalculado() {
      return redondeo(this.editarProductoObjPrecioProductoTotalUnitario * this.editarProductoDescuentoCantidad / 100)
    },
    editarProductoDescuento: {
      get() { return this.$store.state.pedidos.editarProductoDescuento; },
      set(v) { this.$store.commit("pedidos/SET_EDITAR_PRODUCTO_DESCUENTO", v); },
    },
    editarProductoDescuentoCantidad: {
      get() { return this.$store.state.pedidos.editarProductoDescuentoCantidad; },
      set(v) { this.$store.commit("pedidos/SET_EDITAR_PRODUCTO_DESCUENTO_CANTIDAD", v); },
    },
    drawerComandero: {
      get() { return this.$store.state.pedidos.drawerComandero; },
      set(v) { this.$store.commit("pedidos/SET_DRAWER_COMANDERO", v); },
    },
    noEditando() {
      return !this.editarProductoInternoObj && !this.editarProductoMenuObj && !this.editarProductoObj
    },
    productosTodos() {
      return this.$store.getters["productos/PRODUCTOS_CATALOGO"].map((p) => {
        return {
          ...p,
          nombre: mostrarIdioma(p),
          nombreTpv: p.nombreTpv ? p.nombreTpv : mostrarIdioma(p),
          precioMostrar: p.precio ? mostrarMoneda(p.precio) : "0.00",
        };
      });
    },
    ...mapState({
      cobroPorProductos: (state) => state.teclado.cobroPorProductos,
    }),
    ...mapState(["configuracionTpv"]),

    editarProductoObj: {
      get() {
        return this.$store.state.pedidos.editarProductoObj;
      },
      set(v) {
        this.$store.commit("pedidos/setEditarProductoObj", v);
      },
    },
    editarProductoMenuObj: {
      get() {
        return this.$store.state.pedidos.editarProductoMenuObj;
      },
      set(v) {
        this.$store.commit("pedidos/setEditarProductoMenuObj", v);
      },
    },
    editarProductoMenuObjCambiandoOpciones: {
      get() {
        return this.$store.state.pedidos.editarProductoMenuObjCambiandoOpciones;
      },
      set(v) {
        this.$store.commit(
          "pedidos/SET_EDITAR_PRODUCTO_MENU_OBJ_CAMBIANDO_OPCIONES",
          v
        );
      },
    },
    editarProductoInternoObj: {
      get() {
        return this.$store.state.internos.editarProductoInternoObj;
      },
      set(v) {
        this.$store.commit("internos/setEditarProductoInternoObj", v);
      },
    },
    editarProductoMenuObjCalculado() {
      //cuando hay modificaciones
      const res = {
        ...this.editarProductoMenuObj,

        ...this.productosComputed.find(
          (pC) =>
            pC.idconcreto ==
            this.$store.state.pedidos.editarProductoMenuObj.idconcreto
        ),
      };
      return res;
    },
    comentarios: {
      get() {
        return this.pedidoEditandoObj &&
          this.pedidoEditandoObj.comentarioPedidoTpv
          ? this.pedidoEditandoObj.comentarioPedidoTpv
          : "";
      },
      set(v) {
        this.$store.commit("pedidos/setPedidoEditandoObjValor", {
          key: "comentarioPedidoTpv",
          value: v,
        });
      },
    },
    pedidoEditandoObj: {
      get() {
        return this.$store.state.pedidos.pedidoEditandoObj;
      },
      set(v) {
        this.$store.commit("pedidos/setPedidoEditandoObj", v);
      },
    },
    productosComputed() {
      // return this.$store.getters['pedidos/CARRITO'];

      if (Array.isArray(this.productos)) {
        var productosFinales = this.productos
          .filter(
            (p) =>
              p.cantidad > 0
            //|| !!this.pedidoEditandoObj.abonoPedidoOriginal
          ) // o tiene más de un producto o es un abono
          .map((p) => {
            let opcionesFinal = false;
            if (Array.isArray(p.opcionesName)) {
              let res = [];
              let arrayRecorrer = Array.isArray(p.opcionesNombreEs)
                ? p.opcionesNombreEs
                : p.opcionesName;
              arrayRecorrer.forEach((op) => {
                var index = res.findIndex((o) => o.key == op);
                if (index < 0) {
                  res.push({
                    key: op,
                    cantidad: 1,
                  });
                } else {
                  res[index].cantidad++;
                }
              });
              opcionesFinal = res;
            }
            var coincide = false;
            if (!!this.editarProductoObj) {
              // return e.id === producto.id && (e.opciones).toString() === (opciones).toString();
              var array2Sorted = this.editarProductoObj.opciones.slice().sort();
              coincide =
                p.id === this.editarProductoObj.id &&
                p.opciones.length === this.editarProductoObj.opciones.length &&
                p.opciones
                  .slice()
                  .sort()
                  .every(function (value, index) {
                    return value === array2Sorted[index];
                  });
            }

            //agrupamos productos menú

            return {
              ...p,
              precioTotalMostrar: p.precioProductoTotal
                ? mostrarMoneda(p.precioProductoTotal)
                : 0,
              precioProductoTotalUnitarioMostrar: p.precioProductoTotalUnitario
                ? mostrarMoneda(p.precioProductoTotalUnitario)
                : 0,
              cantidad: redondeo(p.cantidad),
              opcionesMostrar: opcionesFinal ? opcionesFinal : [],
              editando: !!coincide,
            };
          })
          .reverse();

        //agrupamos menu
        var productosMenu = [];
        var productosMenuTipo = {
          menuId: "",
          menuIdConcreto: "",
          nombre: "",
          precioProductoTotal: 0,
          precioProductoTotalUnitario: 0,
          productos: [],
        };
        productosFinales
          .filter((p) => !!p.menuId)
          .forEach((menu) => {
            let fI = productosMenu.findIndex(
              (m) => m.menuIdConcreto === menu.menuIdConcreto
            );
            if (fI > -1) {
              productosMenu[fI].productos.push(menu);
            } else {

              productosMenu.push({
                menuId: menu.menuId,
                menuIdConcreto: menu.menuIdConcreto,
                menuIdGrupo: menu.menuIdGrupo ? menu.menuIdGrupo : null,
                nombre: menu.menuCopy.nombre,
                precioProductoTotal: menu.menuCopy.precio,
                precioProductoTotalUnitario: menu.menuCopy.precio,
                precioTotalMostrar: mostrarMoneda(menu.menuCopy.precio),
                precioProductoTotalUnitarioMostrar: mostrarMoneda(
                  menu.menuCopy.precio
                ),
                productos: [menu],
              });
            }
          });

        return [
          ...productosMenu.map((menu) => {
            // console.log(this.pedidoEditandoObj.menus[0].idconcreto);

            let f = this.pedidoEditandoObj.menus
              ? this.pedidoEditandoObj.menus.find(
                (m) => m.idconcreto == menu.menuIdConcreto
              )
              : false;

            let fMenuMontado = this.$store.getters[
              "productos/MENUS_MONTADOS"
            ].find((m) => m.id == menu.menuId);

            let gruposMontados = fMenuMontado.grupos.map((g) => {
              let fGrupo = menu.productos.find((p) => p.menuIdGrupo == g.id);



              return {
                id: g.id,
                nombre: g.nombre.es,
                eleccion: fGrupo
                  ? { id: fGrupo.id, nombre: fGrupo.nombre }
                  : null,
              };
            });
            let productos = menu.productos.map((p) => {
              return {
                ...p,
                orden: gruposMontados.findIndex((g) => g.id == p.menuIdGrupo),
              };
            });
            let menusCobrados = (
              this.pedidoEditandoObj.cobradoDesglose
                ? this.pedidoEditandoObj.cobradoDesglose
                  .map((c) => {
                    return !!c.programado && !!c.programado.productos
                      ? c.programado.productos
                      : false;
                  })
                  .filter((p) => !!p)
                  .flat()
                  .filter(p => !!p.menuIdConcreto)
                : [])

            return {
              ...menu,
              ...f,
              productosMostrar: gruposMontados,
              // productosMostrar: menu.productos.map(
              //   (p) => p.cantidad + "x " + p.nombreTpv
              // ),
              precioTotalMostrar:
                f && f.precioProductoTotal
                  ? mostrarMoneda(f.precioProductoTotal)
                  : 0,
              precioProductoTotalUnitarioMostrar:
                f && f.precioProductoTotalUnitario
                  ? mostrarMoneda(f.precioProductoTotalUnitario)
                  : 0,
              cantidad: 1,
              esMenu: true,
              grupos: gruposMontados,
              cantidadCobrada: !!(menusCobrados.find(m => m.menuIdConcreto == menu.menuIdConcreto)) ? 1 : 0,
              menuCompleto:
                gruposMontados.filter((e) => !e.eleccion).length == 0,
            };
          }),
          ...productosFinales.filter((p) => !p.menuId),
        ];
      } else {
        return [];
      }
    },
    ...mapGetters(["cuenta", "botonGrande"]),
    mostrarTeclado: {
      get() {
        return this.$store.state.teclado.mostrarTeclado;
      },
      set(v) {
        if (v) {
          this.$store.dispatch("teclado/mostrarTeclado");
        } else {
          this.$store.dispatch("teclado/esconderTeclado");
        }
      },
    },
  },
  methods: {
    cambiarOpcionPrecioCantidad(opcion) {

      this.cambioProductoPrecioOrCantidad = opcion;
      this.cambioProductoCampoCambiando = opcion;
      if (opcion == 'precio')
        this.cambioProductoCampoCambiando = 'precioProductoTotalUnitario';
      this.editarProductoDescuentoCantidad = "0";
      this.$refs.keyboardRefNumerico.reset();
    },

    quitarDescuento() {
      if (!this.editarProductoObj.descuentoPersonalizado) return;
      this.editarProductoDescuentoCantidad = 0;
      this.cambioProductoGuardar();

      // this.editarProductoDescuento = null;
    },
    activarDescuento() {
      this.editarProductoDescuento = true;
    },
    mostrarMoneda(s) {
      return mostrarMoneda(s);
    },
    setCambioProductoCampoCambiando(campo) {
      this.cambioProductoCampoCambiando = campo;
      this.$refs.keyboardRefNumerico.reset();
    },
    cambioProductoCantidad(s) {
      if (Object.keys(this.pedidoEditandoObj).length !== 0) {
        if (s == "menos") {
          if (
            this.editarProductoObj.cantidad > 1 &&
            (!this.editarProductoObj.cantidadCobrada ||
              this.editarProductoObj.cantidadCobrada <
              this.editarProductoObj.cantidad)
          ) {
            this.editarProductoObj.cantidad--;
          }
        } else {
          this.editarProductoObj.cantidad++;
        }
      } else if (this.editarProductoInternoObj) {
        if (s == "menos") {
          this.editarProductoInternoObj.cantidad--;
        } else {
          this.editarProductoInternoObj.cantidad++;
        }
      }

    },
    onKeyPressTecladoNuevoProductoNumerico(button) {
      if (button == "C") {
        if (this.editarProductoDescuento) { //cambiando descuento
          setTimeout(() => {
            // this.cambioProductoObj[this.cambioProductoCampoCambiando] = "0";
            this.editarProductoDescuentoCantidad = "0";
            this.$refs.keyboardRefNumerico.reset();
          }, 100);
        } else if (this.editarProductoObj) {
          setTimeout(() => {
            // this.cambioProductoObj[this.cambioProductoCampoCambiando] = "0";
            this.editarProductoObj[this.cambioProductoCampoCambiando] = "0";
            this.$refs.keyboardRefNumerico.reset();
          }, 100);
        } else if (this.editarProductoInternoObj) {
          setTimeout(() => {
            // this.cambioProductoObj[this.cambioProductoCampoCambiando] = "0";
            this.editarProductoInternoObj.cantidad = 1;
            this.$refs.keyboardRefNumerico.reset();
          }, 100);
        }
      }
    },
    onChangeTecladoNuevoProductoNumerico(input) {
      if (this.editarProductoObj) {
        if (this.editarProductoDescuento) { //cambiando descuento
          const inputAux = (parseInt(input));
          if (inputAux > 0 && inputAux <= 100) {
            input = inputAux;
            // if (input < 0) input = 0;
            // if (input > 100) input = 100;
            if (isNaN(input)) input = '10'
            this.editarProductoDescuentoCantidad = input
          }

        } else if (this.cambioProductoCampoCambiando == "precioProductoTotalUnitario") {
          input = mostrarMoneda(parseInt(input) / 100);
          this.editarProductoObj[this.cambioProductoCampoCambiando] = input;
        } else if (this.cambioProductoCampoCambiando == "cantidad") {
          input = parseInt(input);
          this.editarProductoObj[this.cambioProductoCampoCambiando] = input;
        }

        if (isFloat(parseFloat(this.cambioProductoPrecioInput)))
          this.cambioProductoPrecioInput = "0";
      } else if (this.editarProductoMenuObj) {
        if (this.cambioProductoCampoCambiando == "precioProductoTotalUnitario")
          input = mostrarMoneda(parseInt(input) / 100);
        // this.cambioProductoObj[this.cambioProductoCampoCambiando] = input;
        this.editarProductoMenuObj[this.cambioProductoCampoCambiando] = input;
        if (isFloat(parseFloat(this.cambioProductoPrecioInput)))
          this.cambioProductoPrecioInput = "0";
      } else if (this.editarProductoInternoObj) {
        this.editarProductoInternoObj.cantidad = parseInt(input);
      }
    },
    cambioProductoMenuGuardar() {
      this.$store
        .dispatch("pedidos/cambiarCantidadCarritoMenu", {
          menu: this.editarProductoMenuObj,
          cantidad: this.editarProductoMenuObj.cantidad,
          precioProductoTotalUnitario: parseFloat(
            this.editarProductoMenuObj.precioProductoTotalUnitario
          ),
          comentarioCocina: this.editarProductoMenuObj.comentarioCocina,
        })
        .finally(() => {
          this.editarProductoMenuObj = null;
          this.$store.commit("productos/setPasoPidiendoProducto", 0);
          this.$store.commit("productos/setProductoPidiendoMenu", null);
        });
    },
    cambioProductoGuardar() {
      let objDescuento = {}
      if (this.editarProductoDescuento && this.editarProductoDescuentoCantidad == 0) {
        //quitar descuento

        objDescuento = {
          // precioProductoTotalUnitarioPrevio: this.editarProductoObj.precioProductoTotalUnitarioPrevio ? this.editarProductoObj.precioProductoTotalUnitarioPrevio : this.editarProductoObj.precioProductoTotalUnitario,
          // precioProductoTotalPrevio: this.editarProductoObj.precioProductoTotalPrevio ? this.editarProductoObj.precioProductoTotalUnitarioPrevio : this.editarProductoObj.precioProductoTotal,
          descuentoPorcentaje: this.editarProductoDescuentoCantidad,
          // descuentoCantidadUnitario: this.editarProductoDescuentoCalculado,
          // descuentoCantidad: redondeo(this.editarProductoDescuentoCalculado * this.editarProductoObj.cantidad),
          // precioProductoTotalUnitario: parseFloat(
          // this.editarProductoDescuentoCalculadoPrecio
          // ),
        }
      } else if (this.editarProductoDescuento) {
        objDescuento = {
          precioProductoTotalUnitarioPrevio: this.editarProductoObj.precioProductoTotalUnitarioPrevio ? this.editarProductoObj.precioProductoTotalUnitarioPrevio : this.editarProductoObj.precioProductoTotalUnitario,
          precioProductoTotalPrevio: this.editarProductoObj.precioProductoTotalPrevio ? this.editarProductoObj.precioProductoTotalUnitarioPrevio : this.editarProductoObj.precioProductoTotal,
          descuentoPorcentaje: this.editarProductoDescuentoCantidad,
          descuentoCantidadUnitario: this.editarProductoDescuentoCalculado,
          descuentoCantidad: redondeo(this.editarProductoDescuentoCalculado * this.editarProductoObj.cantidad),
          precioProductoTotalUnitario: parseFloat(
            this.editarProductoDescuentoCalculadoPrecio
          ),
        }
      }

      if (this.editarProductoObj) {
        this.$store
          .dispatch("pedidos/cambiarCantidadCarrito", {
            producto: this.editarProductoObj,
            cantidad: this.editarProductoObj.cantidad,
            precioProductoTotalUnitario: parseFloat(
              this.editarProductoObj.precioProductoTotalUnitario
            ),
            comentarioCocina: this.editarProductoObj.comentarioCocina,
            descuento: objDescuento
          })
          .finally(() => {
            this.editarProductoObj = null;
            this.cambioProductoDialog = false;
          });
      }
    },
    cambioProductoInternoGuardar() {
      this.$store
        .dispatch("internos/cambiarCantidadCarrito", {
          producto: this.editarProductoInternoObj,
          cantidad: this.editarProductoInternoObj.cantidad,
        })
        .finally(() => {
          this.editarProductoInternoObj = null;
          // this.cambioProductoDialog = false;
        });
    },
    clickProducto(revealRight, revealLeft, close, item, index) {
      console.log(item);
      if (this.accion == "deslizar") {
        //en pedido dialog

        if (item.esMenu) {
          this.editarMenu(item);
        } else if (item.interno) {
          this.editarInterno(item);
        } else {
          this.editar(item);
        }
        //this.cerrarTodo();
        //revealRight();
      } else {
        this.$emit("onClickProducto", item, index);
      }
    },
    cerrarTodo() {
      this.$refs.list.closeActions();
    },
    eliminarMenu() {
      Promise.all(
        this.productos
          .filter(
            (p) =>
              p.menuIdConcreto &&
              p.menuIdConcreto == this.editarProductoMenuObj.menuIdConcreto
          )
          .map((p) => {
            return this.$store.dispatch("pedidos/cambiarCantidadCarrito", {
              producto: p,
              cantidad: 0,
            });
          })
      )
        .then(() => {
          return this.$store.dispatch("pedidos/cambiarCantidadCarritoMenu", {
            menu: this.editarProductoMenuObj,
            cantidad: 0,
          });
        })
        .then(() => {
          this.editarProductoObj = null;
          this.editarProductoMenuObj = null;
          this.$store.commit("productos/setProductoPidiendoMenu", null);
          this.$store.commit("productos/setPasoPidiendoProducto", 0); //elección menú
        });
    },
    esperarConfirmacionBorrarProducto() {
      this.motivoEliminarProductoDialog = true;
      this.motivoEliminarProducto = null;
      return new Promise((resolve, reject) => {
        this.motivoEliminarProductoResolve = resolve;
        this.motivoEliminarProductoReject = reject;
      });
    },
    async borrar(item) {
      if (Object.keys(this.pedidoEditandoObj).length !== 0) {
        let r = !!this.configuracionTpv.mostrarMotivoEliminarProducto
          ? await this.esperarConfirmacionBorrarProducto()
          : true;

        if (r) {
          if (this.motivoEliminarProducto)
            item.eliminadoMotivo = this.motivoEliminarProducto;

          this.$store
            .dispatch("pedidos/cambiarCantidadCarrito", {
              producto: item,
              cantidad: 0,
            })
            .then(() => {
              this.editarProductoObj = null;
            });
        }
      } else if (this.editarProductoInternoObj) {
        this.$store
          .dispatch("internos/cambiarCantidadCarrito", {
            producto: item,
            cantidad: 0,
          })
          .then(() => {
            this.editarProductoInternoObj = null;
          });
      }
    },
    cambioProducto(item) {
      this.cambioProductoDialog = true;
      this.cambioProductoObj = JSON.parse(JSON.stringify(item));
    },
    editar(item) {
      this.cerrarTodo();
      this.editarProductoObj = { ...item };
      this.$store.commit('pedidos/SET_MOSTRAR_TODAS_OPCIONES', true); //mostrar todas opciones
      //this.cambioProductoObj = JSON.parse(JSON.stringify(item));
    },
    editarInterno(item) {
      this.cerrarTodo();
      this.editarProductoInternoObj = { ...item };

      //this.cambioProductoObj = JSON.parse(JSON.stringify(item));
    },
    editarMenu(item) {
      let fP = this.$store.getters["productos/PRODUCTOS_CATALOGO"].find(
        (p) => p.id == item.menuId
      );
      this.cerrarTodo();
      this.editarProductoMenuObj = item;

      this.$store.commit("productos/setProductoPidiendoMenu", {
        ...fP,
        nombre: mostrarIdioma(fP),
        idconcreto: item.menuIdConcreto,
      }); //aqui habrá que poner las opciones ya seleccionadas
      this.$store.commit("productos/setPasoPidiendoProducto", 2); //elección menú
      this.abrirOpcionesEditarMenu()
      //this.cambioProductoMenuObj = JSON.parse(JSON.stringify(item));
    },
    abrirOpcionesEditarMenu() {
      let f = this.$store.getters["productos/MENUS_CATALOGO"].find(
        (p) => p.id == this.editarProductoMenuObj.menuId && !!p.menu
      );
      this.$store.commit(
        "pedidos/SET_EDITAR_PRODUCTO_MENU_OBJ_CAMBIANDO_OPCIONES",
        f
      );
      // if (f) {
      //   this.$store.commit('productos/setProductoPidiendoMenu', {
      //     ...f,
      //     ...this.editarProductoMenuObj,
      //   });
      //   this.$store.commit('productos/setProductoPidiendoMenuDialog', true);
      // }
    },
    abrirOpcionesEditarMenuOk() {
      this.$store.commit(
        "pedidos/SET_EDITAR_PRODUCTO_MENU_OBJ_CAMBIANDO_OPCIONES",
        null
      );
      this.editarProductoMenuObj = null;
    },
    abrirOpcionesEditarProducto() {
      let fP = this.productosTodos.find(
        (p) => p.id === this.editarProductoObj.id
      );

      //fp.opciones contiene si tiene opciones. Puede ser que en carrito no estén pero tenga la opción
      if (fP.opciones && fP.opciones.length > 0) {
        this.$store.commit("productos/setProductoPidiendo", fP);

        this.editarProductoObj.opciones.map((op, i) => {
          let opFound = fP.opciones.find((o) => o.id == op);

          this.$store.commit("productos/addRemoveProductoPidiendoOpcion", {
            incompatibilidades: !!opFound ? opFound.incompatibilidades : [],
            nombreMostrar: this.editarProductoObj.opcionesName[i],
            //nombreTpv: op.nombreTpv,
            id: op,
            precioCobrar: this.editarProductoObj.opcionesPrecio[i],
            cantidad: 1,
            //panel: panel.id,
          });
        });

        this.$store.commit("productos/setPasoPidiendoProductoOpciones", 1);
        //this.opcionesProductoDialogo = true;
      } else {
        var f = this.$store.state.productos.categorias.find(
          (c) => c.id == v.categoria
        );
        if (f) {
          this.$store.commit("productos/setCategoriaPidiendo", {
            ...f,
            nombre: mostrarIdioma(f),
          });
          this.$store.commit("productos/setPasoPidiendoProducto", 1);
        }
      }
      this.$store.commit("productos/setOpcionesProductoDialogo", true);
    },

    cancelarEdicion() {
      this.editarProductoObj = null;
      this.editarProductoMenuObj = null;
      this.editarProductoMenuObjCambiandoOpciones = null;
      this.editarProductoInternoObj = null;
    },
    contentClick(e) { },
    itemDblClick(e) { },
    fbClick(e) { },
    sbClick(e) { },
    sumRest(item, accion) {
      this.$store.dispatch("pedidos/cambiarCantidadCarrito", {
        producto: item,
        cantidad: accion == "mas" ? item.cantidad + 1 : item.cantidad - 1,
      });
    },
    clearComentarioCocina() {
      this.comentarios = "";
      this.focusComentarioCocina();
    },
    focusComentarioCocina() {
      if (this.$store.state.necesarioTeclado) {
        setTimeout(() => {
          let pos = this.comentarios.length ? this.comentarios.length : 0;
          this.$refs.comentarios.$el
            .querySelector("textarea")
            .setSelectionRange(pos, pos);
        }, 200);
        var _vm = this;

        this.$store.commit("teclado/setMostrarTeclado", true);
        this.$store.commit("teclado/setActualValor", this.comentarios);
        this.$store.commit("teclado/setCampoEditarObj", null);

        this.$store.commit("teclado/setCampoEditarObj", this);
        this.$store.commit("teclado/setInput", "comentarios");
        this.$store.commit("teclado/setCampoEditarPropiedad", "comentarios");
      }
    },
    focusComentarioCocinaProducto() {
      if (this.$store.state.necesarioTeclado) {
        setTimeout(() => {
          let pos =
            this.editarProductoObj &&
              this.editarProductoObj.comentarioCocina.length
              ? this.editarProductoObj.comentarioCocina.length
              : 0;
          this.$refs.comentariosProducto.$el
            .querySelector("textarea")
            .setSelectionRange(pos, pos);
        }, 200);
        var _vm = this;

        this.$store.commit("teclado/setMostrarTeclado", true);
        this.$store.commit(
          "teclado/setActualValor",
          this.editarProductoObj.comentarioCocina
        );
        this.$store.commit("teclado/setCampoEditarObj", null);

        this.$store.commit("teclado/setCampoEditarObj", this.editarProductoObj);
        this.$store.commit("teclado/setInput", "comentarioCocina");
        this.$store.commit(
          "teclado/setCampoEditarPropiedad",
          "comentarioCocina"
        );
      }
    },
  },
};
</script>

<style lang="scss">
.swipeout {
  .swipeout-action {
    display: flex;
  }
}

.v-text-field {
  &.text-center {
    input {
      text-align: center;
    }
  }
}

.input--fake-solo,
.input--p0 {
  &.v-text-field--outlined {
    >.v-input__control {
      >.v-input__slot {
        fieldset {
          color: transparent !important;
          border: none !important;
        }
      }
    }
  }

  input {
    /* fix para Safari */
    max-height: none !important;
    padding: 0 !important;
  }
}

.comentario-contenedor {
  background: #fafafa;

  &.comentario-keyboard-activo {
    background: white;
  }
}

.comentario-keyboard-activo {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.card-actions--bottom {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid;
}

.keyboard--transparent-bg {
  .hg-theme-default {
    background-color: transparent;
  }
}
</style>
