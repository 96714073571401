import Vue from 'vue';
import store from '@/store';
import VueRouter from 'vue-router';
import Login from '../views/Login.vue';
import Tpv from '../views/Tpv.vue';
import Portada from '../views/Portada.vue';
import Mesas from '../views/Mesas.vue';
import Pedidos from '../views/Pedidos.vue';
import PedidosModo from '../views/PedidosModo.vue';
// import Domicilio from '../views/Domicilio.vue'
// import Recoger from '../views/Recoger.vue'
import Clientes from '../views/Clientes.vue';
//import Calendario from '../views/Calendario.vue'
import Productos from '../views/Productos.vue';
import ComanderosLista from '../views/ComanderosLista.vue';
import Configuracion from '../views/Configuracion.vue';
import Dispositivos from '../views/Dispositivos.vue';
import PedidosInternos from '../views/PedidosInternos.vue';
import Comandero from '../views/Comandero.vue';
import PedidosInternosForm from '../views/PedidosInternosForm.vue';
import GestionInterna from '../views/GestionInterna.vue';
import Notificaciones from '../views/Notificaciones.vue';
import diseno from '../views/diseno.vue';
import Inicio from '../views/Inicio.vue';
import InicioComandero from '../views/InicioComandero.vue';
import BusquedaPedidos from '../views/BusquedaPedidos.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/comandero/',
    name: 'Comandero',
    component: Comandero,
    meta: {
      logged: true,
    },
    children: [
      {
        path: 'pedidos/:modotipo/:modoid',
        name: 'Pedidos',
        component: PedidosModo,

        meta: {
          logged: true,
        },
      },
      {
        path: 'mesas/:modomesa',
        name: 'Mesas',
        component: Mesas,

        meta: {
          logged: true,
        },
      },
      {
        path: 'busquedapedido',
        name: 'Todos los pedidos',
        component: BusquedaPedidos,

        meta: {
          logged: true,
        },
      },
    ],
  },
  {
    path: '/inicio',
    component: Inicio,

    meta: {
      logged: true,
    },
  },
  {
    path: '/',
    redirect: '/dashboard',
    name: 'TPV',
    component: Tpv,
    children: [
      {
        path: '/dashboard',
        name: 'Opciones TPV',
        component: Portada,

        meta: {
          logged: true,
        },
      },
      {
        path: '/pedidos',
        name: 'Pedidos',
        component: Pedidos,

        meta: {
          logged: true,
        },
      },
      // {
      //   path: '/domicilio',
      //   name: 'Domicilio',
      //   component: Domicilio
      // },
      {
        path: '/pedidos/:modotipo/:modoid',
        name: 'Pedidos',
        component: PedidosModo,

        meta: {
          logged: true,
        },
      },
      {
        path: '/busquedapedidos/',
        name: 'Pedidos',
        component: BusquedaPedidos,

        meta: {
          logged: true,
        },
      },
      {
        path: '/mesas/:modomesa',
        name: 'Mesas',
        component: Mesas,

        meta: {
          logged: true,
        },
      },
      {
        path: '/clientes',
        name: 'Clientes',
        component: Clientes,

        meta: {
          logged: true,
        },
      },
      //      {
      //        path: '/calendario',
      //        name: 'Calendario',
      //        component: Calendario
      //      },
      {
        path: '/productos',
        name: 'Productos',
        component: Productos,

        meta: {
          logged: true,
        },
      },
      {
        path: '/comanderos',
        name: 'Comanderos',
        component: ComanderosLista,

        meta: {
          logged: true,
        },
      },
      {
        path: '/configuracion',
        name: 'Configuración',
        component: Configuracion,

        meta: {
          logged: true,
        },
      },
      {
        path: '/configuracion/dispositivos',
        name: 'Dispositivos',
        component: Dispositivos,

        meta: {
          logged: true,
        },
      },
      {
        path: '/pedidos-internos',
        name: 'Pedidos Internos',
        component: PedidosInternos,

        meta: {
          logged: true,
        },
      },
      {
        path: '/pedidos-internos/:accion',
        name: 'Añadir Pedido Interno',
        component: PedidosInternosForm,

        meta: {
          logged: true,
        },
      },
      {
        path: '/pedidos-internos/:accion/:idpedido',
        name: 'Pedido Interno',
        component: PedidosInternosForm,

        meta: {
          logged: true,
        },
      },

      {
        path: '/notificaciones',
        name: 'Listado notificaciones recibidas',
        component: Notificaciones,

        meta: {
          logged: true,
        },
      },
      {
        path: '/gestion-interna',
        name: 'Gestion Interna',
        component: GestionInterna,

        meta: {
          logged: true,
        },
      },
      {
        path: '/diseno',
        name: 'Diseño Componentes',
        component: diseno,
        meta: {
          logged: true,
        },
      },
    ],
  },
  {
    path: '/login',
    name: 'Fun Eats acceso TPV',
    component: Login,
    meta: {
      logged: false,
    },
  },
  {
    path: '/iniciocomandero/:rest/:idcomandero/:idintento',
    name: 'Iniciando sesion comandero',
    component: InicioComandero,
  },
  // {
  //   path: '*', redirect: '/',
  // },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  if (!store.state.loaded) {
    store.watch(
      () => store.getters.isLoaded,
      (value) => {
        if (value === true) {
          proceed(to, from, next);
        }
      }
    );
  } else {
    proceed(to, from, next);
  }

  function proceed(to, from, next) {
    document.title = to.name;
    if (to.meta.logged && !store.state.auth.isLogged) {
      next({ path: '/login' });
    } else if (to == '/') {
      // if (store.getters['auth/isComandero']) next({ path: '/comandero' });
      if (store.getters['auth/isTpv']) next({ path: '/dashboard' });
    } else {
      next();
    }
    store.commit('setPaginaPaginacionPedidos', 0);
  }
});

export default router;
