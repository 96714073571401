<template>
  <v-snackbar
    bottom
    left
    v-model="generarCatalogoSnackbar"
    color="primary"
    :timeout="0"
  >
    <v-expansion-panels
      flat
      light
    >
      <v-expansion-panel class="black--text">
        <v-expansion-panel-header color="primary">GENERANDO CATALOGO
          <span class="bold--text">
            ({{ generarCatalogoSegundos }})</span></v-expansion-panel-header>
        <v-expansion-panel-content color="primary">
          <v-card-actions><v-btn
              @click="generarCatalogo"
              text
              :loading="generarCatalogoLoading"
            >Generar ahora</v-btn></v-card-actions></v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-snackbar>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      generarCatalogoLoading: false,
    };
  },
  computed: {
    ...mapState({
      //generarCatalogoSnackbar: (state) => state.productos.generarCatalogoSnackbar,
      generarCatalogoSegundos: (state) =>
        state.productos.generarCatalogoSegundos,
    }),
    generarCatalogoSnackbar: {
      get() {
        return this.$store.state.productos.generarCatalogoSnackbar;
      },
      set(v) {
        this.$store.commit("productos/setGenerarCatalogoSnackbar", v);
      },
    },
  },
  methods: {
    generarCatalogo() {

      this.generarCatalogoLoading = true;
      this.$store
        .dispatch("productos/generarCatalogo")
        .finally(() => (this.generarCatalogoLoading = false));
    },
  },
};
</script>

<style></style>