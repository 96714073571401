var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"800","scrollable":"","fullscreen":_vm.$vuetify.breakpoint.smAndDown,"overlay-opacity":"0.90","content-class":_vm.$vuetify.breakpoint.mdAndUp
        ? 'mt-n4'
        : ''},model:{value:(_vm.infoProductoDialogo),callback:function ($$v) {_vm.infoProductoDialogo=$$v},expression:"infoProductoDialogo"}},[_c('v-card',{staticClass:"transparent",class:_vm.$vuetify.breakpoint.mdAndUp ? '' : 'rounded-0'},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-card-title',{staticClass:"py-6 transparent"},[_c('v-btn',{class:_vm.$vuetify.breakpoint.smAndDown ? 'mt-16' : 'mt-10 mr-n4',attrs:{"fab":"","height":_vm.$vuetify.breakpoint.smAndDown ? 48 : 88,"width":_vm.$vuetify.breakpoint.smAndDown ? 48 : 88,"color":"primary","absolute":"","right":""},on:{"click":function($event){_vm.infoProductoDialogo = false}}},[_c('v-icon',{attrs:{"size":_vm.$vuetify.breakpoint.smAndDown ? 36 : 54,"color":"black"}},[_vm._v("mdi-close")])],1)],1):_vm._e(),_c('v-card-title',{staticClass:"text--primary blue-grey lighten-5",class:_vm.$vuetify.breakpoint.smAndDown
              ? 'caption'
              : _vm.$vuetify.breakpoint.mdAndDown
              ? 'title rounded-t-lg'
              : 'display-1 rounded-t-lg'},[_vm._v(" INFORMACIÓN DEL PRODUCTO"),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-btn',{staticClass:"mr-n2",attrs:{"fab":"","height":"48","width":"48","color":"primary","absolute":"","right":""},on:{"click":function($event){_vm.infoProductoDialogo = false}}},[_c('v-icon',{attrs:{"size":"36","color":"black"}},[_vm._v("mdi-close")])],1):_vm._e()],1),_c('v-divider'),_c('v-card-text',{staticClass:"blue-grey lighten-5 rounded-b-lg"},[_c('v-row',{staticClass:"my-4"},[_c('v-col',{staticClass:"white rounded-lg d-flex align-center",attrs:{"offset":"1","cols":"3"}},[_c('v-img',{attrs:{"src":_vm.infoProductoObj.imagen}})],1),_c('v-col',{attrs:{"offset":"1","cols":"7"}},[_c('h1',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.infoProductoObj.nombreMostrar))]),_c('v-row',{staticClass:"mt-4"},[_c('v-col',{attrs:{"cols":"5","sm":"4","md":"3"}},[_vm._v("Precio:")]),_c('v-col',{attrs:{"cols":"7","sm":"8","md":"9"}},[_vm._v(_vm._s(_vm.infoProductoObj.precioMostrar)+"€")])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"5","sm":"4","md":"3"}},[_vm._v("Impuestos:")]),_c('v-col',{attrs:{"cols":"7","sm":"8","md":"9"}},[_vm._v(_vm._s(_vm.infoProductoObj.impPorcentaje)+"%")])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"5","sm":"4","md":"3"}},[_vm._v("Descripción:")]),_c('v-col',{attrs:{"cols":"7","sm":"8","md":"9"}},[_vm._v(_vm._s(_vm.infoProductoObj.descripcionMostrar))])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"5","sm":"4","md":"3"}},[_vm._v("Código:")]),_c('v-col',{attrs:{"cols":"7","sm":"8","md":"9"}},[_vm._v(_vm._s(_vm.infoProductoObj.codigoLector))])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"5","sm":"4","md":"3"}},[_vm._v("Categoría:")]),_c('v-col',{staticClass:"text-capitalize",attrs:{"cols":"7","sm":"8","md":"9"}},[_vm._v(_vm._s(_vm.infoProductoObj.categoria))])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }