import moment from 'moment-timezone';
import { mostrarMoneda, redondeo } from '@/utils/helper.utils.js';
import { quitarAcentos, idToTexto, lineaQz } from './utils';
import { comandosF, valoresTicketDinamico } from './comandos';

// create QRCode Image
async function getQRCode(content) {
  return new Promise((resolve, reject) => {
    const QRCode = require('qrcode');
    const { loadImage } = require('canvas');

    QRCode.toDataURL(content, (err, url) => {
      if (err) return reject(err);

      loadImage(url).then(resolve, reject);
    });
  });
}

export default async function (obj) {
  const { pedido, factSimplificada, logo, productosFinales, ticket } = obj;
  let devolverQz = [];
  const ticketDinamico = valoresTicketDinamico(ticket);
  const comandos = comandosF(ticket);
  let tieneDescuentoProductos = false;
  devolverQz.push(...[comandos.QZ_INIT]);

  if (logo) {
    devolverQz.push(comandos.QZ_INIT, comandos.QZ_ALIGN_CENTER, {
      type: 'raw',
      format: 'image',
      data: 'data:image/png;base64,' + logo,
      options: { language: 'ESCPOS', dotDensity: 'double' },
    });
  }

  devolverQz.push(comandos.QZ_INIT);
  devolverQz.push(comandos.QZ_LINEA);
  devolverQz.push(comandos.QZ_ALIGN_CENTER);

  const negocio = pedido.copyNegocio;
  const restaurante = pedido.copyRest;
  const timezone =
    restaurante &&
    restaurante.confGeneral &&
    restaurante.confGeneral.zonaHoraria
      ? restaurante.confGeneral.zonaHoraria
      : 'Europe/Madrid';

  var fechaMoment = pedido.fechaServidor.seconds
    ? moment.unix(pedido.fechaServidor.seconds).tz(timezone)
    : moment.unix(pedido.fechaServidorFinalEjecucion.seconds).tz(timezone);

  const formaPago = idToTexto(
    Array.isArray(factSimplificada.formaPago)
      ? factSimplificada.formaPago.join(', ')
      : factSimplificada.formaPago
  ).toUpperCase();

  devolverQz.push(comandos.QZ_ALIGN_CENTER);
  devolverQz.push(lineaQz(pedido.copyNegocio.nombre));
  devolverQz.push(lineaQz(pedido.copyRest.direccion));
  devolverQz.push(
    lineaQz(pedido.copyRest.cif + ' | ' + pedido.copyRest.telefono)
  );
  devolverQz.push(comandos.QZ_BOLD);

  devolverQz.push(comandos.QZ_FUENTE_NORMAL); //miriad undereline
  devolverQz.push(comandos.QZ_STOP_INVERTIR);
  devolverQz.push(comandos.QZ_LINEA);
  devolverQz.push(comandos.QZ_ALIGN_CENTER);
  devolverQz.push(comandos.QZ_BOLD);
  devolverQz.push(lineaQz('FACTURA SIMPLIFICADA'));
  devolverQz.push(
    lineaQz('NUMERO DE FACTURA: ' + factSimplificada.codigoFactura)
  );
  devolverQz.push(comandos.QZ_LINEA);
  devolverQz.push(comandos.QZ_ALIGN_LEFT);
  devolverQz.push(comandos.QZ_BOLD);
  devolverQz.push(
    lineaQz(
      'FECHA PEDIDO: ' +
        fechaMoment.format('DD/MM/YYYY') +
        ' - ' +
        fechaMoment.format('HH:mm')
    )
  );
  devolverQz.push(
    lineaQz(
      'FECHA FACTURA: ' +
        moment.unix(factSimplificada.moment).format('DD/MM/YYYY') +
        ' - ' +
        moment.unix(factSimplificada.moment).format('HH:mm')
    )
  );
  devolverQz.push(lineaQz('FORMA PAGO: ' + formaPago));

  devolverQz.push(comandos.QZ_ALIGN_LEFT);
  devolverQz.push(comandos.QZ_BOLD);
  devolverQz.push(comandos.QZ_LINEA_HORIZONTAL);
  devolverQz.push(comandos.QZ_ALIGN_LEFT);

  var impuestos = 0;

  // var productos = factSimplificada.productos.filter((p) => p.cantidad > 0);
  var productos = productosFinales.filter((p) => p.cantidad > 0);

  productosFinales
    .sort((a, b) =>
      a.ordenCat > b.ordenCat
        ? 1
        : b.ordenCat > a.ordenCat
        ? -1
        : a.orden > b.orden
        ? 1
        : -1
    )
    .forEach((prd) => {
      let s = '';
      if (prd.porPeso) {
        s =
          s +
          (prd.cantidad + 'kg ').padEnd(4) +
          quitarAcentos(!!prd.nombreEs ? prd.nombreEs : prd.nombre).substring(
            0,
            ticketDinamico.substring
          );
      } else {
        s =
          s +
          (prd.cantidad + 'x ').padEnd(4) +
          quitarAcentos(!!prd.nombreEs ? prd.nombreEs : prd.nombre).substring(
            0,
            ticketDinamico.substring
          );
      }

      s = s.padEnd(ticketDinamico.finLinea);
      let precioUnitario = !!prd.precioProductoTotalUnitarioPrevio
        ? prd.precioProductoTotalUnitarioPrevio
        : !!prd.precioProductoTotalUnitario
        ? prd.precioProductoTotalUnitario
        : prd.precio;
      precioUnitario = mostrarMoneda(precioUnitario, true).padStart(7);

      let precioTotal = !!prd.precioProductoTotalUnitarioPrevio
        ? prd.precioProductoTotalUnitarioPrevio * prd.cantidad
        : !!prd.precioProductoTotalUnitario
        ? prd.precioProductoTotalUnitario * prd.cantidad
        : prd.precio * prd.cantidad;
      impuestos = impuestos + redondeo(prd.impuestoCantidad * prd.cantidad);
      let precioTotalString = mostrarMoneda(precioTotal, true).padStart(7);
      s = s + precioUnitario + precioTotalString;
      devolverQz.push(lineaQz(s));
      if (prd.opciones) {
        let opcionesImpresas = [];
        prd.opciones.forEach((e, i) => {
          if (!opcionesImpresas.includes(e)) {
            let arrayUsar = Array.isArray(prd.opcionesNombreEs)
              ? prd.opcionesNombreEs
              : prd.opcionesName;
            opcionesImpresas.push(e);
            var ocurrencias = prd.opciones.reduce(
              (a, v) => (v === e ? a + 1 : a),
              0
            );
            let s = '';
            s =
              ocurrencias > 1
                ? '   ' + ocurrencias + '- ' + quitarAcentos(arrayUsar[i])
                : '   - ' + quitarAcentos(arrayUsar[i]);
            s = s.substring(0, ticketDinamico.substring + 4);
            devolverQz.push(lineaQz(s));
          }
        });
      }
      if (prd.descuentoPersonalizado) {
        let s = '';
        s = s + ' *** Descuento ' + prd.descuentoPorcentaje + '%';
        s = s.padEnd(ticketDinamico.finLinea);
        const descuentoCantidad = redondeo(
          prd.precioProductoTotalUnitarioPrevio -
            prd.precioProductoTotalUnitario
        );
        let precioUnitario = (
          '-' + mostrarMoneda(descuentoCantidad, true)
        ).padStart(7);
        let precioTotalString = (
          '-' + mostrarMoneda(descuentoCantidad * prd.cantidad, true)
        ).padStart(7);
        s = s + precioUnitario + precioTotalString;
        devolverQz.push(lineaQz(s));
      }
    });

  if (pedido.recargo && pedido.recargo > 0) {
    let s = '';
    // carritoString = carritoString + encoder.line(s)
    // res = res.concat(lineaEsc(s))
    s = '';
    s = s + '   Recargo domicilio ';
    s = s.padEnd(36);
    let precio = mostrarMoneda(pedido.recargo).padStart(5);
    s = s + precio;
    devolverQz.push(lineaQz(s));
  }

  const impuestosImprimir = factSimplificada.impuestosTotalesPago
    ? factSimplificada.impuestosTotalesPago
    : impuestos;
  let precioTotal = factSimplificada.pagado;
  // var carritoProductos = new Uint8Array(carritoString);
  var precioFinal = 'TOTAL: ' + mostrarMoneda(precioTotal);
  precioFinal = precioFinal;

  devolverQz.push(comandos.QZ_ALIGN_LEFT);
  devolverQz.push(comandos.QZ_LINEA_HORIZONTAL);
  devolverQz.push(comandos.QZ_ALIGN_RIGHT);
  devolverQz.push(comandos.QZ_FUENTE_MEDIANA);

  if (factSimplificada.formaPago == 'efectivo' && factSimplificada.entregado) {
    //sólo un pago
    devolverQz.push(
      lineaQz('ENTREGADO: ' + mostrarMoneda(factSimplificada.entregado, true))
    );
    devolverQz.push(
      lineaQz(' CAMBIO: ' + mostrarMoneda(factSimplificada.cambio, true))
    );
  }

  let baseImponibleImprimir = factSimplificada.baseImponiblePago
    ? factSimplificada.baseImponiblePago
    : parseFloat(precioTotal) - impuestosImprimir;
  devolverQz.push(comandos.QZ_BOLD_NORMAL);
  if (factSimplificada.descuentoPorcentaje || tieneDescuentoProductos) {
    devolverQz.push(
      lineaQz(
        'TOTAL SIN DESCUENTOS:' +
          mostrarMoneda(factSimplificada.precioTotalPrevio).padStart(10, ' ')
      )
    );
    devolverQz.push(
      lineaQz(
        'DESCUENTO ' +
          factSimplificada.descuentoPorcentaje +
          '%:' +
          ('-' + mostrarMoneda(factSimplificada.descuentoCantidad)).padStart(
            10,
            ' '
          )
      )
    );
  }
  devolverQz.push(
    lineaQz(
      'BASE IMPONIBLE:' + mostrarMoneda(baseImponibleImprimir).padStart(10, ' ')
    )
  );
  devolverQz.push(
    lineaQz('IMPUESTOS:' + mostrarMoneda(impuestosImprimir).padStart(10, ' '))
  );
  devolverQz.push(comandos.QZ_FUENTE_GRANDE);
  devolverQz.push(comandos.QZ_BOLD);
  devolverQz.push(lineaQz(precioFinal));
  devolverQz.push(comandos.QZ_LINEA);
  devolverQz.push(comandos.QZ_FUENTE_NORMAL);

  devolverQz.push(comandos.QZ_LINEA);
  devolverQz.push(comandos.QZ_ALIGN_CENTER);

  if (factSimplificada.generarQrUrl) {
    const qrcode = await getQRCode(factSimplificada.generarQrUrl);

    devolverQz.push(comandos.QZ_ALIGN_CENTER);
    devolverQz.push(lineaQz('DESCARGUE SU FACTURA'));
    devolverQz.push(comandos.QZ_INIT);
    devolverQz.push(comandos.QZ_INIT, comandos.QZ_ALIGN_CENTER, {
      type: 'raw',
      format: 'image',
      data: qrcode.src,
      options: { language: 'ESCPOS', dotDensity: 'double' },
    });
    devolverQz.push(comandos.QZ_ALIGN_CENTER);
    devolverQz.push(comandos.QZ_LINEA);
    devolverQz.push(comandos.QZ_LINEA);
    devolverQz.push(comandos.QZ_LINEA);
  }

  if (!!obj.qrIdPedido) {
    //para imprimir código QR con el ID pedido y poder escanearlo para ver o devoluciones
    // create QRCode Image

    const qrcodeID = await getQRCode(pedido.id);

    devolverQz.push(comandos.QZ_ALIGN_CENTER);
    devolverQz.push(lineaQz('Código pedido'));
    devolverQz.push(comandos.QZ_INIT);
    devolverQz.push(comandos.QZ_INIT, comandos.QZ_ALIGN_CENTER, {
      type: 'raw',
      format: 'image',
      data: qrcodeID.src,
      options: { language: 'ESCPOS', dotDensity: 'double' },
    });
    devolverQz.push(comandos.QZ_ALIGN_CENTER);
    devolverQz.push(comandos.QZ_LINEA);
    devolverQz.push(comandos.QZ_LINEA);
    devolverQz.push(comandos.QZ_LINEA);
  }

  devolverQz.push(lineaQz('Gracias por su pedido'));
  // devolverQz.push(lineaQz('By FunEats'));

  devolverQz.push(comandos.QZ_LINEA);
  devolverQz.push(comandos.QZ_LINEA);
  devolverQz.push(comandos.QZ_LINEA);
  devolverQz.push(comandos.QZ_LINEA);
  devolverQz.push(comandos.QZ_LINEA);
  devolverQz.push(comandos.QZ_LINEA);

  devolverQz.push(comandos.QZ_CORTE);
  devolverQz.push(comandos.QZ_INIT);

  return devolverQz;
}
