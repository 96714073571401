import { mostrarIdioma, mostrarMoneda } from '@/utils/helper.utils.js';
export default {
  //v3
  MENUS_FINAL(state) {
    return state.menus.map((m) => {
      return {
        ...m,
      };
    });
  },
  MENUS_MONTADOS(state, getters) {
    return getters.MENUS_FINAL.map((m) => {
      return {
        ...m,
        productosGrupos: m.grupos,
      };
    });
  },
  PRODUCTS_FINAL(state, getters, rootState) {
    return state.productos
      .filter((p) => !!p.individualPedible)
      .map((p) => {
        let fC = state.categorias.find((c) => c.id == p.categoria);
        return {
          ...p,
          categoryCopy: fC ? fC : false,
        };
      });
  },
  PRODUCTO_ES_MODIFICADOR(state, getters, rootState) {
    return state.productos.filter((p) => !!p.modificador);
  },

  PRODUCTO_OPCIONES_MONTADOS(state, getters) {
    return getters['PRODUCTO_ES_MODIFICADOR'].map((op) => {
      let paneles = getters['MODIFICADORES_CON_PRODUCTOS'].filter((m) =>
        m.opciones.map((o) => o.id).includes(op.id)
      );

      return {
        ...op,
        panelesUsado: paneles,
      };
    });
  },

  CATEGORIES_SELECT(state) {
    return state.categorias.map((c) => {
      return {
        value: c.id,
        text: this.$mostrarIdioma(c),
      };
    });
  },

  PRODUCTOS_MONTADOS(state, getters, rootState, rootGetters) {
    return getters['PRODUCTS_FINAL'].map((p) => {
      const secreto = state.productosSecreto.find((pSec) => pSec.id == p.id);
      let secretoAux = {};
      let almacenesAux = {};
      let stockTotal = 0;

      if (!!secreto)
        Object.keys(secreto).forEach((alm) => {
          //volcamos solo los almacenes, no las propiedades secretas del producto
          const f = !!rootGetters['ALMACENES'].find((a) => a.id == alm);
          if (f) {
            almacenesAux[alm] = secreto[alm];
            stockTotal = stockTotal + secreto[alm];
          } else secretoAux[alm] = secreto[alm];
        });

      var grupoFind = false;

      if (!!p.grupoModificador) {
        grupoFind = getters['GRUPOS_MODIFICADORES_MONTADOS'].find(
          (gM) => gM.id == p.grupoModificador
        );
      }
      let cat = state.categorias.find((c) => c.id == p.categoria);

      return {
        ...p,
        opcionesMontadas: !!grupoFind ? grupoFind : false,
        secreto: !!secretoAux ? secretoAux : {},
        stock: !!almacenesAux ? almacenesAux : {},
        stockTotal: stockTotal,
        ordenCat: cat ? cat.orden : null,
      };
    });
  },

  GRUPOS_MODIFICADORES_MONTADOS(state, getters) {
    return state.gruposModificadores
      .map((grupo) => {
        return {
          ...grupo,
          modificadores: Array.isArray(grupo.modificadores)
            ? grupo.modificadores.map((mod) => {
                let f = getters['MODIFICADORES_MONTADOS'].find(
                  (modMon) => modMon.id == mod.id
                );

                return !!f
                  ? {
                      ...f,
                      ...mod,
                      opciones: f.opciones
                        .map((op) => {
                          let fOpGrupo = mod.opciones.find(
                            (o) => o.id == op.id
                          );

                          return {
                            ...op,
                            ...(fOpGrupo ? fOpGrupo : {}),
                            incompatibilidades: op.incompatibilidades,
                          };
                        })
                        .filter((o) => !o.ocultar),
                    }
                  : false;
              })
            : [],
        };
      })
      .filter((m) => !!m);
  },
  MODIFICADORES_MONTADOS(state, getters) {
    return state.modificadores.map((mod) => {
      return {
        ...mod,
        opciones: Array.isArray(mod.opciones)
          ? JSON.parse(
              JSON.stringify(
                mod.opciones.map((op) => {
                  let opFind = getters['PRODUCTO_ES_MODIFICADOR'].find(
                    (m) => m.id == op.id
                  );

                  return opFind
                    ? {
                        ...JSON.parse(JSON.stringify(opFind)),
                        tarifas: op.tarifas,
                        incompatibilidades: Array.isArray(op.incompatibilidades)
                          ? op.incompatibilidades
                          : [],
                      }
                    : false;
                })
              )
            ).filter((op) => !!op)
          : [],
      };
    });
  },

  MODIFICADORES_CON_PRODUCTOS(state, getters) {
    return getters['MODIFICADORES_MONTADOS'].map((m) => {
      let f = getters['PRODUCTOS_MONTADOS'].filter((p) => {
        return (
          p.opcionesMontadas &&
          Array.isArray(p.opcionesMontadas.modificadores) &&
          p.opcionesMontadas.modificadores.map((p) => p.id).includes(m.id)
        );
      });

      return {
        ...m,
        productosTiene: f ? f.map((p) => p.id) : [],
        productosTieneMostrar: f
          ? f.map((p) => {
              return { name: p.name, nombre: p.nombre };
            })
          : [],
      };
    });
  },
  VALOR_FILTRAR_TARIFA(state, getters, rootState, rootGetters) {
    let modo = rootState.pedidos.pedidoEditandoObj.modo;
    let modoCopy = rootState.pedidos.pedidoEditandoObj.modoCopy;
    let canal = rootState.pedidos.pedidoEditandoObj.canalVenta;
    var valorFiltrar = '';
    if (canal == 'funeats' || canal == 'propio' || canal == 'directorio') {
      valorFiltrar = 'online-' + rootState.pedidos.pedidoEditandoObj.modo;

      if (
        rootState.pedidos.pedidoEditandoObj.modoCopy &&
        rootState.pedidos.pedidoEditandoObj.modoCopy.tarifa
      )
        valorFiltrar = rootState.pedidos.pedidoEditandoObj.modoCopy.tarifa;

      // return f ? f.productosFinales.filter((p) => !!p.activo) : [];
    } else if (canal == 'tpv') {
      if (
        rootState.pedidos.pedidoEditandoObj.modoCopy &&
        rootState.pedidos.pedidoEditandoObj.modoCopy.tarifa
      ) {
        let f = getters['PRODUCTOS_RESTAURANTE'].find(
          (pc) =>
            pc.tarifa == rootState.pedidos.pedidoEditandoObj.modoCopy.tarifa
        );
        valorFiltrar = rootState.pedidos.pedidoEditandoObj.modoCopy.tarifa;

        // return f ? f.productosFinales.filter((p) => !!p.activo) : [];
      } else {
        valorFiltrar = 'tpv-';
        let mesa = rootGetters['pedidos/mesaObjPedido'];

        valorFiltrar = mesa && mesa.catalogo ? mesa.catalogo : 'tpv-' + modo;
      }
    }

    return valorFiltrar;
  },
  PRODUCTOS_CATALOGO_SOLO(state, getters, rootState, rootGetters) {
    if (
      rootState.pedidos.pedidoEditandoObj &&
      rootState.pedidos.pedidoEditandoObj.modo
    ) {
      let encontradoCacheado = state.productosCatalogoCacheado.find(
        (t) => t.tarifa == getters.VALOR_FILTRAR_TARIFA
      );
      if (encontradoCacheado) {
        return encontradoCacheado.productos;
      }

      return getters.PRODUCTOS_MONTADOS.map((p) => {
        let tarifaFound = Array.isArray(p.tarifas)
          ? p.tarifas.find((t) => t.id == getters.VALOR_FILTRAR_TARIFA)
          : false;

        var impuestoAux = null;
        var impuestoCantidadAux = 0;
        if (tarifaFound) {
          let foundImp = state.impuestos.find(
            (i) => i.id == tarifaFound.impuesto
          );
          if (foundImp) {
            impuestoAux = foundImp.id;
            impuestoCantidadAux = foundImp.impuesto;
          }
        }
        let auxP = JSON.parse(JSON.stringify(p));
        delete auxP.tarifas;
        return {
          ...auxP,
          precio: tarifaFound ? parseFloat(tarifaFound.precio) : 0,
          impuesto: impuestoAux,
          impPorcentaje: impuestoCantidadAux,
          activo: tarifaFound ? tarifaFound.activo : false,
          opciones: p.opcionesMontadas
            ? p.opcionesMontadas.modificadores.map((m) => {
                let aux = JSON.parse(JSON.stringify(m));
                delete aux.tarifas;
                return {
                  ...aux,
                  opciones: Array.isArray(m.opciones)
                    ? m.opciones
                        .map((op) => {
                          let tarifaFoundOp = op.tarifas.find(
                            (t) => t.id == getters.VALOR_FILTRAR_TARIFA
                          );

                          return {
                            ...op,
                            precioCobrar: tarifaFoundOp
                              ? parseFloat(tarifaFoundOp.precio)
                              : 0,
                            precio: tarifaFoundOp
                              ? parseFloat(tarifaFoundOp.precio)
                              : 0,
                            impuesto: impuestoAux,
                            impPorcentaje: impuestoCantidadAux,
                            activo: tarifaFoundOp
                              ? tarifaFoundOp.activo
                              : false,
                          };
                        })
                        .filter((op) => op && op.activo && !op.ocultar)
                    : [],
                };
              })
            : false,
        };
      }).filter((p) => p.activo);
    }
    return [];
  },
  MENUS_CATALOGO(state, getters) {
    return getters.MENUS_FINAL.map((m) => {
      let tarifaFound = Array.isArray(m.tarifas)
        ? m.tarifas.find((t) => t.id == getters.VALOR_FILTRAR_TARIFA)
        : false;

      var impuestoAux = null;
      var impuestoCantidadAux = 0;
      if (tarifaFound) {
        let foundImp = state.impuestos.find(
          (i) => i.id == tarifaFound.impuesto
        );
        if (foundImp) {
          impuestoAux = foundImp.id;
          impuestoCantidadAux = foundImp.impuesto;
        }
      }
      return {
        ...m,
        precio: tarifaFound ? parseFloat(tarifaFound.precio) : 0,
        impuesto: impuestoAux,
        impPorcentaje: impuestoCantidadAux,
        activo: tarifaFound ? tarifaFound.activo : false,
        menu: true,
      };
    });
  },
  PRODUCTOS_CATALOGO(state, getters) {
    console.log('CAMBIO');
    return [...getters.PRODUCTOS_CATALOGO_SOLO, ...getters.MENUS_CATALOGO];
  },

  //fin v3

  PRODUCTOS_RESTAURANTE(state, getters, rootState) {
    let res = state.productosCatalogoCacheado.map((foundTarifa) => {
      return {
        tarifa: foundTarifa.tarifa,
        productosFinales: foundTarifa.productos.map((prdConstruido) => {
          return prdConstruido;
          // var foundPrdCat = !!cat.productos
          //   ? cat.productos.find((pCat) => pCat.id == prdGeneral.id)
          //   : false;
          var foundPrdBase = state.productosArray.find(
            (pBase) => pBase.id == prdGeneral.id
          );
          // if (foundPrdCat) {
          //   prdGeneral.activo = foundPrdCat.activo;
          //   prdGeneral.precio = parseFloat(foundPrdCat.precio);
          //   prdGeneral.particularRest = true;
          // }

          return {
            ...prdGeneral,
            ...(!!foundPrdBase ? foundPrdBase : {}),
            opciones: Array.isArray(prdGeneral.opciones)
              ? prdGeneral.opciones.map((panel) => {
                  return {
                    ...panel,
                    opciones: Array.isArray(panel.opciones)
                      ? panel.opciones
                          .map((op) => {
                            if (op.activo) return op;
                            else return false;
                          })
                          .filter((op) => !!op)
                      : [],
                  };
                })
              : [],
          };
        }),
        // .sort((a, b) => {
        //   console.log(a);
        //   return a.orden > b.orden ? 1 : -1;
        // }),
      };
    });

    return res;
  },
  PRODUCTOS_RESTAURANTE_LISTADO(state, getters, rootState) {
    var res = [];
    getters['PRODUCTOS_RESTAURANTE'].forEach((prdCat) => {
      prdCat.productosFinales.forEach((prd) => {
        let fIndex = res.findIndex((p) => p.id == prd.id);
        if (fIndex > -1) {
          res[fIndex].catalogo.push({
            catalogo: prdCat.catalogo,
            precio: prd.precio,
            activo: prd.activo,
          });
        } else {
          res.push({
            ...prd,
            opciones:
              prd.opciones && prd.opciones.length > 0
                ? prd.opciones.map((op) => {
                    return op;
                  })
                : [],
            catalogo: [
              {
                catalogo: prdCat.catalogo,
                precio: prd.precioCobrar,
                activo: prd.activo,
              },
            ],
          });
        }
      });
    });
    return res;
  },

  PRODUCTOS_CATALOGO_DEFECTO(state, getters, rootState, rootGetters) {
    // if (rootState.pedidos.pedidoEditandoObj && rootState.pedidos.pedidoEditandoObj.modo) {
    // let modo = rootState.pedidos.pedidoEditandoObj.modo;
    // let canal = rootState.pedidos.pedidoEditandoObj.canalVenta;
    // var valorFiltrar = '';
    // if ((canal == 'funeats') || (canal == 'propio') || (canal == 'directorio')) {
    //     valorFiltrar = 'online-' + rootState.pedidos.pedidoEditandoObj.modo;
    // } else if (canal == 'tpv') {
    //     valorFiltrar = 'tpv-';
    //     let mesa = rootGetters['pedidos/mesaObjPedido']
    //     valorFiltrar = (mesa && mesa.catalogo) ? mesa.catalogo : 'tpv-' + modo
    // }
    // valorFiltrar += rootState.pedidos.pedidoEditandoObj.modo;
    let valorFiltrar = 'tpv-mostrador';
    let f = getters['PRODUCTOS_RESTAURANTE'].find(
      (pc) => pc.catalogo == valorFiltrar
    );

    return f ? f.productosFinales.filter((p) => !!p.activo) : [];
    // }
    return [];
  },
  TARIFAS_EFECTIVAS(state) {
    return [...new Set(state.productosCatalogos.map((pC) => pC.tarifa))];
  },
  MENU_PIDIENDO(state, getters) {
    return !!state.productoPidiendoMenu
      ? {
          ...state.productoPidiendoMenu,
          grupos: state.productoPidiendoMenu
            ? state.productoPidiendoMenu.grupos.map((g) => {
                return {
                  ...g,
                  nombreMostrar: mostrarIdioma(g),
                  descripcionMostrar: mostrarIdioma(g, 'description'),
                  productosGrupo: g.productosGrupo
                    .map((p) => {
                      let foundP = getters['PRODUCTOS_CATALOGO_SOLO'].find(
                        (pG) => pG.id == p
                      );

                      if (!!foundP) {
                        return {
                          ...foundP,
                          nombre: mostrarIdioma(foundP),
                          nombreTpv: foundP.nombreTpv
                            ? foundP.nombreTpv
                            : mostrarIdioma(foundP),
                          precioMostrar:
                            foundP.precio && foundP.precio > 0
                              ? mostrarMoneda(foundP.precio)
                              : '0.00',
                        };
                      }
                    })
                    .filter((p) => !!p),
                };
              })
            : [],
        }
      : null;
  },
  MODIFICADORES(state, getters) {
    return state.modificadores.map((m) => {
      return {
        ...m,
        opciones: m.opciones
          .map((op) => {
            // console.log(op);
            let aux = { activo: false, precio: null };
            if (op.tarifas[0]) {
              aux.activo = op.tarifas[0].activo;
              aux.precio = op.tarifas[0].precio;
            }
            // console.log(state.productosCatalogos);
            if (
              state.productosCatalogos[0] &&
              state.productosCatalogos[0].productos
            ) {
              let fCatalogo = state.productosCatalogos[0].productos.find(
                (opCatalog) => opCatalog.id == op.id
              );
              // console.log(fCatalogo);
              if (fCatalogo) {
                aux.activo = fCatalogo.activo;
                aux.precio = fCatalogo.precio ? fCatalogo.precio : aux.precio;
              }
            }

            // console.log(state.modificadoresOpciones);
            let f = state.modificadoresOpciones.find((p) => p.id == op.id);
            // console.log(f);
            if (f) {
              return { ...op, ...f, ...aux };
            } else {
              return false;
            }
          })
          .filter((op) => !!op),
      };
    });
  },
};
