<template>
  <div
    :class="keyboardClass"
    ref="keyboardNumMini"
  ></div>
</template>

<script>
import Keyboard from "simple-keyboard";
import "simple-keyboard/build/css/index.css";

export default {
  name: "keyboardNumMini",
  props: {
    keyboardClass: {
      default: "keyboard-num-mini",
      type: String,
    },
    input: {
      type: String,
    },
    inputName: {
      type: String,
    },
    maxLength: 0,
  },
  data: () => ({
    keyboard: null,
  }),
  watch: {
    limpiarInput(v) {

      if (v) {

        this.reset();
      } else {
        this.$store.commit("teclado/setLimpiarInput", false);
      }
    },
  },
  computed: {
    limpiarInput() {
      return this.$store.teclado.limpiarInput;
    },
  },
  mounted() {
    this.keyboard = new Keyboard(this.keyboardClass, {
      onChange: this.onChange,
      onKeyPress: this.onKeyPress,
      layout: {
        default: ["1 2 3", "4 5 6", "7 8 9", "C 0 {bksp}"],
      },
      display: {
        "{bksp}": "⬅︎",
      },
      theme: "hg-theme-default hg-layout-numeric numeric-theme",
      buttonTheme: [
        {
          class: "rounded-lg font-weight-bold elevation-1 text-h5",
          buttons: "1 2 3 4 5 6 7 8 9 C 0 ⬅︎ {bksp}",
        },
        {
          class: "warning white--text",
          buttons: "C ⬅︎ {bksp}",
        },
      ],
      maxLength: this.maxLength,
    });
  },
  methods: {
    reset() {
      if (this.keyboard) this.keyboard.setInput("");
    },
    onChange(input) {
      this.$emit("onChange", input);
    },
    onKeyPress(button) {
      this.$emit("onKeyPress", button);

      /**
       * If you want to handle the shift and caps lock buttons
       */
      if (button === "{shift}" || button === "{lock}") this.handleShift();
    },
    handleShift() {
      let currentLayout = this.keyboard.options.layoutName;
      let shiftToggle = currentLayout === "default" ? "shift" : "default";

      this.keyboard.setOptions({
        layoutName: shiftToggle,
      });
    },
  },
  watch: {
    input(input) {

      this.keyboard.setInput(input);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" ></style>
