<template>
  <v-snackbar
    v-if="trabajosImpresion.length > 0 && false"
    bottom
    left
    v-model="impresionDialog"
    color="primary"
    :timeout="0"
  >
    <v-list
      flat
      color="transparent"
      class="black--text"
    >
      <span>Trabajos de impresión</span>
      <v-list-item
        v-for="(t, i) in trabajosImpresion"
        :key="i"
        class="black--text"
      >
        <span class="black--text">{{ t.nombre }}</span>
      </v-list-item>
    </v-list>
  </v-snackbar>
</template>

<script>
import { mapState } from "vuex";
export default {
  watch: {
    trabajosImpresionBruto: {
      handler: async function (v) {
        let array = v.filter((i) => !i.imprimiendo);
        if (array.length > 0) {
          let trabajoImprimir = array[0];

          trabajoImprimir.imprimiendo = true;

          let f =
            trabajoImprimir.dispositivo && trabajoImprimir.dispositivo.productId
              ? this.$store.state.configuracion.dispositivosEnUso.includes(
                trabajoImprimir.dispositivo.productId
              )
              : false;

          var tiempoEspera = 0;
          if (f) {
            tiempoEspera = 1000;
            /*this.$store.watch(
              () => this.$store.state.configuracion.dispositivosEnUso,
              (value) => {
                console.log("WATCH " + value);
                console.log(value);
                let f =
                  trabajoImprimir.dispositivo &&
                  trabajoImprimir.dispositivo.productId
                    ? value.includes(trabajoImprimir.dispositivo.productId)
                    : false;
                if (!f) {

                  this.ejecutarImpresion(trabajoImprimir);
                }
              }
            );*/
          }
          setTimeout(() => {
            this.ejecutarImpresion(trabajoImprimir);
          }, tiempoEspera);
        }
      },
    },
  },
  computed: {
    impresionDialog: {
      get() {
        return this.$store.state.impresion.impresionDialog;
      },
      set(v) {
        this.$store.commit("impresion/setImpresionDialog", v);
      },
    },
    ...mapState({
      trabajosImpresionBruto: (state) => state.impresion.trabajosPorImprimir,
    }),
    trabajosImpresion() {
      return this.trabajosImpresionBruto
        .filter((i) => !i.imprimiendo)
        .splice(0, 2);
    },
  },
  methods: {
    ejecutarImpresion(trabajoImprimir) {
      this.$store.commit("impresion/marcarImprimiendoPrimero");
      if (
        !!trabajoImprimir &&
        !!trabajoImprimir.dispositivo &&
        !!trabajoImprimir.dispositivo.printnodeImpresora
      ) {
        return this.$store.dispatch(
          "impresion/ejecutarImpresionPN",
          trabajoImprimir
        );
      } else {

        return this.$store.dispatch(
          "impresion/ejecutarImpresion",
          trabajoImprimir
        );
      }
    },
  },
};
</script>

<style></style>