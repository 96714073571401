<template>
  <v-dialog
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
    overlay-opacity="0.90"
    content-class="mt-n4 dialogo--custom-fs"
    v-model="zcierreDialogMostrar"
    scrollable
    max-width="912"
  >
    <v-card class="transparent">
      <v-card-title
        v-if="$vuetify.breakpoint.mdAndUp"
        class="py-6 transparent"
      >
      </v-card-title>
      <v-card-title
        :class="$vuetify.breakpoint.smAndDown
          ? 'caption'
          : $vuetify.breakpoint.mdAndDown
            ? 'title'
            : 'display-1'
          "
        class="text--primary rounded-t-lg blue-grey lighten-5"
      >INICIO DIARIO
      </v-card-title>
      <v-card-subtitle class="blue-grey lighten-5 body-1 font-weight-light py-2">
        <!-- Inicio del día de curro -->
        Hora de inicio: <strong>{{ inicioMostrar }}</strong>
      </v-card-subtitle>
      <v-divider></v-divider>
      <v-card-text class="pt-4 blue-grey lighten-5 border">
        <!--         <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-text-field readonly label="Inicio" :value="inicioMostrar" />
          </v-col>
        </v-row> -->
        <v-row>
          <v-col
            cols="12"
            md="8"
            class="flex-grow-1"
          >
            <v-list
              class="rounded-lg"
              style="min-height: 100%"
            >
              <v-list-item
                v-for="c in cuentasMostrar"
                :key="c.id"
                class="my-4"
              >
                <v-row class="ma-0">
                  <v-col cols="8">
                    <v-list-item-title>
                      {{ c.nombre }}
                    </v-list-item-title>
                  </v-col>
                  <v-col
                    v-if="!!c.marcaCaja"
                    cols="4"
                    class="text-center rounded-lg"
                    :class="campoCambiando == 'efectivo_' + c.id
                      ? 'green lighten-3'
                      : 'grey lighten-3'
                      "
                    @click="cambiarSeleccion(c.id)"
                  >
                    <div class="font-weight-bold">EFECTIVO</div>
                    <div class="font-weight-bold text-h5 success--text">
                      {{ c.efectivoMostrar }}
                    </div>
                  </v-col>
                </v-row>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
            class="flex-grow-1"
            v-if="$vuetify.breakpoint.mdAndUp"
          >
            <KeyboardNumerico
              keyboardClass="keyboard-zinicio"
              noDividir
              ref="keyboardRefNumerico"
              @onChange="onChange"
              @onKeyPress="onKeyPress"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="rounded-b-lg blue-grey lighten-5">
        <v-btn
          @click="logout"
          large
          color="red"
          class="black--text rounded-lg"
        >Salir</v-btn>
        <v-spacer />
        <v-btn
          @click="inicioDia"
          large
          color="primary"
          class="black--text rounded-lg"
        >Iniciar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import KeyboardNumerico from './KeyboardNumerico';
import { mostrarMoneda, isFloat } from '@/utils/helper.utils.js';

import { mapState, mapGetters, mapActions } from 'vuex';
import moment from 'moment-timezone';
export default {
  components: { KeyboardNumerico },
  data() {
    return {
      campoCambiando: null,
      cuentas: [],
    };
  },
  watch: {
    'datosTpv.cuentas': function (v) {
      this.asignarCuentas();
    },
    zcierreDialogMostrar(v) {
      if (v) {
        this.campoCambiando = 'efectivo_' + this.cuentaActual.id;

        this.cuentas = this.datosTpv.cuentas
          .filter((c) => c.role == 'tpv')
          .map((c) => {
            return {
              ...c,
              efectivo: 0,
            };
          });
      }
    },
  },
  computed: {
    ...mapGetters({
      cuentaActual: 'cuenta',
      zCierreDialogGetters: 'zCierreDialogGetters',
    }),

    cuentasMostrar() {
      return this.cuentas
        .filter((c) => c.role == 'tpv')
        .map((c) => {
          let efectivoAux = parseFloat(c.efectivo);
          return {
            ...c,
            efectivo: 0,
            efectivoMostrar: mostrarMoneda(
              efectivoAux && efectivoAux > 0 ? efectivoAux : 0,
              true
            ),
          };
        });
    },
    zcierreDialogMostrar() {
      // alert(this.zCierreDialogGetters);
      return this.zCierreDialogGetters;
      return (
        this.zcierreDialog ||
        (this.configuracionTpv &&
          this.configuracionTpv.zcierreObligatorio &&
          !this.zcierre)
      );
    },

    ...mapState([
      'zcierreDialog',
      'datosTpv',
      'horaActual',
      'zcierre',
      'configuracionTpv',
    ]),
    // cuentas() {

    // },
    inicioMostrar() {
      return moment(this.horaActual).format('DD-MM-YYYY HH:mm:ss');
    },
  },
  methods: {
    ...mapActions({
      logout: 'auth/firebaseLogout',
    }),
    cambiarSeleccion(id) {
      this.campoCambiando = 'efectivo_' + id;
      this.$refs.keyboardRefNumerico.reset(); //pone a 0
    },
    onKeyPress(button) {
      if (button == 'C') {
        setTimeout(() => {
          this.$refs.keyboardRefNumerico.reset(); //pone a 0
          this.onChange(0);
        }, 100);
      }
    },
    onChange(input) {
      if (!!this.campoCambiando) {
        let id = this.campoCambiando.substring(
          this.campoCambiando.indexOf('_') + 1,
          this.campoCambiando.length
        );

        let fEfectivo = this.cuentas.find((c) => c.id == id);

        if (fEfectivo) {
          fEfectivo.efectivo = parseFloat(parseInt(input) / 100);
        }
      }
      // if (
      //   this.campoCambiando == "precio" ||
      //   this.campoCambiando == "precioBase"
      // )
      //   input = mostrarMoneda(parseInt(input) / 100);
      // this.nuevoProducto[this.nuevoProductoCampoCambiando] = input;
      // if (isFloat(parseFloat(this.inputTecladoNumerico)))
      //   this.inputTecladoNumerico = "0";
      // if (input.toString().charAt(input.length - 1) == "C") {
      //   this.inputTecladoNumerico = "0";
      // } else {
      //   this.inputTecladoNumerico = input;
      // }
      // if (isFloat(parseFloat(this.inputKeyboard))) this.inputKeyboard = "0";
    },
    inicioDia() {
      const numeroCierre = this.$store.state.contable.numerosContables.numeroCierre
        ? this.$store.state.contable.numerosContables.numeroCierre
        : 1;

      this.$store.dispatch('contable/incrementarContadores', 'cierre');
      const ahora = moment().unix();
      this.$store
        .dispatch('inicioZCierre', {
          cuentas: this.cuentas.map((c) => {
            return {
              marcaCaja: !!c.marcaCaja,
              efectivo: c.efectivo,
              id: c.id,
              nombre: c.nombre,
              email: c.email,
            };
          }),
          inicio: ahora,
          restaurante: this.$store.state.restauranteObj.id,
          negocio: this.$store.state.negocioObj.id,
          estado: 'abierto',
          v3: true,
          numeroCierre: numeroCierre,
          cierresX: [],
        })
        .then(() => {
          // this.$store.dispatch("reiniciar");
          if (!this.$store.getters["cuenta"].ocultarDialogoUsuario)
            this.$store.commit('usuarios/setEleccionUsuarioDialog', true);
        });
    },
    asignarCuentas() {
      this.cuentas = this.datosTpv.cuentas
        .filter((c) => c.role == 'tpv')
        .map((c) => {
          return {
            ...c,
            efectivo: 0,
          };
        });
    },
  },
  mounted() {
    this.campoCambiando = 'efectivo_' + this.cuentaActual.id;
    this.asignarCuentas();
  },
};
</script>

<style></style>
