<template>
    <v-dialog
        v-model="buscarPedidoDialog"
        max-width="1200px"
        :fullscreen="$vuetify.breakpoint.smAndDown"
        overlay-opacity="0.90"
        :content-class="$vuetify.breakpoint.mdAndUp ? 'elevation-0' : 'dialogo--custom-fs'"
        scrollable
    >
        <v-card :rounded="$vuetify.breakpoint.smAndDown ? '0' : 'lg'">
            <v-card-title
                class="white py-3 pr-3"
                height="78"
            >
                <!-- <v-icon color="info" class="mr-4"> mdi-history </v-icon> -->
                <h2
                    :class="$vuetify.breakpoint.smAndDown ? 'caption' : 'body-1'"
                    class="font-weight-black"
                >
                    Buscar Pedido por código QR

                </h2>
                <v-spacer />
                <v-btn
                    fab
                    :height="$vuetify.breakpoint.smAndDown ? 48 : 68"
                    :width="$vuetify.breakpoint.smAndDown ? 48 : 68"
                    color="primary"
                    @click="buscarPedidoDialog = false"
                    top
                    right
                >
                    <v-icon
                        :size="$vuetify.breakpoint.smAndDown ? 36 : 44"
                        color="black"
                    >mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider />
            <v-card-title
                class="white py-3 pr-3"
                height="78"
            >
                <v-row
                    justify="center"
                    align="center"
                >
                    <v-col
                        cols="10"
                        md="6"
                        lg="4"
                    >
                        <v-text-field
                            autofocus
                            outlined
                            :loading="loading"
                            class="bg"
                            @keydown.enter="buscarPedidoMetodo"
                            hide-details
                            label="Código pedido"
                            v-model="buscarPedido"
                        />
                    </v-col>
                    <v-col cols="2">
                        <v-btn
                            large
                            :disabled="buscarPedido.trim().length == 0"
                            :loading="loading"
                            @click="buscarPedidoMetodo"
                            color="primary"
                            class="black--text"
                            block
                        >Buscar</v-btn>
                    </v-col>
                </v-row>


            </v-card-title>
            <v-card-text>
                <v-alert
                    type="info"
                    v-if="loading"
                >Buscando pedido</v-alert>
                <v-alert
                    type="error"
                    v-else-if="buscarPedidoObj === false"
                >Pedido no encontrado</v-alert>
            </v-card-text>
            <v-divider />

            <v-card-text class="pt-4 blue-grey lighten-5">
                <v-row class="pa-2 pa-md-0">

                </v-row>


            </v-card-text></v-card></v-dialog>
</template>

<script>
import moment from 'moment';
import { chunkArray } from '@/utils/helper.utils.js';
import { mapState } from 'vuex';
import PedidoCard from './PedidoCard.vue';

export default {
    components: {
        PedidoCard,
    },
    data: () => {
        return {
            buscarPedido: '',
            loading: false,
        };
    },
    watch: {
        buscarPedidoDialog(v) {
            if (!v) {
                this.$store.commit('pedidos/SET_BUSCAR_PEDIDO_OBJ', null);
                this.buscarPedido = ''
                this.loading = false;
            }
        },

        buscarPedido(v, old) {
            // console.log(v);
            // console.log(old);
            // if (v.length > 10 && v.length > old + 5) { //pistola o pegar pedido
            //     console.log(v);
            //     this.buscarPedido = v.replace('\'', '-').replace('?', '_')
            //     console.log(this.buscarPedido)
            //     this.buscarPedidoMetodo(v);
            // }
        },

    },
    computed: {
        ...mapState({
            buscarPedidoObj: (state) => state.pedidos.buscarPedidoObj
        }),
        ...mapState(["pistolaTextoDefinitivo"]),

        pedidoEditandoObj: {
            get() {
                return this.$store.state.pedidos.pedidoEditandoObj;
            },
        },


        buscarPedidoDialog: {
            get() {
                return this.$store.state.pedidos.buscarPedidoDialog;
            },
            set(v) {
                this.$store.commit('pedidos/SET_BUSCAR_PEDIDO_DIALOG', v);
            },
        },
    },
    methods: {
        buscarPedidoMetodo() {

            this.loading = true;
            this.buscarPedido = this.buscarPedido.replaceAll("'", "-").replaceAll("?", "_")

            this.$store.dispatch('pedidos/buscarPedidoPorId', this.buscarPedido)
                .then((r) => {
                    if (!!this.buscarPedidoObj) {
                        this.$store.commit('pedidos/setCobrarDialog', true);
                        this.buscarPedidoDialog = false;
                        this.pedidoEditando = this.buscarPedidoObj.id;
                        this.$store.dispatch('pedidos/setPedidoObj', this.buscarPedidoObj.id);
                    }
                })
                .finally(() => this.loading = false)
        }
    },
    mounted() {

    }
};
</script>

<style></style>
