<template>
  <v-overlay opacity="1">
    <v-container class="d-flex align-center justify-center flex-column">
      <h1 class="white--text text--darken-4 mb-10 mx-4">INICIANDO SESIÓN</h1>

      <v-progress-circular
        v-if="iniciando"
        size="50"
        width="8"
        indeterminate
        color="primary"
      ></v-progress-circular>

      <v-alert
        v-else
        outlined
        icon="mdi-alert"
        color="red darnken-2"
      >Error al iniciar sesión, contacte con soporte</v-alert>
    </v-container>
  </v-overlay>
</template>

<script>
import { mapState } from "vuex";
export default {
  data: () => {
    return {
      iniciando: true,
    };
  },
  watch: {
    isLoaded(v) {
      if (v) {
        this.lanzarLogin();
      }
    },
  },
  computed: {
    ...mapState({
      isLoaded: (state) => state.loaded,
    }),
  },
  methods: {
    lanzarLogin() {
      var promesa = this.$store.state.auth.isLogged
        ? // ? this.$store.dispatch("auth/firebaseLogout", false)
        Promise.resolve()
        : Promise.resolve();
      promesa.then(() => {
        const rest = this.$route.params.rest;
        const id = this.$route.params.idcomandero;
        const intento = this.$route.params.idintento;
        if (id && rest && intento) {
          this.$store
            .dispatch("loginComandero", {
              usuario: id,
              intento: intento,
              rest: rest,
            })
            .then((r) => {

              if (!r.ok) {
                this.$router.push("/login");
              } else {
                this.$store.dispatch("auth/firebaseLoginToken", r.token);
              }
            })
            .catch(() => (this.iniciando = false));
        }
      });
    },
  },
  mounted() {
    if (this.isLoaded) {
      this.lanzarLogin();
    }
  },
};
</script>

<style></style>