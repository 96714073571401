<template>
  <v-card flat>
    <v-card-title v-if="mostrarTitulo">
      MÓDULO COBRAR E IMPRESIÓN
    </v-card-title>
    <v-card-text class="pa-0">
      <!-- <v-row no-gutters>
        <v-col cols="12">
          <v-btn
            elevation="0"
            large
            block
            color="primary"
            class="black--text rounded-0"
            @click="$store.commit('pedidos/setCobrarDialog', true)"
          >
            COBRAR
          </v-btn>
        </v-col>
      </v-row> -->
      <v-row no-gutters>
        <v-col cols="4">

          <!-- solicitar eliminación, canal funeats, propio, directorio -->
          <v-btn
            v-if="pedido &&
              (pedido.canalVenta == 'propio' ||
                pedido.canalVenta == 'directorio' ||
                pedido.canalVenta == 'funeats')
              "
            elevation="0"
            x-large
            block
            color="warning"
            class="black--text rounded-0"
            @click="goSolicitarEliminado"
            :loading="eliminar.loading"
          ><v-icon>mdi-delete</v-icon></v-btn>

          <v-btn
            v-else-if="pedido && pedido.canalVenta == 'tpv'"
            elevation="0"
            x-large
            block
            color="red"
            class="black--text rounded-0"
            @click="goEliminado"
            :loading="eliminar.loading"
          ><v-icon>mdi-delete</v-icon></v-btn>
        </v-col>
        <v-col cols="4">
          <v-btn
            elevation="0"
            x-large
            block
            color="cyan"
            class="black--text rounded-0"
            @click="imprimirCocinaDialog = true"
            :disabled="!pedido || !pedido.productos || pedido.productos.length < 1
              "
          >
            <v-icon>mdi-food</v-icon>
          </v-btn>
        </v-col>
        <!-- <v-col cols="4">
          <v-btn
            elevation="0"
            x-large
            block
            color="cyan darken-1"
            class="black--text rounded-0"
          >
            <v-icon>mdi-food</v-icon>
          </v-btn>
        </v-col> -->
        <v-col cols="4">
          <v-btn
            elevation="0"
            x-large
            block
            color="light-blue darken-1"
            class="black--text rounded-0"
            @click="imprimir"
            :disabled="!pedido || !pedido.productos || pedido.productos.length < 1
              "
          >
            <v-icon>mdi-printer</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <!-- solictar eliminar -->
    <v-dialog
      overlay-opacity="0.90"
      v-if="pedido"
      v-model="eliminar.dialogo"
      scrollable
      max-width="600px"
    >
      <v-card :rounded="$vuetify.breakpoint.mdAndUp ? 'lg' : '0'">
        <v-card-title class="white py-3 pr-3">
          <h2
            :class="$vuetify.breakpoint.smAndDown ? 'caption' : 'body-1'"
            class="font-weight-black"
          >
            Solicitud de eliminación de pedido
          </h2>
          <v-spacer />
          <v-btn
            fab
            :height="$vuetify.breakpoint.smAndDown ? 48 : 68"
            :width="$vuetify.breakpoint.smAndDown ? 48 : 68"
            color="primary"
            @click="eliminar.dialogo = false"
            top
            right
          >
            <v-icon
              :size="$vuetify.breakpoint.smAndDown ? 36 : 44"
              color="black"
            >mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-4 blue-grey lighten-5 text-h6">
          Está solicitando eliminar el
          <strong>
            pedido
            {{ eliminar.pedido ? eliminar.pedido.numPedidoRest : "" }}
            (número
            {{ eliminar.pedido ? eliminar.pedido.numPedidoRestDia : "" }} del
            día)</strong>.
          <br />
          Hasta que no se confirme aparecerá en los cierres Z.
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="rounded-b-lg py-4 blue-grey lighten-5">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="rounded-lg black--text"
            @click="confirmarEliminar"
            :loading="eliminar.loading"
          >SOLICITAR ELIMINACIÓN</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--  eliminar -->
    <v-dialog
      overlay-opacity="0.90"
      v-if="pedido"
      v-model="eliminarTpv.dialogo"
      scrollable
      max-width="600px"
    >
      <v-card :rounded="$vuetify.breakpoint.mdAndUp ? 'lg' : '0'">
        <v-card-title class="white py-3 pr-3">
          <h2
            :class="$vuetify.breakpoint.smAndDown ? 'caption' : 'body-1'"
            class="font-weight-black"
          >
            Eliminar pedido
          </h2>
          <v-spacer />
          <v-btn
            fab
            :height="$vuetify.breakpoint.smAndDown ? 48 : 68"
            :width="$vuetify.breakpoint.smAndDown ? 48 : 68"
            color="primary"
            @click="eliminarTpv.dialogo = false"
            top
            right
          >
            <v-icon
              :size="$vuetify.breakpoint.smAndDown ? 36 : 44"
              color="black"
            >mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-4 blue-grey lighten-5 text-h6">
          El
          <strong>
            pedido
            {{ pedido ? pedido.numPedidoRest : "" }}
            (número
            {{ pedido ? pedido.numPedidoRestDia : "" }} del día)
          </strong>
          se eliminará directamente.
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="rounded-b-lg py-4 blue-grey lighten-5">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="rounded-lg black--text"
            @click="confirmarEliminarTpv"
            :loading="eliminarTpv.loading"
          >ELIMINAR</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- dialogo mensajes -->
    <v-dialog
      v-if="pedido"
      v-model="pedidoMensajeDialog"
      max-width="800px"
      width="80%"
    >
      <v-card class="mx-auto">
        <v-toolbar
          color="primary"
          dark
        >
          <!--<v-app-bar-nav-icon></v-app-bar-nav-icon>-->

          <v-toolbar-title>MENSAJES DEL PEDIDO {{ pedidoMensaje.numPedidoRest }} | PEDIDO DÍA
            {{ pedidoMensaje.numPedidoRestDia }}</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn icon>
            <v-icon>mdi-information</v-icon>
          </v-btn>
        </v-toolbar>

        <v-row dense>
          <v-col>
            <div
              v-for="(m, i) in mensajesComputados"
              :key="i"
            >
              <v-card-title>{{ m.fechaMostrar }}</v-card-title>
              <v-card-text>{{ m.adicional.mensaje }}</v-card-text>
            </div>

            <v-card-actions>
              <v-spacer />
              <v-btn
                class="primary"
                @click="pedidoMensajeDialog = false"
                x-large
              >Cerrar</v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="imprimirCocinaDialog"
      max-width="500"
    >
      <v-card>
        <v-card-title>¿Quiere enviar la comanda completa a cocina?
        </v-card-title>
        <v-card-text>Si quiere mandar los productos que no se han imprimido en cocina,
          sólo cierre el pedido. Si quiere imprimir la comanda completa confirme
          la impresión. Al confirmar la impresión se marcarán todos los
          productos como impresos.</v-card-text>
        <v-card-actions>
          <v-btn
            large
            rounded
            @click="imprimirCocinaDialog = false"
            color="red lighten-2"
          >Cancelar</v-btn>
          <v-spacer />
          <v-btn
            class="black--text"
            large
            rounded
            @click="imprimirCocina"
            color="primary"
          >Imprimir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  props: {
    mostrarTitulo: {
      type: Boolean,
      default: false,
    },
    pedidoProp: {
      type: Object,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      imprimirCocinaDialog: false,
      eliminar: {
        dialogo: false,
        loading: false,
        pedido: null,
        motivos: this.$store.state.pedidos.eliminarMotivos,
        motivo: "pruebas",
      },
      eliminarTpv: {
        dialogo: false,
        loading: false,
      },
      pedidoMensajeDialog: false,
      pedidoMensaje: { id: null },
    };
  },
  watch: {
    eleccionUsuarioAdminAccionesRespuesta: {
      handler: function (v) {

        if (v.ok) {
          if (this.eleccionUsuarioAdminAccionesObj.accion == "eliminar-pedido")
            this.confirmarEliminarTpv();
          this.$store.commit(
            "usuarios/setEleccionUsuarioAdminAccionesRespuesta",
            { usuario: this.eleccion, ok: false }
          );
          this.$store.commit("usuarios/setEleccionUsuarioAdminAccionesObj", {});
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      accionesNecesarioAdmin: "usuarios/accionesNecesarioAdmin",
      puedeEliminarPedido: "usuarios/puedeEliminarPedido",
      puedeEliminarProducto: "usuarios/puedeEliminarProducto",
    }),
    ...mapState(["configuracionTpv"]),
    ...mapState({
      eleccionUsuarioAdminAccionesRespuesta: (state) =>
        state.usuarios.eleccionUsuarioAdminAccionesRespuesta,
      eleccionUsuarioAdminAccionesObj: (state) =>
        state.usuarios.eleccionUsuarioAdminAccionesObj,
    }),
    mensajesComputados() {
      return this.pedido.historial
        ? this.pedido.historial
          .filter((p) => p.mensaje.includes("Mensaje desde la web"))
          .map((p) => {
            return {
              ...p,
              fechaMostrar: moment.unix(p.fecha).format("DD-MM-YYYY HH:mm"),
            };
          })
        : [];
    },
    pedido() {
      return this.pedidoProp
        ? this.pedidoProp
        : this.$store.state.pedidos.pedidoEditandoObj;
    },
  },
  methods: {
    moverMesa(pedido) { },
    imprimir() {
      this.$store.dispatch("pedidos/imprimirPedidoCaja", this.pedido);
    },
    imprimirCocina() {
      this.$store.dispatch("pedidos/imprimirPedidoCocina", {
        pedido: this.pedido,
      });
      this.imprimirCocinaDialog = false;
    },
    goMensajes(item) {
      this.pedidoMensaje = item;
      this.pedidoMensajeDialog = true;
    },
    goSolicitarEliminado() {
      this.eliminar.dialogo = true;
      this.eliminar.pedido = this.pedido;
    },
    goEliminado() {
      this.eliminar.pedido = this.pedido;
      if (this.puedeEliminarPedido) {
        this.eliminarTpv.dialogo = true;
      } else {
        this.$store.commit(
          "usuarios/setEleccionUsuarioAdminAccionesDialog",
          true
        );
        this.$store.commit("usuarios/setEleccionUsuarioAdminAccionesObj", {
          accion: "eliminar-pedido",
          pedido: this.pedido.numPedidoRestDiaModoMostrar,
          precioTotal: this.pedido.precioTotal,
        });
      }
    },
    confirmarEliminar() {
      let _vm = this;
      this.eliminar.pedido.loadingEliminar = true;

      this.$store
        .dispatch("pedidos/solicitarEliminacion", this.eliminar.pedido)
        .then((r) => {
          this.$store.commit("snackbar/setMensajeSuccess", {
            msj: "Pedido solicitado eliminar",
          });
          this.eliminar.dialogo = false;
        })
        .catch((e) => {
          console.log(e);
          this.$store.commit("snackbar/setMensajeDanger", {
            msj: "Error al solicitar eliminación: " + e,
          });
        })
        .finally(() => {
          item.loadingEliminar = true;
        });
    },
    confirmarEliminarTpv() {
      let _vm = this;
      this.eliminarTpv.loading = true;

      this.$store
        .dispatch("pedidos/confirmarEliminarTpv", this.pedido)
        .then((r) => {
          this.$store.commit("snackbar/setMensajeSuccess", {
            msj: "Pedido eliminado",
          });
          this.eliminarTpv.dialogo = false;
          this.$store.commit("pedidos/setPedidoDialog", false);
        })
        .catch((e) => {
          console.log(e);
          this.$store.commit("snackbar/setMensajeDanger", {
            msj: "Error al eliminar: " + e,
          });
        })
        .finally(() => {
          this.eliminarTpv.loading = false;
        });
    },
  },
};
</script>

<style></style>