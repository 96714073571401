<template>
  <v-toolbar
    flat
    class="mx-2"
    fluid
  >
    <!-- ############## NUEVO PEDIDO INTERNO  ############## -->
    <v-row v-if="$router.currentRoute.path.includes('/pedidos-internos/')">
      <v-btn
        outlined
        left
        x-large
        @click="$router.push('/pedidos-internos')"
      >
        <v-icon class="mr-4">mdi-arrow-left</v-icon>
        <span>Volver</span>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        outlined
        left
        x-large
        @click="guardarPedidoInterno"
        :loading="$store.state.internos.pedidoGuardadoLoading"
        :disabled="$store.state.internos.pedidoGuardado"
      >
        <v-icon class="mr-4">mdi-content-save-outline</v-icon>
        <span>Guardar</span>
      </v-btn>
      <v-btn
        class="ml-4"
        outlined
        left
        x-large
        @click="enviarPedidoInterno"
        :loading="$store.state.internos.pedidoEnviarLoading"
        :disabled="$store.getters['internos/enviarButtonDisabled']"
      >
        <v-icon class="mr-4">mdi-send</v-icon>
        <span>Enviar</span>
      </v-btn>
    </v-row>
    <!-- ############## PEDIDOS INTERNOS ############## -->
    <v-row v-else-if="$router.currentRoute.path == '/pedidos-internos'">
      <v-btn
        outlined
        left
        x-large
        @click="$router.push('/dashboard')"
      >
        <v-icon class="mr-4">mdi-arrow-left</v-icon>
        <span>Atrás</span>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        class="ml-4"
        outlined
        left
        x-large
        @click="$router.push('/pedidos-internos/add')"
      >
        <v-icon class="mr-4">mdi-content-save-outline</v-icon>
        <span>Nuevo</span>
      </v-btn>
    </v-row>
    <!-- ############## CLIENTES ############## -->
    <!--<v-row v-else-if="$router.currentRoute.path == '/clientes'">
      <v-btn outlined left x-large @click="$router.push('/dashboard')">
        <v-icon class="mr-4">mdi-arrow-left</v-icon>
        <span>Atrás</span>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn outlined left x-large @click="nuevoCliente">
        <v-icon class="mr-4">mdi-account-plus</v-icon>
        <span>Nuevo</span>
      </v-btn>
    </v-row>-->
    <!-- ############## PEDIDOS ############## -->
    <v-row v-else-if="$router.currentRoute.path == '/pedidos'">
      <v-btn
        outlined
        left
        x-large
        @click="$router.push('/dashboard')"
      >
        <v-icon class="mr-4">mdi-arrow-left</v-icon>
        <span>Atrás</span>
      </v-btn>
      <v-spacer></v-spacer>
    </v-row>
    <!-- ############## PRODUCTOS ############## -->
    <!--<v-row v-else-if="$router.currentRoute.path == '/productos'">
      <v-btn outlined left x-large @click="$router.push('/dashboard')">
        <v-icon class="mr-4">mdi-arrow-left</v-icon>
        <span>Atrás</span>
      </v-btn>
      <v-spacer></v-spacer>
    </v-row>-->
    <!-- ############## GESTION INTERNA ############## -->
    <v-row v-else-if="$router.currentRoute.path == '/gestion-interna'">
      <v-btn
        outlined
        left
        x-large
        @click="$router.push('/dashboard')"
      >
        <v-icon class="mr-4">mdi-arrow-left</v-icon>
        <span>Atrás</span>
      </v-btn>
      <v-spacer></v-spacer>
    </v-row>

    <!-- ############## OTROS  ############## -->
    <v-row
      v-else
      class="pa-3 d-flex flex-row"
      align="center"
    >
      <v-menu
        offset-y
        dark
        top
        rounded="lg"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="rounded-lg mr-4"
            color="primary"
            dark
            outlined
            v-bind="attrs"
            v-on="on"
            height="44"
          >
            <v-icon> mdi-information </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-if="nombreFooter || cuenta.impresionLocal">
            {{ nombreFooter }}
            <v-icon class="ml-4">mdi-printer</v-icon>
          </v-list-item>
          <v-list-item>
            <v-btn
              class="rounded-lg mx-auto"
              block
              small
              color="primary"
              :class="mostrarTeclado ? 'black--text' : ''"
              :outlined="mostrarTeclado ? false : true"
              :dark="mostrarTeclado ? false : true"
              @click="mostrarTeclado = !mostrarTeclado"
              height="44"
            >
              <v-icon>mdi-keyboard</v-icon>
            </v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn
              class="rounded-lg mx-auto"
              block
              small
              color="primary"
              :class="modoPaginacion ? 'black--text' : ''"
              :outlined="modoPaginacion ? false : true"
              :dark="modoPaginacion ? false : true"
              @click="cambiarPaginacion"
              height="44"
            >
              <v-icon>mdi-content-paste</v-icon>
            </v-btn>
          </v-list-item>
          <v-list-item>

            <v-btn
              class="rounded-lg mx-auto"
              block
              small
              color="primary"
              :class="sizeBoton == 'grande' ? 'black--text' : ''"
              :outlined="sizeBoton == 'small' ? true : false"
              :dark="bajaCalidad ? false : true"
              @click="sizeBoton = (sizeBoton == 'grande' ? 'small' : 'grande')"
              height="44"
            >
              <v-icon>mdi-resize</v-icon>
            </v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn
              class="rounded-lg mx-auto"
              block
              small
              color="primary"
              :class="bajaCalidad ? 'black--text' : ''"
              :outlined="bajaCalidad ? false : true"
              :dark="bajaCalidad ? false : true"
              @click="bajaCalidad = !bajaCalidad"
              height="44"
            >
              <v-icon>mdi-image-off</v-icon>
            </v-btn>
          </v-list-item>

          <v-list-item>
            <v-btn
              class="rounded-lg mx-auto"
              block
              small
              outlined
              color="primary"
              @click="$store.dispatch('reiniciar')"
              height="44"
            >
              <v-icon>mdi-reload</v-icon>
            </v-btn>
          </v-list-item>
          <v-list-item v-if="false">
            <v-btn
              class="rounded-lg mx-auto"
              block
              small
              outlined
              color="primary"
              @click="probarInfo"
              height="44"
            >
              <v-icon>mdi-test-tube</v-icon>
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-card
        class="rounded-lg mr-4 px-3 text-button border-primary hora-cabecera primary--text"
        elevation="0"
        color="transparent"
      >
        <v-icon
          left
          size="18"
          color="primary"
          class="mr-0"
        >mdi-clock</v-icon>
        {{ $store.getters['hora'] }}
      </v-card>
      <span class="white--text">&copy; Mewo Systems 2024 ({{ $store.state.version }})</span>
      <!--       <span class="white--text ml-8">
        <v-icon>mdi-whatsapp</v-icon>
        +34 951 16 22 01
      </span
      > -->
      <paginacion />
      <v-spacer />
  </v-row>
</v-toolbar></template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { mostrarIdioma, mostrarMoneda } from '@/utils/helper.utils.js';
import Paginacion from './Paginacion.vue';

export default {
  components: { Paginacion },
  data() {
    return {};
  },
  computed: {
    ...mapState(['modoPaginacion']),
    // ...mapGetters(["paginaActualPaginacion"]),
    ...mapState({
      datosTpv: (state) => state.datosTpv,
    }),

    ...mapGetters({
      cuenta: 'cuenta',
    }),
    mostrarTeclado: {
      get() {
        return this.$store.state.necesarioTeclado;
      },
      set(v) {
        this.$store.commit('setNecesarioTeclado', v);
      },
    },
    bajaCalidad: {
      get() {
        return this.$store.state.bajaCalidad;
      },
      set(v) {
        this.$store.commit('setBajaCalidad', v);
      },
    },
    sizeBoton: {
      get() {
        return this.$store.state.sizeBoton;
      },
      set(v) {
        this.$store.commit('setSizeBoton', v);
      },
    },
    nombreFooter() {
      return this.cuenta.nombre;
    },
  },
  methods: {
    cambiarPaginacion() {
      this.$store.commit(
        'setModoPaginacion',
        !this.$store.state.modoPaginacion
      );
    },
    probarInfo() {
      let v = '8003511304478';
      var f = new Promise((resolve) => {
        let res = this.$store.getters['productos/PRODUCTOS_CATALOGO_DEFECTO']
          .filter((p) => {
            return (
              !!p.codigoLector &&
              p.codigoLector.toUpperCase().includes(v.toUpperCase())
            );
          })
          .map((p) => {
            return {
              ...p,
              nombre: mostrarIdioma(p),
              precioMostrar: p.precio ? mostrarMoneda(p.precio) : '0.00',
            };
          });
        resolve(res);
      });
      return f.then((r) => {
        if (r[0]) {
          this.$store.commit('setInfoProductoObj', r[0]);
          this.$store.commit('setInfoProductoDialogo', true);
        }
      });
    },

    nuevoCliente() {
      let nuevo = JSON.parse(
        JSON.stringify(this.$store.state.clientes.clienteNuevo)
      );
      nuevo.restaurantes.push(this.$store.state.restauranteObj.id);
      this.$store.commit('clientes/setDialogoClientes', true);
      this.$store.commit('clientes/setEditarCliente', nuevo);
    },
    internosAdd() {
      return inc;
    },
    ...mapActions({
      getClientes: 'clientes/getClientes',
    }),
  },
  mounted() { },
  watch: {
    $route(to, from) {
      this.$forceUpdate();
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style scope></style>
