<template>
  <div>
    <v-row
      class=""
      style="max-height: 100%"
    >
      <v-col
        cols="12"
        md="8"
        lg="9"
        class="scroll-y--helper"
        :class="$vuetify.breakpoint.mdAndUp ? 'pl-0 pr-1' : 'px-0'"
        style="max-height: 100%"
      >
        <!-- <v-overlay opacity="0.8" :value="editandoProductos"> </v-overlay> -->

        <div :class="$vuetify.breakpoint.mdAndDown ? 'pb-16 mb-4' : ''">
          <template v-if="pasoPidiendo == 0 && !busquedaActiva">
            <v-row class="mx-0">
              <v-col
                class="pa-2"
                cols="4"
                md="3"
                lg="2"
              >
                <v-hover v-slot:default="{ hover }">
                  <v-card
                    @click="paso = 'listado'"
                    elevation="4"
                    class="rounded-lg ficha-generica"
                    :style="hover ? 'cursor: pointer' : ''"
                    :color="hover ? 'primary lighten-4' : 'primary borde-blanco'
                      "
                  >
                    <v-responsive
                      :aspect-ratio="1.618"
                      class="align-center"
                    >
                      <v-card-text
                        class="text-uppercase font-weight-bold text-center"
                        :class="$vuetify.breakpoint.smAndDown
                            ? 'caption'
                            : $vuetify.breakpoint.mdAndDown
                              ? 'body-2'
                              : 'body-1'
                          "
                        :style="$vuetify.breakpoint.smAndDown ? 'line-height:1;' : ''
                          "
                      >
                        <v-row justify="center">
                          <v-btn icon>
                            <v-icon
                              x-large
                              class="black--text"
                            >mdi-arrow-left-bold</v-icon>
                          </v-btn>
                        </v-row>
                      </v-card-text>
                    </v-responsive>
                  </v-card>
                </v-hover>
              </v-col>
              <v-col
                cols="4"
                md="3"
                lg="2"
                class="pa-2"
                v-for="c in categorias"
                :key="c.id"
              >
                <v-hover v-slot:default="{ hover }">
                  <v-card
                    elevation="4"
                    class="rounded-lg pedido-categoria"
                    @click="clickCategoria(c)"
                    :color="hover ? 'secondary lighten-4' : ' borde-blanco'"
                  >
                    <v-responsive
                      :aspect-ratio="1.618"
                      class="align-center"
                    >
                      <v-img src="/img/pedidosInternos/imgPedidoCategoria.svg">
                        <v-card-text
                          style="height: 100%"
                          class="
                            text-uppercase
                            font-weight-black
                            text-center
                            pa-0
                          "
                          :class="$vuetify.breakpoint.mdAndDown ? '' : 'text-h4'
                            "
                        >
                          <v-row
                            class="pa-0 ma-0"
                            style="height: 100%"
                          >
                            <v-col
                              v-line-clamp="$vuetify.breakpoint.lgAndDown ? 3 : 4
                                "
                              style="word-break: normal !important"
                              class="font-weight-black body-2 my-auto pa-0 px-1"
                            >
                              {{ c.nombre }}
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-img>
                    </v-responsive>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
          </template>
          <!--paso pidiendo producto-->

          <template v-else-if="pasoPidiendo == 1 || busquedaActiva">
            <v-row class="mx-0">
              <v-col
                class="pa-2"
                cols="4"
                md="3"
                lg="2"
              >
                <v-hover v-slot:default="{ hover }">
                  <v-card
                    @click="pasoPidiendo--"
                    elevation="4"
                    class="rounded-lg ficha-generica"
                    :style="hover ? 'cursor: pointer' : ''"
                    :color="hover ? 'primary lighten-4' : 'primary borde-blanco'
                      "
                  >
                    <v-responsive
                      :aspect-ratio="1.618"
                      class="align-center"
                    >
                      <v-card-text
                        class="text-uppercase font-weight-bold text-center"
                        :class="$vuetify.breakpoint.smAndDown
                            ? 'caption'
                            : $vuetify.breakpoint.mdAndDown
                              ? 'body-2'
                              : 'body-1'
                          "
                        :style="$vuetify.breakpoint.smAndDown ? 'line-height:1;' : ''
                          "
                      >
                        <v-row justify="center">
                          <v-btn icon>
                            <v-icon
                              x-large
                              class="black--text"
                            >mdi-arrow-left-bold</v-icon>
                          </v-btn>
                        </v-row>
                      </v-card-text>
                    </v-responsive>
                  </v-card>
                </v-hover>
              </v-col>
              <v-col
                cols="4"
                md="3"
                lg="2"
                class="pa-2"
                v-for="p in productosDefinitivos"
                :key="p.id"
              >
                <v-hover v-slot:default="{ hover }">
                  <v-card
                    elevation="4"
                    class="rounded-lg pedido-articulo"
                    @click="elegirProducto(p)"
                    :color="hover ? 'secondary lighten-4' : ' borde-blanco'"
                  >
                    <v-chip
                      v-if="p.cantidadCarrito"
                      class="
                        custom-badge
                        producto-badge
                        elevation-3
                        info
                        body-1
                        font-weight-bold
                        white--text
                      "
                    >{{ p.cantidadCarrito }}
                    </v-chip>
                    <v-responsive
                      :aspect-ratio="1.618"
                      class="align-center"
                    >
                      <v-img src="/img/pedidosInternos/imgPedidoArticulo.svg">
                        <v-card-text
                          style="height: 100%"
                          class="
                            text-uppercase
                            font-weight-black
                            text-center
                            pa-0
                          "
                        >
                          <v-row
                            class="pa-0 ma-0"
                            style="height: 100%"
                          >
                            <v-col
                              v-line-clamp="$vuetify.breakpoint.lgAndDown ? 3 : 4
                                "
                              style="word-break: normal !important"
                              class="font-weight-black body-2 my-auto pa-0 px-1"
                            >
                              {{ p.nombre }}
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-img>
                    </v-responsive>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
          </template>
        </div>
      </v-col>
      <template v-if="$vuetify.breakpoint.mdAndUp">
        <v-col
          cols="7"
          md="4"
          lg="3"
          class="pa-0"
          style="position: relative; z-index: 999"
        ><v-card
            elevation="0"
            :height="'calc(100vh - ' + $vuetify.application.top * 2 + 'px)'"
            width="100%"
            class="mx-auto border"
          >
            <v-navigation-drawer
              absolute
              width="100%"
              permanent
              class="grey lighten-5 elevation-0"
            >
              <productos-carrito :productos="carritoInterno" />
              <template
                v-slot:append
                v-if="!editandoProductos"
              >
                <div class="pa-0">
                  <v-btn
                    class="text-h6 black--text"
                    x-large
                    color="primary"
                    @click="$store.commit('pedidos/setCobrarDialog', true)"
                    block
                    :disabled="!pedidoEditandoObj.productos ||
                      pedidoEditandoObj.productos.length < 1 ||
                      !pedidoEditandoObj.id
                      "
                  >
                    Cobrar
                    <v-spacer></v-spacer>
                    <span class="font-weight-black">{{ precioTotal }} €</span>
                  </v-btn>
                </div>
                <div class="pa-0">
                  <cobrar />
                </div>
              </template>
            </v-navigation-drawer>
          </v-card> </v-col></template>
    </v-row>
    <v-footer
      height="78"
      app
      class="pa-0"
    >
      <v-toolbar
        dark
        height="78"
        flat
        class="ma-0"
        fluid
      >
        <v-spacer />
        <v-btn
          v-if="pedidoEnCurso.estado == 'realizando'"
          class="px-16 rounded-lg"
          style="background: #464646"
          outlined
          x-large
          @click="enviarPedidoInterno"
          :loading="$store.state.internos.pedidoEnviarLoading"
          :disabled="$store.getters['internos/enviarButtonDisabled']"
        >
          Enviar
          <v-icon right>mdi-send</v-icon>
        </v-btn>
      </v-toolbar>
    </v-footer>
  </div>
</template>

<script>
import ProductosCarrito from "../components/ProductosCarrito.vue";
import { mapState, mapGetters } from "vuex";
export default {
  components: {
    ProductosCarrito,
  },
  data() {
    return {
      categoriaSelecionada: null,
      timeoutGrabar: null,
    };
  },
  watch: {
    paso(v) {

      if (v == "listado") {
        if (this.timeoutGrabar) clearTimeout(this.timeoutGrabar);
        this.guardar().then(() => {

          this.$store.commit("internos/resetPedidoEnCurso");
        });
      }
    },
    pasoPidiendo(v) {

      if (v < 0) {
        this.pasoPidiendo = 0;
        return;
      }
      if (v == 0) {
        this.busqueda = "";
      }
    },
    carritoInterno: {
      handler: function (v) {
        if (v.length > 0) {
          if (this.timeoutGrabar) clearTimeout(this.timeoutGrabar);
          this.timeoutGrabar = setTimeout(() => {

            this.guardar();
          }, 2000);
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(["paginaActualPaginacion"]),
    ...mapState([
      "modoPaginacion",
      "categoriasPaginacionCategorias",
      "paginaPaginacionCategorias",
      "productosPaginacionProductos",
      "paginaPaginacionProductos",
    ]),

    ...mapState({
      categorias: (state) => state.internos.categorias,

      productos: (state) => state.internos.productos,
      pedidoEnCursoComprobar: (state) => state.internos.pedidoEnCursoComprobar,
      // pedidoEnCurso: (state) => state.internos.pedidoEnCurso,
      editandoProductos: (state) => state.internos.editarProductoObj,
    }),
    busqueda: {
      get() {
        return this.$store.state.internos.busqueda;
      },
      set(v) {
        this.$store.commit("internos/setBusqueda", v);
      },
    },
    busquedaActiva() {
      return this.busqueda.length >= 2;
    },
    loadingSave: {
      get() {
        return this.$store.state.internos.pedidoGuardadoLoading;
      },
      set(val) {
        this.$store.commit("internos/setPedidoGuardadoLoading", val);
      },
    },
    guardado: {
      get() {
        return this.$store.state.internos.pedidoGuardado;
      },
      set(val) {
        this.$store.commit("internos/setPedidoGuardado", val);
      },
    },
    pedidoEnCurso: {
      get() {
        return this.$store.state.internos.pedidoEnCurso;
      },
      set(v) {
        this.$store.commit("internos/setPedidoEnCurso", v);
      },
    },

    productosDefinitivos() {
      return this.productos
        .filter((p) => {

          return (
            (this.categoriaSelecionada == p.categoria &&
              !this.busquedaActiva) ||
            (this.busquedaActiva &&
              p.nombre.toUpperCase().includes(this.busqueda.toUpperCase()))
          );
          return true;
        })
        .map((p) => {
          return {
            ...p,
            cantidadCarrito: this.cantidadCarrito(p),
            interno: true, //para saber que es interno en carrito
          };
        });
    },
    paso: {
      get() {
        return this.$store.state.internos.paso;
      },
      set(v) {
        this.$store.commit("internos/SET_PASO", v);
      },
    },
    pasoPidiendo: {
      //0 categorías, 1 productos,
      get() {
        return this.$store.state.internos.pasoPidiendo;
      },
      set(v) {
        this.$store.commit("internos/SET_PASO_PIDIENDO", v);
      },
    },
    carritoInterno: {
      get() {
        return this.$store.state.internos.pedidoEnCurso.carritoInterno;
      },
      set(v) {
        this.$store.commit("app/setCarritoInterno", v);
      },
    },
    guardado: {
      get() {
        return this.$store.state.internos.pedidoGuardado;
      },
      set(val) {
        this.$store.commit("internos/setPedidoGuardado", val);
      },
    },
  },
  methods: {
    enviarPedidoInterno() {
      this.$confirm(
        "¿Quiero enviar el pedido a central? No podrá modificar el pedido con posterioridad",
        { title: "Enviar pedido interno" }
      ).then((r) => {
        if (r) {
          this.$store.commit("internos/setPedidoEnviarLoading", true);
          this.$store
            .dispatch("internos/enviarPedido")
            .then((r) => {
              this.$store.commit("snackbar/setMensajeSuccess", {
                msj: "Pedido enviado",
              });
              this.$store.commit("internos/setPedidoEnviado", true);
              this.paso = "listado";
            })
            .catch((e) => {
              console.log(e);
              this.$store.commit("snackbar/setMensajeDanger", {
                msj: "Error al enviar el pedido: " + e,
              });
            })
            .finally(() => {
              this.$store.commit("internos/setPedidoEnviarLoading", false);
            });
        }
      });
    },
    elegirProducto(item, i) {

      var found = this.carritoInterno.findIndex((prod) => {
        return prod.id == item.id;
      });
      if (found > -1) {
        this.carritoInterno[found].cantidad += 1;
      } else {
        this.carritoInterno.push({
          ...item,
          cantidad: 1,
          precioProductoTotalUnitario: !!item.precio ? item.precio : 0,
          precioProductoTotal: !!item.precio ? item.precio : 0,
        });
      }
      this.modificacion();
    },

    modificacion() {
      this.guardado = false;
      this.$forceUpdate();
    },
    cantidadCarrito(p) {
      let c = this.carritoInterno.filter((pC) => pC.id == p.id);
      let cantidad = c.reduce((a, b) => a + b.cantidad, 0);
      return cantidad > 0 ? "x " + cantidad : false;
    },
    cambiarPagina(i) {
      this.$store.dispatch("cambiarPagina", i);
    },
    clickCategoria(c) {
      this.categoriaSelecionada = c.id;
      this.pasoPidiendo = 1;
    },
    guardar() {
      this.loadingSave = true;
      const recargar = !this.pedidoEnCurso.id;
      this.$store.commit("setForzarMuestraMensaje", true);
      return this.$store
        .dispatch("internos/savePedido")
        .then((r) => {
          this.$store.commit("snackbar/setMensajeSuccess", {
            msj: "Pedido guardado",
          });
          if (recargar) this.$store.dispatch("internos/getPedidos");

          this.guardado = true;
        })
        .catch((e) => {
          console.log(e);
          this.$store.commit("snackbar/setMensajeDanger", {
            msj: "Error al guardar el pedido: " + e,
          });
        })
        .finally(() => {
          this.loadingSave = false;
          this.timeoutGrabar = null;
          setTimeout(() => {
            this.$store.commit("setForzarMuestraMensaje", false);
          }, 6000);
        });
    },
  },
};
</script>

<style lang="scss" scoped>.pedido-categoria {
  border: 2px solid #32cd32 !important;
}

.pedido-articulo {
  border: 2px solid #87cefa !important;
}</style>