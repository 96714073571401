import moment from 'moment-timezone';
import { mostrarMoneda, redondeo } from '@/utils/helper.utils.js';
import { quitarAcentos, idToTexto, lineaQz } from './utils';
import { comandosF, valoresTicketDinamico } from './comandos';

export default async function (obj) {
  const { data, logo, productosFinales, ticket } = obj;

  //variables dinámicas de ticket
  const ticketDinamico = valoresTicketDinamico(ticket);
  const comandos = comandosF(ticket);
  const negocio = data.copyNegocio;
  const restaurante = data.copyRest;
  const timezone =
    restaurante &&
    restaurante.confGeneral &&
    restaurante.confGeneral.zonaHoraria
      ? restaurante.confGeneral.zonaHoraria
      : 'Europe/Madrid';
  var fechaMoment = data.fechaServidor.seconds
    ? moment.unix(data.fechaServidor.seconds).tz(timezone)
    : moment.unix(data.fechaServidorFinalEjecucion.seconds).tz(timezone);
  var formaPago =
    data.forma_pago == 'tarjeta_online'
      ? 'PAGADO ' + idToTexto(data.forma_pago).toUpperCase()
      : Array.isArray(data.forma_pago)
      ? idToTexto(data.forma_pago.join(', ')).toUpperCase()
      : idToTexto(data.forma_pago).toUpperCase();
  var cabeceraMostrar = '';
  if (data.copyMesa && data.modoCopy) {
    cabeceraMostrar =
      'MESA ' + data.copyMesa.nombre + ' ' + data.modoCopy.nombre;
  } else if (data.modoCopy) {
    let num = data.numPedidoGofo
      ? ' G' + data.numPedidoGofo
      : data.numPedidoRestDiaModoMostrar
      ? ' ' + data.numPedidoRestDiaModoMostrar
      : data.numPedidoRestDia
      ? ' ' + data.numPedidoRestDia
      : '';
    cabeceraMostrar = data.modoCopy.nombre + num;
  }
  if (!cabeceraMostrar) {
    switch (data.modo) {
      case 'domicilio':
        res = '';
        break;
      case 'recoger':
        res = 'RECOGER';
        break;
      case 'mesa':
        res = 'MESA ' + data.copyMesa.nombre;
        break;
      case 'mostrador':
        res = 'MOSTRADOR ';
        break;
    }
    cabeceraMostrar = res;
  }

  let direccion =
    data.modo == 'domicilio'
      ? quitarAcentos(
          data.direccion.calle +
            ' ' +
            data.direccion.numero +
            ' ' +
            data.direccion.adicional +
            ', ' +
            data.direccion.municipio +
            ' ' +
            data.direccion.cp +
            ' ' +
            data.direccion.provincia
        )
      : data.modo == 'recogida'
      ? 'RECOGIDA'
      : '';

  let devolverQz = [];

  devolverQz.push(...[comandos.QZ_INIT]);

  if (logo) {
    devolverQz.push(comandos.QZ_ALIGN_CENTER);
    devolverQz.push({
      type: 'raw',
      format: 'image',
      data: 'data:image/png;base64,' + logo,
      options: { language: 'ESCPOS', dotDensity: 'double' },
    });
    // devolverQz.push({
    //   type: 'pixel',

    //   format: 'image',
    //   flavor: 'base64',
    //   data: 'data:image/png;base64,' + logo,
    // });
  }

  devolverQz.push(
    ...[
      comandos.QZ_INIT, // init
      comandos.QZ_LINEA,
      comandos.QZ_ALIGN_CENTER,
      lineaQz(data.copyNegocio.nombre),
      lineaQz(data.copyRest.direccion),
      lineaQz(data.copyRest.cif + ' | ' + data.copyRest.telefono),
      comandos.QZ_ALIGN_CENTER,
      comandos.QZ_BOLD,
      lineaQz(
        'TICKET: 00' +
          data.numPedidoRest +
          ' - ' +
          fechaMoment.format('DD/MM/YYYY') +
          ' - ' +
          fechaMoment.format('HH:mm')
      ),
      comandos.QZ_FUENTE_GRANDE,
      comandos.QZ_LINEA,
      comandos.QZ_INVERTIR,
      lineaQz(' ' + cabeceraMostrar + ' '),
      comandos.QZ_LINEA,
      comandos.QZ_FUENTE_NORMAL,
      comandos.QZ_STOP_INVERTIR,
      comandos.QZ_ALIGN_LEFT,
    ]
  );

  if (data.direccion) {
    devolverQz.push(comandos.QZ_LINEA_HORIZONTAL);
    devolverQz.push(
      lineaQz(
        data.modo === 'domicilio' ? 'DIRECCION DE ENTREGA: ' + direccion : ''
      )
    );
  }

  if (
    (data.modo == 'domicilio' || data.modo == 'recogida') &&
    !!data.copyUser &&
    !!data.copyUser.phoneNumber
  )
    devolverQz.push(
      lineaQz('TELEFONO: ' + data.copyUser.phoneNumber.padStart(5))
    );

  if (data.modo == 'domicilio' || data.modo == 'recoger')
    devolverQz.push(
      lineaQz(
        data.modo == 'domicilio'
          ? 'TIEMPO ESTIMADO: ' + data.copyRest.tiempoDomicilio + ' min.'
          : 'TIEMPO ESTIMADO: ' + data.copyRest.tiempoRecogida + ' min.'
      )
    );

  if (data.restante) {
    devolverQz.push(lineaQz('RESTANTE POR PAGAR'));
  } else if (!!data.cobrado) {
    devolverQz.push(lineaQz('FORMA PAGO: ' + formaPago));
  }

  if (data.notaRestaurante)
    devolverQz.push(
      lineaQz(
        data.notaRestaurante
          ? 'COMENTARIO CLIENTE: ' + data.notaRestaurante
          : ''
      )
    );
  devolverQz.push(comandos.QZ_FUENTE_NORMAL);
  devolverQz.push(comandos.QZ_ALIGN_LEFT);
  devolverQz.push(comandos.QZ_BOLD);
  devolverQz.push(comandos.QZ_LINEA_HORIZONTAL);
  devolverQz.push(comandos.QZ_ALIGN_LEFT);
  var impuestos = 0;

  productosFinales
    .sort((a, b) =>
      a.ordenCat > b.ordenCat
        ? 1
        : b.ordenCat > a.ordenCat
        ? -1
        : a.orden > b.orden
        ? 1
        : -1
    )
    .forEach((prd) => {
      let s = '';
      if (prd.porPeso) {
        s =
          s +
          (prd.cantidad + 'kg ').padEnd(4) +
          quitarAcentos(!!prd.nombreEs ? prd.nombreEs : prd.nombre).substring(
            0,
            ticketDinamico.substring
          );
      } else {
        s =
          s +
          (prd.cantidad + 'x ').padEnd(4) +
          quitarAcentos(!!prd.nombreEs ? prd.nombreEs : prd.nombre).substring(
            0,
            ticketDinamico.substring
          );
      }

      s = s.padEnd(ticketDinamico.finLinea);
      let precioUnitario = !!prd.precioProductoTotalUnitarioPrevio
        ? prd.precioProductoTotalUnitarioPrevio
        : !!prd.precioProductoTotalUnitario
        ? prd.precioProductoTotalUnitario
        : prd.precio;
      precioUnitario = mostrarMoneda(precioUnitario, true).padStart(7);

      let precioTotal = !!prd.precioProductoTotalPrevio
        ? prd.precioProductoTotalPrevio
        : !!prd.precioProductoTotal
        ? prd.precioProductoTotal
        : prd.precio * prd.cantidad;
      impuestos = impuestos + redondeo(prd.impuestoCantidad * prd.cantidad);
      let precioTotalString = mostrarMoneda(precioTotal, true).padStart(7);
      s = s + precioUnitario + precioTotalString;
      devolverQz.push(lineaQz(s));
      if (prd.opciones) {
        let opcionesImpresas = [];
        prd.opciones.forEach((e, i) => {
          if (!opcionesImpresas.includes(e)) {
            let arrayUsar = Array.isArray(prd.opcionesNombreEs)
              ? prd.opcionesNombreEs
              : prd.opcionesName;
            opcionesImpresas.push(e);
            var ocurrencias = prd.opciones.reduce(
              (a, v) => (v === e ? a + 1 : a),
              0
            );
            let s = '';
            s =
              ocurrencias > 1
                ? '    ' + ocurrencias + '- ' + quitarAcentos(arrayUsar[i])
                : '    - ' + quitarAcentos(arrayUsar[i]);
            s = s.substring(0, ticketDinamico.substring + 4);
            devolverQz.push(lineaQz(s));
          }
        });
      }
      if (prd.descuentoPersonalizado) {
        let s = '';
        s = s + ' *** Descuento ' + prd.descuentoPorcentaje + '%';
        s = s.padEnd(ticketDinamico.finLinea);
        const descuentoCantidad = redondeo(
          prd.precioProductoTotalUnitarioPrevio -
            prd.precioProductoTotalUnitario
        );
        let precioUnitario = (
          '-' + mostrarMoneda(descuentoCantidad, true)
        ).padStart(7);
        let precioTotalString = (
          '-' + mostrarMoneda(descuentoCantidad * prd.cantidad, true)
        ).padStart(7);
        s = s + precioUnitario + precioTotalString;
        devolverQz.push(lineaQz(s));
      }
    });

  if (data.recargo && data.recargo > 0) {
    let s = '';
    s = s + '   Recargo domicilio ';
    s = s.padEnd(36);
    let precio = mostrarMoneda(data.recargo).padStart(5);
    s = s + precio;
    devolverQz.push(lineaQz(s));
  }

  const impuestosImprimir = data.impuestosTotal
    ? data.impuestosTotal
    : impuestos;

  let precioTotal = data.precioTotal;
  var precioFinal = 'TOTAL: ' + mostrarMoneda(precioTotal);
  precioFinal = precioFinal;

  devolverQz.push(comandos.QZ_ALIGN_LEFT);
  devolverQz.push(comandos.QZ_LINEA_HORIZONTAL);
  devolverQz.push(comandos.QZ_ALIGN_RIGHT);
  devolverQz.push(comandos.QZ_FUENTE_MEDIANA);

  if (!!data.cobrado) {
    if (
      data.forma_pago == 'efectivo' &&
      Array.isArray(data.cobradoDesglose) &&
      data.cobradoDesglose.length == 1
    ) {
      //sólo un pago
      let cambioFound = data.cobradoDesglose.find(
        (f) => f.formaPago == 'efectivo'
      );
      if (!!cambioFound && !!cambioFound.entregado) {
        devolverQz.push(
          lineaQz('ENTREGADO: ' + mostrarMoneda(cambioFound.entregado, true))
        );
        devolverQz.push(
          lineaQz(' CAMBIO: ' + mostrarMoneda(cambioFound.cambio, true))
        );
      }
    }
  }

  devolverQz.push(comandos.QZ_BOLD_NORMAL);
  if (data.descuentoPorcentaje) {
    devolverQz.push(
      lineaQz(
        'TOTAL SIN DESCUENTOS:' +
          mostrarMoneda(data.precioTotalPrevio).padStart(10, ' ')
      )
    );
    devolverQz.push(
      lineaQz(
        'DESCUENTO ' +
          data.descuentoPorcentaje +
          '%:' +
          ('-' + mostrarMoneda(data.descuentoCantidad)).padStart(10, ' ')
      )
    );
  }
  devolverQz.push(
    lineaQz(
      'BASE IMPONIBLE:' +
        mostrarMoneda(parseFloat(precioTotal) - impuestosImprimir).padStart(
          10,
          ' '
        )
    )
  );
  devolverQz.push(
    lineaQz('IMPUESTOS:' + mostrarMoneda(impuestosImprimir).padStart(10, ' '))
  );
  devolverQz.push(comandos.QZ_FUENTE_GRANDE);
  devolverQz.push(comandos.QZ_BOLD);
  devolverQz.push(lineaQz(precioFinal));
  devolverQz.push(comandos.QZ_LINEA);
  devolverQz.push(comandos.QZ_FUENTE_NORMAL);
  devolverQz.push(comandos.QZ_LINEA);
  devolverQz.push(comandos.QZ_ALIGN_CENTER);

  if (data.facturaCliente) {
    // create QRCode Image
    async function getQRCode(content) {
      return new Promise((resolve, reject) => {
        QRCode.toDataURL(content, (err, url) => {
          if (err) return reject(err);

          loadImage(url).then(resolve, reject);
        });
      });
    }
    const qrcode = await getQRCode(data.facturaCliente);

    devolverQz.push(comandos.QZ_ALIGN_CENTER);
    devolverQz.push(lineaQz('DESCARGUE SU FACTURA'));
    devolverQz.push({
      type: 'raw',
      format: 'image',
      data: 'data:image/png;base64,' + qrcode,
      options: { language: 'ESCPOS', dotDensity: 'double' },
    });
  }
  devolverQz.push(comandos.QZ_ALIGN_CENTER);

  devolverQz.push(lineaQz('Gracias por su pedido'));
  // devolverQz.push(lineaQz('By FunEats'));
  devolverQz.push(comandos.QZ_LINEA);
  devolverQz.push(comandos.QZ_LINEA);
  devolverQz.push(comandos.QZ_LINEA);
  devolverQz.push(comandos.QZ_LINEA);
  devolverQz.push(comandos.QZ_LINEA);
  devolverQz.push(comandos.QZ_LINEA);

  devolverQz.push(comandos.QZ_CORTE);
  devolverQz.push(comandos.QZ_INIT);

  return devolverQz;
}
