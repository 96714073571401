<template>
  <div>
    <v-dialog v-model="abrirFicha" content-class="dialogo--custom-fs rounded-lg">
      <v-card>
        <v-toolbar color="primary">
          <h2>Editar</h2>
          <v-spacer></v-spacer>
          <v-btn x-large icon text @click="abrirFicha = false">
            <v-icon class="black--text ma-auto">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <div class="text-h2 pa-12">Hello world!</div>
        </v-card-text>
        <v-card-actions class="justify-end py-4 acciones-footer">
          <v-btn color="success" @click="abrirDialogoCobrar = true">Cobrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col v-for="(card, i) in cards" :key="i" cols="6" md="3">
        <v-card elevation="4" :style="{ border: card.color }" class="rounded-lg" @click="abrirFicha = true">
          <v-responsive class="d-flex rounded-0" :aspect-ratio="1.618">
            <v-card-text style="position: relative" class="pa-0">
              <div class="pa-2 text-uppercase font-weight-black black--text"
                :class="$vuetify.breakpoint.mdAndDown ? 'body-2' : 'title'" :color="card.color">
                {{ card.title }} 001
              </div>
            </v-card-text>
            <v-card-title :class="$vuetify.breakpoint.mdAndDown ? 'body-2' : 'title'" class="font-weight-medium">
              David Q.</v-card-title>
            <v-card-subtitle class="text-uppercase text-truncate">
              domicilio
            </v-card-subtitle>
            <v-card-actions class="mt-4 acciones-footer">
              <v-list-item class="grow">
                <v-row align="end">
                  <v-col class="pb-0 px-0" cols="8">
                    <v-icon :size="$vuetify.breakpoint.mdAndDown ? '12' : '22'" color="blue-grey lighten-1" class="mr-1">
                      mdi-account-outline
                    </v-icon>
                    <span :class="$vuetify.breakpoint.mdAndDown ? 'body-2' : 'subtitle-1'
                      " class="mr-1 font-weight-medium black--text">06</span>
                    <span class="mr-1">-</span>
                    <v-icon :size="$vuetify.breakpoint.mdAndDown ? '12' : '22'" color="blue-grey lighten-1" class="mr-1">
                      mdi-clock-time-eight-outline
                    </v-icon>
                    <span :class="$vuetify.breakpoint.mdAndDown ? 'body-2' : 'subtitle-1'
                      " class="font-weight-medium black--text">02:45</span>
                  </v-col>
                  <v-col class="text-right pb-0 px-0">
                    <span :class="$vuetify.breakpoint.mdAndDown ? 'body-2' : 'title'
                      " class="font-weight-black">12,95 €</span>
                  </v-col>
                </v-row>
              </v-list-item>
            </v-card-actions>
          </v-responsive>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      abrirFicha: false,
      abrirDialogoCobrar: false,
      cards: [
        {
          title: "Fun Eats",
          src: "/img/fondoFichaFunEats.png",
          color: "2px solid #56a7a8",
        },
        {
          title: "Glovo",
          src: "/img/fondoFichaGlovo.png",
          color: "2px solid #f9c52e",
        },
        {
          title: "Just Eat",
          src: "/img/fondoFichaJustEat.png",
          color: "2px solid #f27700",
        },
        {
          title: "Uber Eats",
          src: "/img/fondoFichaUberEats.png",
          color: "2px solid #4fc83d",
        },
        {
          title: "Fun Eats",
          src: "/img/fondoFichaFunEats.png",
          color: "2px solid #56a7a8",
        },
        {
          title: "Just Eat",
          src: "/img/fondoFichaJustEat.png",
          color: "2px solid #f27700",
        },
        {
          title: "Glovo",
          src: "/img/fondoFichaGlovo.png",
          color: "2px solid #f9c52e",
        },

        {
          title: "Uber Eats",
          src: "/img/fondoFichaUberEats.png",
          color: "2px solid #4fc83d",
        },
      ],
    };
  },
  methods: {
    pruebaColor(card) {
      if (card <= 3) return "con-comensales";
      else if (card == 4) return "terminando";
      else if (card == 5) return "libre";
      else return "cobrada";
    },
  },
};
</script>

<style lang="scss">
/* Variables */
$principalColor: #00f4d9;
$conComensalesColor: #0188be;
$terminandoColor: #e06316;
$libreColor: #0ced72;
$cobradaColor: #ffab40;
$cobradaBgColor: #eceff1;
$eliminadaColor: #f44336;
$eliminadaBgColor: $cobradaBgColor;
$propioColor: #56a7a8;

.ficha-generica {
  border: 2px solid;

  &.v-card {
    border-color: $propioColor;
  }

  &.borde-blanco {
    border-color: white;
  }

  &.con-comensales {
    border-color: $conComensalesColor;

    .ficha-header {
      background-color: $conComensalesColor;
    }
  }

  &.terminando {
    border-color: $terminandoColor;

    .ficha-header {
      background-color: $terminandoColor;
    }
  }

  &.libre {
    border-color: $libreColor;

    .ficha-header {
      background-color: $libreColor;
    }
  }

  &.cobrada {
    background-color: $cobradaBgColor;
    border-color: $cobradaColor;

    .ficha-header {
      background-color: $cobradaColor;
    }
  }

  &.eliminada {
    background-color: $eliminadaBgColor;
    border-color: $eliminadaColor;
  }
}

.acciones-footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
}

.dialogo--custom-fs,
.dialogo--custom-fs>.v-card {
  height: 100%;
}

.dialogo--custom-fs:not(.v-dialog--fullscreen) {

  &.dialogo--no-fs,
  &.dialogo--no-fs>.v-card {
    height: auto;
  }
}

.dialogo--custom-fs {

  .v-card__title,
  .v-card__subtitle,
  .v-card__text,
  header.v-toolbar {
    +.theme--light.v-divider {
      border-color: #263238;
      /* blue-grey darken-4 */
    }
  }
}
</style>