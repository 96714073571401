<template>
  <v-dialog
    v-model="devolucionDialog"
    max-width="1200px"
    v-if="devolucionConfirmarObj"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    overlay-opacity="0.90"
    :content-class="$vuetify.breakpoint.mdAndUp ? 'elevation-0' : 'dialogo--custom-fs'"
    scrollable
  >
    <v-card
      class="transparent"
      :rounded="$vuetify.breakpoint.smAndDown ? '0' : 'lg'"
    >
      <v-card-title
        class="white py-3 pr-3"
        height="78"
      >
        <!-- <v-icon color="info" class="mr-4"> mdi-history </v-icon> -->
        <h2
          :class="$vuetify.breakpoint.smAndDown ? 'caption' : 'body-1'"
          class="font-weight-black"
        >
          DEVOLUCIÓN FACTURA

        </h2>
        <v-spacer />
        <v-btn
          fab
          :height="$vuetify.breakpoint.smAndDown ? 48 : 68"
          :width="$vuetify.breakpoint.smAndDown ? 48 : 68"
          color="primary"
          @click="devolucionDialog = false"
          top
          right
        >
          <v-icon
            :size="$vuetify.breakpoint.smAndDown ? 36 : 44"
            color="black"
          >mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-title
        class="white py-3 pr-3"
        height="78"
      >
        {{ subtitulo }}
      </v-card-title>
      <v-divider />

      <v-card-text class="pt-4 blue-grey lighten-5">
        <v-row class="pa-2 pa-md-0">
          <v-col
            v-for="ped in pedidosCobrados"
            :key="ped.id"
            cols="6"
            sm="4"
            md="3"
            class="pa-2 pa-md-3"
          >
            <pedido-card :pedido="ped" />
          </v-col>
        </v-row>
        <!-- <v-row
          class="pa-2 pa-md-0"
          v-else
        >
          <v-col
            v-for="ped in pedidosPaginados"
            :key="ped.id"
            cols="12"
            sm="6"
            md="3"
            class="pa-2 pa-md-3"
          >
            <pedido-card :pedido="ped" />
          </v-col> </v-row> -->

      </v-card-text></v-card></v-dialog>
</template>

<script>
import moment from 'moment';
import { chunkArray } from '@/utils/helper.utils.js';
import { mapState } from 'vuex';
import PedidoCard from './PedidoCard.vue';

export default {
  components: {
    PedidoCard,
  },
  data: () => {
    return {
      pasoAbono: 'elegirPedido', //elegirPedido, elegirProducto
      pedidoElegido: null,
    };
  },
  watch: {

  },
  computed: {
    ...mapState({
      devolucionConfirmarObj: (state) => state.pedidos.devolucionConfirmarObj
    }),
    subtitulo() {
      return this.devolucionConfirmarObj.desglose.codigoFactura
    },
    pedidoEditandoObj: {
      get() {
        return this.$store.state.pedidos.pedidoEditandoObj;
      },
    },
    pedidosCobrados() {
      return this.$store.getters['pedidos/pedidos'].filter((p) => {
        return !!p.cobrado;
      });
    },

    devolucionDialog: {
      get() {
        return this.$store.state.pedidos.devolucionDialog;
      },
      set(v) {
        this.$store.commit('pedidos/SET_DEVOLUCION_DIALOG', v);
      },
    },
  },
  methods: {},
  mounted() {

  }
};
</script>

<style></style>
