export default () => {
  return {
    trabajosContables: [],
    trabajosContablesListener: null,
    numerosContables: {
      numeroContableTpv: 0,
      numeroContableOnline: 0,
      numeroContableAbono: 0,
      numeroCierre: 0,
    },
    //codigoRestaurante: ''
  };
};
