<template>
  <v-row justify="center">
    <v-dialog
      :fullscreen="$vuetify.breakpoint.smAndDown"
      max-width="800px"
      overlay-opacity="0.90"
      v-model="actualizacionDialogComputed"
      scrollable
      persistent
    >
      <v-card>
        <v-card-title class="pa-0">
          <v-alert
            class="mb-0"
            border="left"
            colored-border
            type="info"
          >
            La versión {{ $store.state.version }} está disponible. Versión
            actual {{ versionServidor }}
          </v-alert>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text
          class="my-6"
          style="max-height: 400px"
        >
          <v-card-text v-html="cambios"> </v-card-text>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            dark
            x-large
            :loading="loading"
            color="red"
            @click="actualizacionDialog = false"
          >
            AHORA NO
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :loading="loading"
            x-large
            color="primary"
            class="black--text"
            @click="actualizar"
          >
            Actualizar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import moment from "moment";
export default {
  data: () => {
    return {
      loading: false,
    };
  },
  watch: {
    moverPedidoModoDialog(v) {
      if (!v) {
      }
    },
  },
  watch: {
    versionServidor(v) {

      if (this.versionServidor !== this.$store.state.version)
        this.actualizacionDialog = true;
    },
  },
  computed: {
    cambios() {
      return this.versionServidor &&
        this.$store.state.configuracionTpvGeneral.changelog &&
        this.$store.state.configuracionTpvGeneral.changelog[
        this.versionServidor
        ]
        ? this.$store.state.configuracionTpvGeneral.changelog[
        this.versionServidor
        ]
        : false;
    },
    actualizacionDialog: {
      get() {
        return this.$store.state.actualizacionDialog;
      },
      set(v) {
        this.$store.commit("setActualizacionDialog", v);
      },
    },
    versionServidor() {
      return this.$store.state.configuracionTpvGeneral &&
        this.$store.state.configuracionTpvGeneral["v2_version"]
        ? this.$store.state.configuracionTpvGeneral["v2_version"]
        : false;
    },
    actualizacionDialogComputed() {
      return (
        this.actualizacionDialog &&
        this.cambios &&
        this.versionServidor &&
        this.versionServidor !== this.$store.state.version
      );
    },
    actualizacionVersionServidor: {
      get() {
        return this.$store.state.actualizacionVersionServidor;
      },
      set(v) {
        this.$store.commit("setActualizacionVersionServidor", v);
      },
    },
  },
  methods: {
    actualizar() {
      this.loading = true;
      window.location.reload();
    },
  },
};
</script>

<style></style>