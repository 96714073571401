<template>
  <v-dialog
    v-model="devolucionConfirmarDialog"
    max-width="1200px"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    overlay-opacity="0.90"
    :content-class="$vuetify.breakpoint.mdAndUp ? 'elevation-0' : 'dialogo--custom-fs'"
    scrollable
  >
    <v-card
      v-if="!!desgloseObj"
      class=""
      :rounded="$vuetify.breakpoint.smAndDown ? '0' : 'lg'"
    >
      <v-card-title
        class="white py-3 pr-3"
        height="78"
      >
        <!-- <v-icon color="info" class="mr-4"> mdi-history </v-icon> -->
        <h2
          :class="$vuetify.breakpoint.smAndDown ? 'caption' : 'body-1'"
          class="font-weight-black"
        >
          ELIJA LOS PRODUCTOS A DEVOLVER O ABONAR

        </h2>
        <v-spacer />
        <v-btn
          fab
          :height="$vuetify.breakpoint.smAndDown ? 48 : 68"
          :width="$vuetify.breakpoint.smAndDown ? 48 : 68"
          color="primary"
          @click="devolucionConfirmarDialog = false"
          top
          right
        >
          <v-icon
            :size="$vuetify.breakpoint.smAndDown ? 36 : 44"
            color="black"
          >mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-title
        class="white pt-0 pb-3 pr-3 d-flex justify-space-between"
        height="78"
      >
        <v-chip
          color="black"
          class=" darken-2 title font-weight-black"
          label
          outlined
        >{{ subtitulo }}</v-chip>
        <v-chip
          label
          outlined
          color="black"
          class=" darken-2 title font-weight-black"
        >{{ fechaMostrar }}</v-chip>
        <v-chip
          label
          outlined
          color="black"
          class=" darken-2 title font-weight-black"
        >{{ total }}€</v-chip>
        <v-chip
          label
          outlined
          color="black"
          class=" darken-2 title font-weight-black"
        >{{ devolucionConfirmarObj.desglose.productos.reduce((acc, obj) => acc + obj.cantidad, 0) }} artículos</v-chip>
      </v-card-title>
      <v-divider />

      <v-divider />

      <v-card-text class="pt-4 blue-grey lighten-5">
        <v-card-title
          class=" pt-0 pb-3 pr-3"
          height="78"
        >
          <v-row
            class="pa-2 pa-md-0"
            justify="center"
            align="center"
            dense
          >
            <v-col
              cols="6"
              al
            >
            </v-col>
            <v-col
              cols="2"
              align="center"
            ><v-chip
                @click="todo"
                color="primary"
                class=" darken-2 title"
                label
              >Todo</v-chip>
              <v-chip
                @click="nada"
                color="primary"
                class="ml-2 darken-2 title"
                label
              >Nada</v-chip></v-col>
            <v-col
              cols="2"
              align="center"
            >
              <v-chip
                label
                color="primary"
                class=" darken-2 title"
              >{{ cantidadSeleccionada }} artículos</v-chip></v-col>

            <v-col
              cols="2"
              align="center"
            ><v-chip
                label
                color="primary"
                class=" darken-2 title mr-2"
              >{{ mostrarMoneda(totalSeleccionado) }}€</v-chip></v-col>


          </v-row>
        </v-card-title>
        <v-row
          class="pa-2 pa-md-0"
          justify="center"
          align="center"
          dense
          v-for="(prod, i) in desgloseObj.productos"
          :key="prod.id"
        >
          <v-col
            cols="6"
            al
          >
            <span class="text-h6 font-weight-bold">{{ prod.nombre }}</span>

          </v-col>
          <v-col
            cols="1"
            align="right"
            class="pr-2"
          >
            <v-btn
              :disabled="prod.cantidadSeleccionada < 1"
              @click="cambiar(i, 'menos')"
              outlined
              color="red"
              large
            ><v-icon>mdi-minus</v-icon></v-btn>

          </v-col>
          <v-col
            cols="1"
            class="pl-4"
          >
            <v-btn
              outlined
              @click="cambiar(i, 'suma')"
              color="success"
              large
              :disabled="prod.cantidadSeleccionada == prod.cantidad"
            ><v-icon>mdi-plus</v-icon></v-btn>
          </v-col>
          <v-col
            cols="2"
            align="center"
          >
            <span class="text-h6 subtitle-1 font-weight-bold">{{ prod.cantidadSeleccionada }} de {{ prod.cantidad
            }}</span>
          </v-col>
          <v-col
            cols="2"
            align="center"
          >
            <span class="text-h6 subtitle-1 font-weight-bold">{{ mostrarMoneda(prod.precioProductoTotalUnitario *
              prod.cantidadSeleccionada) }}€</span>
          </v-col>
        </v-row>

      </v-card-text>
      <v-card-actions>
        <v-btn
          large
          color="primary black--text"
          @click="devolucionConfirmarDialog = false"
        >
          Cancelar
        </v-btn><v-spacer />
        <v-btn
          large
          color="primary black--text"
          :disabled="cantidadSeleccionada == 0"
          @click="crearAbono"
        >
          Crear abono {{ mostrarMoneda(totalSeleccionado) }}€
        </v-btn>
      </v-card-actions>

    </v-card></v-dialog>
</template>

<script>
import moment from 'moment';
import { chunkArray } from '@/utils/helper.utils.js';
import { mapState } from 'vuex';
import PedidoCard from './PedidoCard.vue';
import { mostrarIdioma, redondeo, mostrarMoneda } from "@/utils/helper.utils.js";

export default {
  components: {
    PedidoCard,
  },
  data: () => {
    return {
      pasoAbono: 'elegirPedido', //elegirPedido, elegirProducto
      pedidoElegido: null,
      desgloseObj: null
    };
  },
  watch: {
    devolucionConfirmarDialog(v) {
      if (!v) {
        this.devolucionConfirmarObj = null
        this.desgloseObj = null
      } else {
        this.desgloseObj = JSON.parse(JSON.stringify(this.devolucionConfirmarObj.desglose))
        this.desgloseObj.productos = this.desgloseObj.productos.map(p => {
          return {
            ...p,
            cantidadSeleccionada: !isNaN(p.cantidadSeleccionada) ? p.cantidadSeleccionada : 0

          }
        })
      }
    }
  },
  computed: {
    ...mapState({
      // devolucionConfirmarObj: (state) => state.pedidos.devolucionConfirmarObj
    }),
    cobrarDialog: {
      get() {
        return this.$store.state.pedidos.cobrarDialog;
      },
      set(v) {
        this.$store.commit('pedidos/setCobrarDialog', v);
      },
    },
    totalSeleccionado() {

      return this.desgloseObj.productos.reduce((acc, obj) => obj.cantidadSeleccionada * obj.precioProductoTotalUnitario + acc, 0)
    },
    cantidadSeleccionada() {
      return this.desgloseObj.productos.reduce((acc, obj) => obj.cantidadSeleccionada + acc, 0)
    },
    devolucionConfirmarObj: {
      get() {
        return this.$store.state.pedidos.devolucionConfirmarObj
      },
      set(v) {

        this.$store.commit('pedidos/SET_DEVOLUCION_CONFIRMAR_OBJ', v)
      }
    },

    fechaMostrar() {
      return moment.unix(this.desgloseObj.moment).format('DD/MM/YYYY HH:mm')
    },
    total() {
      return mostrarMoneda(this.desgloseObj.pagado)
    },
    subtitulo() {
      return this.desgloseObj.codigoFactura
    },
    pedidoEditandoObj: {
      get() {
        return this.$store.state.pedidos.pedidoEditandoObj;
      },
    },
    pedidosCobrados() {
      return this.$store.getters['pedidos/pedidos'].filter((p) => {
        return !!p.cobrado;
      });
    },

    devolucionConfirmarDialog: {
      get() {
        return this.$store.state.pedidos.devolucionConfirmarDialog;
      },
      set(v) {
        this.$store.commit('pedidos/SET_DEVOLUCION_CONFIRMAR_DIALOG', v);
      },
    },
  },
  methods: {
    crearAbono() {
      this.$confirm(
        '¿Seguro que quieres crear un abono? Esta acción no se puede deshacer'
      ).then((r) => {
        if (r) {
          this.crearAbonoConfirmar();
        }
      });
    },
    crearAbonoConfirmar() {
      let pedidoCreadoAbono;
      this.abonoLoading = true;
      const aux = JSON.parse(JSON.stringify(this.desgloseObj.productos))
      const productosAux = aux.filter(p => p.cantidadSeleccionada > 0).map(p => {
        const c = p.cantidadSeleccionada;
        delete p.cantidadSeleccionada;
        let precioProductoTotal = p.precioProductoTotalUnitario * c;
        let baseImponible = redondeo(
          precioProductoTotal / (parseFloat(p.impuesto) + 1)
        );
        let impuestoTotal =
          precioProductoTotal -
          (baseImponible > 0 ? baseImponible : -baseImponible);
        return {
          ...p,
          cantidad: c,
          baseImponible: baseImponible,
          impuestosCantidad: impuestoTotal
        }
      })
      return this.$store
        .dispatch('pedidos/crearAbono', {
          pedido: this.devolucionConfirmarObj.pedidoObj,
          desglose: {
            ...this.desgloseObj,
            baseImponiblePago: productosAux.reduce((acc, obj) => acc + obj.baseImponible, 0),
            impuestosTotalesPago: productosAux.reduce((acc, obj) => acc + obj.impuestosCantidad, 0),
            pagado: this.totalSeleccionado,
            productos: productosAux
          },
        })
        .then((abono) => {
          //desglose.abonoContablePor = abono.codigoFactura;
          pedidoCreadoAbono = abono
          let cobradoDesgloseAux = [...this.devolucionConfirmarObj.pedidoObj.cobradoDesglose];
          cobradoDesgloseAux[this.devolucionConfirmarObj.indice].abonoContablePor =
            abono.cobradoDesglose[0].codigoFactura;

          return this.$store.dispatch('pedidos/savePedido', {
            id: this.devolucionConfirmarObj.pedidoObj.id,
            abonoCreado: moment().unix(),
            cobradoDesglose: cobradoDesgloseAux,
            abonoPedidoQueAbona: abono.id,
            abonoPedidoQueAbonaCopy: {
              id: abono.id,
              numPedidoRestDia: abono.numPedidoRestDia,
              numPedidoRestDiaModo: abono.numPedidoRestDiaModo,
              numPedidoRestDiaModoMostrar: abono.numPedidoRestDiaModoMostrar,
              numPedidoRest: abono.numPedidoRest,
            },
          });
        })
        .then(() => {
          this.devolucionConfirmarDialog = false;
          this.cobrarDialog = false;
          this.$store.dispatch('pedidos/setPedidoObj', pedidoCreadoAbono.id);
          this.cobrarDialog = true;

        })
        .finally(() => (this.abonoLoading = false));
    },
    todo() {
      const aux = this.desgloseObj.productos.map(p => ({
        ...p,
        cantidadSeleccionada: p.cantidad
      }))
      this.desgloseObj = {
        ...this.desgloseObj,
        productos: aux
      }
    },
    nada() {
      const aux = this.desgloseObj.productos.map(p => ({
        ...p,
        cantidadSeleccionada: 0
      }))
      this.desgloseObj = {
        ...this.desgloseObj,
        productos: aux
      }
    },
    mostrarMoneda(s) {
      return mostrarMoneda(s, true)
    },
    cambiar(i, a) {

      const valor = a == 'suma' ? 1 : -1
      const aux = this.desgloseObj.productos;
      aux[i].cantidadSeleccionada = (aux[i].cantidadSeleccionada ? parseInt(aux[i].cantidadSeleccionada) : 0) + valor;
      this.desgloseObj = {
        ...this.desgloseObj,
        productos: aux
      }
    }
  },
};
</script>

<style></style>
