<template>
  <v-dialog
    v-model="facturacionDialog"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    overlay-opacity="0.90"
    max-width="600"
    scrollable
    :content-class="$vuetify.breakpoint.mdAndUp
        ? 'mt-n4 dialogo--custom-fs elevation-0'
        : 'dialogo--custom-fs'
      "
  >
    <v-card
      v-if="pedidoEditandoObj"
      class="transparent"
      :class="$vuetify.breakpoint.mdAndUp ? '' : 'rounded-0'"
    >
      <v-card-title
        v-if="$vuetify.breakpoint.mdAndUp"
        class="py-6 transparent"
      >
        <v-btn
          fab
          :height="$vuetify.breakpoint.smAndDown ? 48 : 88"
          :width="$vuetify.breakpoint.smAndDown ? 48 : 88"
          color="primary"
          @click="facturacionDialog = false"
          absolute
          right
          :class="$vuetify.breakpoint.smAndDown ? 'mt-16' : 'mt-10 mr-n4'"
        ><v-icon
            :size="$vuetify.breakpoint.smAndDown ? 36 : 54"
            color="black"
          >mdi-close</v-icon></v-btn></v-card-title>
      <v-card-title
        :class="$vuetify.breakpoint.smAndDown
            ? 'caption'
            : $vuetify.breakpoint.mdAndDown
              ? 'title'
              : 'display-1'
          "
        class="text--primary rounded-t-lg blue-grey lighten-5"
      >
        <v-icon
          color="info"
          class="mr-4"
        > mdi-currency-eur </v-icon>
        <div>
          <span class="font-weight-bold">
            FACTURA - {{ $store.getters['pedidos/TITULO_PIDIENDO'] }}
          </span>
        </div>
        <v-btn
          v-if="$vuetify.breakpoint.smAndDown"
          fab
          height="48"
          width="48"
          color="primary"
          @click="facturacionDialog = false"
          absolute
          right
          class="mr-n2 mt-n1"
        ><v-icon
            size="36"
            color="black"
          >mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <!-- <v-card-text
        class="pt-4 blue-grey lighten-5"
        :class="
          pedidoEditandoObj.fechaMostrar || pedidoEditandoObj.copyUsuarioTpv
            ? 'rounded-0'
            : 'rounded-b-lg'
        "
      >
      </v-card-text> -->
      <v-card-title class="blue-grey lighten-5">Datos de facturación</v-card-title>
      <v-card-text
        class="blue-grey lighten-5"
        v-if="editarCliente"
      >
        <v-row class="my-0">
          <v-col cols="12">
            <v-checkbox
              label="Actualizar datos del cliente y pedido"
              v-model="actualizarDatosCliente"
            />
          </v-col>
          <v-col cols="12">
            <v-alert
              v-if="actualizarDatosCliente"
              type="warning"
              dense
              colored-border
              border="left"
              class="mb-0 rounded-lg"
              icon="mdi-alert"
            >Al cambiar los datos de facturación se guardarán en el
              cliente</v-alert></v-col>
          <v-col cols="12">
            <v-text-field
              label="Razón Social / Nombre Completo"
              v-model="editarCliente.facturacionRazonSocial"
              ref="facturacionRazonSocial"
              @click="focusCampo(editarCliente, 'facturacionRazonSocial')"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              label="CIF / NIF"
              v-model="editarCliente.facturacionNifCif"
              ref="facturacionNifCif"
              @click="focusCampo(editarCliente, 'facturacionNifCif')"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              label="Código Postal"
              v-model="editarCliente.facturacionCp"
              ref="facturacionCp"
              @click="focusCampo(editarCliente, 'facturacionCp')"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="Direccion"
              v-model="editarCliente.facturacionDireccion"
              ref="facturacionDireccion"
              @click="focusCampo(editarCliente, 'facturacionDireccion')"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              label="Municipio"
              v-model="editarCliente.facturacionMunicipio"
              ref="facturacionMunicipio"
              @click="focusCampo(editarCliente, 'facturacionMunicipio')"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              label="Provincia"
              v-model="editarCliente.facturacionProvincia"
              ref="facturacionProvincia"
              @click="focusCampo(editarCliente, 'facturacionProvincia')"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="blue-grey lighten-5 py-4 justify-end">
        <v-btn
          :loading="generarFacturaLoading"
          @click="generarFactura"
          :disabled="!camposFacturaValido"
          color="primary"
          class="black--text rounded-lg"
          large
        >GENERAR FACTURA</v-btn>
      </v-card-actions>
      <template v-if="pedidoEditandoObj.fechaMostrar || pedidoEditandoObj.copyUsuarioTpv
        ">
        <v-divider></v-divider>
        <v-card-actions
          class="py-3 blue-grey lighten-5"
          :class="$vuetify.breakpoint.mdAndUp ? 'rounded-b-lg' : 'rounded-0'"
        >
          <v-row>
            <v-col
              v-if="pedidoEditandoObj.fechaMostrar"
              cols="12"
              sm="6"
              class="pb-0 pb-sm-3"
            >
              <v-icon
                size="18"
                class="mr-2 mt-n1"
              > mdi-clock-outline </v-icon>Registrado a las
              <span class="font-weight-bold">
                &nbsp;{{ pedidoEditandoObj.fechaMostrar }}
              </span>
            </v-col>
            <v-col
              v-if="pedidoEditandoObj.copyUsuarioTpv &&
                pedidoEditandoObj.copyUsuarioTpv.nombre
                "
              cols="12"
              sm="6"
              :offset-sm="!pedidoEditandoObj.fechaMostrar ? 6 : 0"
              class="text-sm-right pt-0 pt-sm-3"
            >
              <v-icon
                v-if="$vuetify.breakpoint.xsOnly"
                size="18"
                class="mr-2 mt-n1"
              >
                mdi-account-box </v-icon>Atendido por
              <span class="font-weight-bold">
                &nbsp;{{ pedidoEditandoObj.copyUsuarioTpv.nombre }}
              </span>
            </v-col>
          </v-row>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      actualizarDatosCliente: true,
      generarFacturaLoading: false,
      editarCliente: {},
    };
  },
  watch: {
    facturacionDialog(v) {
      if (!v) {
        this.$store.commit('teclado/setMostrarTeclado', false);
        this.$store.commit('pedidos/setFacturandoPedido', false);
      } else {
        this.editarCliente = {
          //inicializamos valores si no los tiene
          facturacionRazonSocial: '',
          facturacionNifCif: '',
          facturacionCp: '',
          facturacionDireccion: '',
          facturacionProvincia: '',
          facturacionMunicipio: '',
          ...(this.pedidoEditandoObj && this.pedidoEditandoObj.copyUser
            ? this.pedidoEditandoObj.copyUser
            : {}),
        };
        // this.$store.commit("pedidos/setPedidoEditandoObjValor", {
        //   key: "copyUser",
        //   value: {
        //     //inicializamos valores si no los tiene
        //     facturacionRazonSocial: "",
        //     facturacionNifCif: "",
        //     facturacionCp: "",
        //     facturacionDireccion: "",
        //     facturacionProvincia: "",
        //     facturacionMunicipio: "",
        //     ...this.editarCliente,
        //   },
        // });
      }
    },
  },
  computed: {
    camposFacturaValido() {
      return (
        this.editarCliente &&
        this.editarCliente.facturacionRazonSocial &&
        this.editarCliente.facturacionRazonSocial.length > 0 &&
        this.editarCliente.facturacionNifCif.length > 0
      );
    },
    editarClienteOld: {
      get() {
        return this.pedidoEditandoObj ? this.pedidoEditandoObj.copyUser : {};
      },
      set(v) {

        // this.$store.commit("pedidos/setPedidoEditandoObjValor", {
        //   key: "copyUser",
        //   value: v,
        // });
      },
    },
    esContado() {
      return this.pedidoEditandoObj && this.pedidoEditandoObj.uid == '0';
    },

    numeroPidiendo() {
      return this.pedidoEditandoObj && this.numPedidoRestDiaModoMostrar
        ? this.pedidoEditandoObj.numPedidoRestDiaModoMostrar
        : this.pedidoEditandoObj.numPedidoRestDia
          ? this.pedidoEditandoObj.numPedidoRestDia
          : '-';
    },
    pedidoEditandoObj: {
      get() {
        return this.$store.state.pedidos.pedidoEditandoObj;
      },
      /* set(v) {
      this.$store.commit("pedidos/setPedidoEditandoObj", v);
    }, */
    },

    cambiandoCliente: {
      get() {
        return this.$store.state.cambiandoCliente;
      },
      set(v) {
        this.$store.commit('pedidos/setCambiandoCliente', v);
      },
    },
    facturacionDialog: {
      get() {
        return this.$store.state.pedidos.facturacionDialog;
      },
      set(v) {
        this.$store.commit('pedidos/setFacturacionDialog', v);
      },
    },
  },
  methods: {
    focusCampo(obj, prop) {
      if (this.$store.state.necesarioTeclado) {
        this.$store.commit('teclado/setMostrarTeclado', true);
        //this.$store.commit("teclado/setLimpiarInput", true);
        this.$store.commit('teclado/setActualValor', obj[prop]);
        this.$store.commit('teclado/setCampoEditarObj', obj);
        this.$store.commit('teclado/setCampoEditarPropiedad', prop);
        setTimeout(() => {
          let pos = obj[prop].length ? obj[prop].length : 0;
          this.$refs[prop].$el
            .querySelector('input')
            .setSelectionRange(pos, pos);
        }, 200);
      }
    },
    generarFactura() {
      this.generarFacturaLoading = true;

      var promesa = this.actualizarDatosCliente
        ? this.$store
          .dispatch('clientes/saveCliente', this.editarCliente)
          .then(() =>
            this.$store.dispatch('pedidos/savePedido', {
              id: this.pedidoEditandoObj.id,
              copyUser: {
                ...this.pedidoEditandoObj.copyUser,
                ...this.editarCliente,
              },
            })
          )
        : Promise.resolve();

      promesa
        .then((r) =>
          this.$store.dispatch('pedidos/generarFactura', {
            pedido: this.pedidoEditandoObj,
            pago: this.$store.state.pedidos.facturacionContableFacturar,
            copyUser: this.editarCliente,
          })
        )
        .then(() => {
          setTimeout(() => {
            let fDesglose = this.pedidoEditandoObj.cobradoDesglose.find(
              (c) =>
                c.codigoFactura ==
                this.$store.state.pedidos.facturacionContableFacturar
                  .codigoFactura
            );
            this.$store.dispatch('pedidos/imprimirFacturaSimplificada', {
              pedido: this.pedidoEditandoObj,
              factSimplificada: !!fDesglose
                ? fDesglose
                : this.$store.state.pedidos.facturacionContableFacturar,
            });
          }, 2500);

          this.facturacionDialog = false;
        })
        .finally(() => {
          this.generarFacturaLoading = false;
        });
    },
  },
};
</script>
