import { render, staticRenderFns } from "./ClienteDialog.vue?vue&type=template&id=09a28ba5"
import script from "./ClienteDialog.vue?vue&type=script&lang=js"
export * from "./ClienteDialog.vue?vue&type=script&lang=js"
import style0 from "./ClienteDialog.vue?vue&type=style&index=0&id=09a28ba5&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports