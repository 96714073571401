import { render, staticRenderFns } from "./ProductosCarrito.vue?vue&type=template&id=355f892d&v-if=productos%20%26%26%20productos.length%20%3E%200"
import script from "./ProductosCarrito.vue?vue&type=script&lang=js"
export * from "./ProductosCarrito.vue?vue&type=script&lang=js"
import style0 from "./ProductosCarrito.vue?vue&type=style&index=0&id=355f892d&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports