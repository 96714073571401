<template>
  <v-row>
    <v-col
      cols="12"
      md="8"
      xl="6"
      class="mx-auto pt-0"
    >
      <v-list
        elevation="1"
        class="rounded-lg"
        two-line
      >
        <v-list-item
          v-for="c in pedidosFinal"
          :key="c.id"
          :class="c > 1 ? 'border--list-item' : ''"
        >
          <v-list-item-title>{{ c.fechaMostrar }}
            <v-list-item-subtitle>{{ c.precioMostrar }}</v-list-item-subtitle>
          </v-list-item-title>
          <v-list-item-action>
            <v-btn
              rounded
              large
              color="primary"
              class="black--text"
            ><v-icon :large="$vuetify.breakpoint.mdAndUp">mdi-emoticon-outline</v-icon></v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list></v-col>
  </v-row>
</template>

<script>
import moment from "moment";
import { mostrarMoneda } from "@/utils/helper.utils.js";

export default {
  props: {
    pedidos: {
      require: true,
      default: [],
      type: Array,
    },
  },
  computed: {
    pedidosFinal() {

      return this.pedidos.map((p) => {
        return {
          ...p,
          fechaMostrar: moment(p.fechaServidor.toDate()).format(
            "DD-MM-YYYY HH:mm"
          ),
          precioMostrar: p.precioTotal ? mostrarMoneda(p.precioTotal) : "0.00",
        };
      });
    },
  },
};
</script>

<style></style>