var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","fullscreen":_vm.$vuetify.breakpoint.smAndDown,"overlay-opacity":"0.90","content-class":"mt-n4 dialogo--custom-fs","scrollable":"","max-width":"912"},model:{value:(_vm.zcierreDialogMostrar),callback:function ($$v) {_vm.zcierreDialogMostrar=$$v},expression:"zcierreDialogMostrar"}},[_c('v-card',{staticClass:"transparent"},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-card-title',{staticClass:"py-6 transparent"}):_vm._e(),_c('v-card-title',{staticClass:"text--primary rounded-t-lg blue-grey lighten-5",class:_vm.$vuetify.breakpoint.smAndDown
        ? 'caption'
        : _vm.$vuetify.breakpoint.mdAndDown
          ? 'title'
          : 'display-1'},[_vm._v("INICIO DIARIO ")]),_c('v-card-subtitle',{staticClass:"blue-grey lighten-5 body-1 font-weight-light py-2"},[_vm._v(" Hora de inicio: "),_c('strong',[_vm._v(_vm._s(_vm.inicioMostrar))])]),_c('v-divider'),_c('v-card-text',{staticClass:"pt-4 blue-grey lighten-5 border"},[_c('v-row',[_c('v-col',{staticClass:"flex-grow-1",attrs:{"cols":"12","md":"8"}},[_c('v-list',{staticClass:"rounded-lg",staticStyle:{"min-height":"100%"}},_vm._l((_vm.cuentasMostrar),function(c){return _c('v-list-item',{key:c.id,staticClass:"my-4"},[_c('v-row',{staticClass:"ma-0"},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(c.nombre)+" ")])],1),(!!c.marcaCaja)?_c('v-col',{staticClass:"text-center rounded-lg",class:_vm.campoCambiando == 'efectivo_' + c.id
                    ? 'green lighten-3'
                    : 'grey lighten-3',attrs:{"cols":"4"},on:{"click":function($event){return _vm.cambiarSeleccion(c.id)}}},[_c('div',{staticClass:"font-weight-bold"},[_vm._v("EFECTIVO")]),_c('div',{staticClass:"font-weight-bold text-h5 success--text"},[_vm._v(" "+_vm._s(c.efectivoMostrar)+" ")])]):_vm._e()],1)],1)}),1)],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-col',{staticClass:"flex-grow-1",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('KeyboardNumerico',{ref:"keyboardRefNumerico",attrs:{"keyboardClass":"keyboard-zinicio","noDividir":""},on:{"onChange":_vm.onChange,"onKeyPress":_vm.onKeyPress}})],1):_vm._e()],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"rounded-b-lg blue-grey lighten-5"},[_c('v-btn',{staticClass:"black--text rounded-lg",attrs:{"large":"","color":"red"},on:{"click":_vm.logout}},[_vm._v("Salir")]),_c('v-spacer'),_c('v-btn',{staticClass:"black--text rounded-lg",attrs:{"large":"","color":"primary"},on:{"click":_vm.inicioDia}},[_vm._v("Iniciar")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }