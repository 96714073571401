<template>
  <v-row class="pa-2 pa-md-0" v-if="!modoPaginacion">
    <v-col cols="6" sm="4" md="3" class="pa-2 pa-md-3" v-if="modo != 'gofo'">
      <pedido-card-add />
    </v-col>
    <v-col v-for="ped in pedidos" :key="ped.id" cols="6" sm="4" md="3" class="pa-2 pa-md-3">
      <pedido-card :pedido="ped" />
    </v-col>
  </v-row>
  <v-row class="pa-2 pa-md-0" v-else>
    <v-col cols="6" sm="4" md="3" class="pa-2 pa-md-3">
      <pedido-card-add v-if="modo != 'gofo'" />
    </v-col>
    <v-col v-for="ped in pedidosPaginados" :key="ped.id" cols="6" sm="4" md="3" class="pa-2 pa-md-3">
      <pedido-card :pedido="ped" />
    </v-col>
  </v-row>
</template>

<script>
import PedidosForm from '@/components/PedidosForm.vue';
import ProductosCarrito from '@/components/ProductosCarrito.vue';
import { mapState } from 'vuex';
import Cobrar from '../components/Cobrar.vue';
import PedidoCard from '../components/PedidoCard.vue';
import PedidoCardAdd from '../components/PedidoCardAdd.vue';
import { chunkArray } from '@/utils/helper.utils.js';

export default {
  components: {
    PedidosForm,
    ProductosCarrito,
    Cobrar,
    PedidoCard,
    PedidoCardAdd,
  },

  computed: {
    modoTexto() {
      return this.modo.toUpperCase();
    },
    modo() {
      return this.$route.params.modotipo;
    },
    modoid() {
      return this.$route.params.modoid;
    },
    ...mapState([
      'modoPaginacion',
      'pedidosPaginacionPedidos',
      'paginaPaginacionPedidos',
    ]),
    ...mapState({
      pedidoViendo: (state) => state.pedidos.pedidoEditandoObj,
    }),
    // pedidoViendo() {
    // 	return this.$store.state.pedido.pedidoEditandoObj;
    // },
    modoObj() {
      return this.$store.state.datosTpv.modos
        .filter((m) => !!m.tipoModo)
        .find((m) => m.id == this.modoid);
    },
    pedidoEditando: {
      get() {
        return this.$store.state.pedidos.pedidoEditando;
      },
      set(v) {
        this.$store.dispatch('pedidos/setPedidoObj', v);
      },
    },
    pedidos() {
      let res = this.$store.getters['pedidos/pedidos'].filter((p) => {
        return (
          p.modo == this.modo &&
          (p.modoId === undefined || p.modoId == this.modoid) &&
          (!this.modoObj || !p.eliminado || this.modoObj.mostrarEliminados) &&
          (!this.modoObj || !p.cobrado || this.modoObj.mostrarCobrados)
        );
      });

      return res;
    },
    pedidosPaginados() {
      let pedidos = chunkArray(this.pedidos, this.pedidosPaginacionPedidos);
      let res = pedidos[this.paginaPaginacionPedidos];
      this.$store.commit('setMaximoPaginacionPedidos', pedidos.length);
      this.$store.commit(
        'setMostrandoPaginacionPedidos',
        res ? res.length : 0
      );
      return res;
    },
  },
  methods: {},
  beforeDestroy() {
    this.$store.commit('setPaginacionManejando', null);
  },
  mounted() {
    this.$store.commit('setPaginacionManejando', 'pedidos');
    this.$store.dispatch('pedidos/setPedidoObj', null);
  },
};
</script>

<style></style>
