/*
 * JavaScript client-side example using jsrsasign
 */

// #########################################################
// #             WARNING   WARNING   WARNING               #
// #########################################################
// #                                                       #
// # This file is intended for demonstration purposes      #
// # only.                                                 #
// #                                                       #
// # It is the SOLE responsibility of YOU, the programmer  #
// # to prevent against unauthorized access to any signing #
// # functions.                                            #
// #                                                       #
// # Organizations that do not protect against un-         #
// # authorized signing will be black-listed to prevent    #
// # software piracy.                                      #
// #                                                       #
// # -QZ Industries, LLC                                   #
// #                                                       #
// #########################################################

/**
 * Depends:
 *     - jsrsasign-latest-all-min.js
 *     - qz-tray.js
 *
 * Steps:
 *
 *     1. Include jsrsasign 8.0.4 into your web page
 *        <script src="https://cdn.rawgit.com/kjur/jsrsasign/c057d3447b194fa0a3fdcea110579454898e093d/jsrsasign-all-min.js"></script>
 *
 *     2. Update the privateKey below with contents from private-key.pem
 *
 *     3. Include this script into your web page
 *        <script src="path/to/sign-message.js"></script>
 *
 *     4. Remove or comment out any other references to "setSignaturePromise"
 */

const qz = require('qz-tray');

var privateKey =
  '-----BEGIN PRIVATE KEY-----\n' +
  'MIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQDI14ZYwBxz4uLE\n' +
  '9p5lX2xT+Z/UzgRUnYK7seNC2TGGNE0L67bIt848r2dzOJFVioIsIQ+iaZucJI18\n' +
  'AsCDwih+E7megSdazgAIbqKJa8hNqCsYMirUH37GDiwc1Uak17pzbKLCJ8lbVywh\n' +
  '/UN/mkKY33whILbMmMqR5JKNqkK3RyTlXxARzahXmfPRksXl+xj8ylZjFeCxkx6q\n' +
  'hKNjutqOv5OslWLIptg+TXwcl+yAA1V5DxgJ08DIq1KgS8ZTIwxcFCfmmAaQQikx\n' +
  'wyKhvPSzHNjMrOFGAr3FIL8sK8bfOMi+LtF8rpZto04wU8p7OWsoKJaWBcT8FzlD\n' +
  'SXbSrQOTAgMBAAECggEAWo36kBeGfKXG00HS5yggIrjBXOTKxRADuvcY3bIqBzWb\n' +
  'q41K67McNPZtMWFG/gema5k4gGUXeU5t0IU5Ie8v7LCeF55qDSIml6ayeD11obX6\n' +
  'QD2WW/Aba9dGAnYTFQ4DjjlkJSBlRF7Ikkar4WO/NUhGonUqlpMpeqBRh7hFvnZz\n' +
  '4OHCeN13nb0bc+qFOoIg5VC6NQmqsVnNi8ceUNxaYir3Dn6e57QtXy74FxPNhuFH\n' +
  'WSCYYdGQnDmuDNSocjDNyG0PY4tsFu7I/oOJt1TkTQvVpcbaukXWeyViIIytoihO\n' +
  'HcSQRhI+oML6TyBNLsVNJFOCZlBDDwY2g01bTISygQKBgQDn1AgNaBpbAOAPPJQP\n' +
  'TgyXSzLlWsZh0PechAxwt+Z46IMWZZVqY5mrFn3+yKMSl3UMpSkoEVPo6o565d//\n' +
  'mupyP1kwoDTDJPBaa1/1vG5HM6I2WiV9oiXUlUH0dCZXQC2+8t9mbUkuhEHtGNfl\n' +
  '9+eedpcRMFhRCjbnbQnT3gMa0wKBgQDdyGeFJfQ0mARqUkkh0oL89sGTnpcKsKRA\n' +
  'NyZaMdLGzP59qcjmsTZWHSAX1XF0RSUmZ2D0q+NJ6HjHNgSiTJvRR6arNBm/VwWa\n' +
  'gUg4k0rrwUThNa4jE/kuqQyEDy2xrrUX3PpgTZrRx83ExiCwQO9dYyGmlIh5OiU7\n' +
  '2CNsOGd8QQKBgQDL0cP+nX9GcT+MxdLaneE+BxRJR4TRLRr11bbmRxjvRmUyBL/X\n' +
  '2JebLtw5bAbLarOzGWZU3t8qEUaRUJeer4HeTknJEaExzMEwjeTBNRizqva72IfL\n' +
  'rAqcm1lwZcnrvfY6QmgdMoo7xnX70XLHsixGSrdImnubTGK3F0ujpTaPlwKBgQCN\n' +
  '07iPp0PR1l0MeO6XmH13s3z7H/HLPkbrYdk+2JnH1BPy3H7DOBRdYWbClxZkmks1\n' +
  'keqozLse6nFCQ8MW3xLN9Joa5ciJWecVdRNwSpEgzolz09Y2THGPaz+7u9EmzWi1\n' +
  'h/T9XONiv/lQmrsQu5XXDKvaUSYtEAf7iy6r6C4VgQKBgE3DIevDRPdix2tGiQZl\n' +
  'T9o0BrkIEFsOxTu8UyXzMJ6XtYDqSIdHON/7OAJPxESYCOvoIFCQz8sacfiZ3oRd\n' +
  '2BjkGxCW7+vfKjns6op0idQSgE50+ARzGMTqRXRQ1shcHS/F75YbQH9eoxZU4K7C\n' +
  'AMyUNIaGdEgEwTbf35mZQ1ko\n' +
  '-----END PRIVATE KEY-----\n' +
  '';

qz.security.setSignatureAlgorithm('SHA512'); // Since 2.1
qz.security.setSignaturePromise(function (toSign) {
  return function (resolve, reject) {
    try {
      var pk = KEYUTIL.getKey(privateKey);
      var sig = new KJUR.crypto.Signature({ alg: 'SHA512withRSA' }); // Use "SHA1withRSA" for QZ Tray 2.0 and older
      sig.init(pk);
      sig.updateString(toSign);
      var hex = sig.sign();

      resolve(stob64(hextorstr(hex)));
    } catch (err) {
      console.error(err);
      reject(err);
    }
  };
});
