import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { db } from '@/firebase';
import firebase from 'firebase';
import MultiFiltersPlugin from './plugins/Multifilters';
import VuetifyConfirm from 'vuetify-confirm';
import * as VueGoogleMaps from 'vue2-google-maps';
import lineClamp from 'vue-line-clamp';
import Barcoder from 'barcoder';
import moment from 'moment';
require('@/assets/sign.message.js');

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDuLwfgGkATl--v_KliZ8OGr980E_1s6SU',
    libraries: 'places', // This is required if you use the Autocomplete plugin
  },
  installComponents: true,
});

Vue.use(lineClamp, {
  // plugin options
});

Vue.config.productionTip = false;
Vue.use(MultiFiltersPlugin);
Vue.use(VuetifyConfirm, {
  vuetify,
  buttonTrueText: 'Sí',
  buttonFalseText: 'No',
  color: 'warning',
  icon: 'warning',
  width: 350,
});

function updateIndicator() {
  store.commit('setOnlineComputadora', window.navigator.onLine);
}
window.addEventListener('online', updateIndicator);
window.addEventListener('offline', updateIndicator);
store.commit('setOnlineComputadora', window.navigator.onLine);

// store.commit("setLoaded", true);

firebase.auth().onAuthStateChanged(function (user) {
  // alert(user);

  if (
    window.location.href.includes('/iniciocomandero') &&
    !store.state.auth.loginComandero
  ) {
    store.commit('auth/SET_LOGIN_COMANDERO', false);
    store.dispatch('auth/firebaseLogout', false);
    store.commit('setLoaded', true);
  } else {
    if (user) {
      return db
        .collection('users')
        .doc(user.uid)
        .get()
        .then(async (doc) => {
          let user_db = {};
          if (doc.exists) {
            user_db = doc.data();
          }
          let user_combinado = { ...user, ...user_db };
          store.commit('auth/setUser', user_combinado);
          await db.collection('users').doc(user.uid).set(
            {
              ultimoLogin: moment().unix(),
            },
            { merge: true }
          );

          let promesaInicio = Promise.resolve();

          if (user_db.role == 'tpv') {
            //router.push('/dashboard')

            promesaInicio = store
              .dispatch('inicioTpvAlternativo')
              .then(() => {
                funcHora();
                //lector pistola, solo en tpv
                document.addEventListener('keydown', onKeyPressed, false);
                function onKeyPressed(e) {
                  var keyCode = e.keyCode;

                  if (keyCode == 13) {
                    store.commit('setPistolaLeyendo', false);
                    console.log('pistola');
                    console.log(store.state.pistolaTexto);
                    if (Barcoder.validate(store.state.pistolaTexto)) {
                      // if (Barcoder.validate('6151100036810')) {
                      store.commit(
                        'setPistolaTextoDefinitivo',
                        store.state.pistolaTexto
                      );
                    }
                  } else {
                    if (keyCode != 16) {
                      store.commit('setPistolaLeyendo', true);
                      store.commit(
                        'setPistolaTexto',
                        store.state.pistolaTexto + e.key
                      );
                      setTimeout(() => {
                        store.commit('setPistolaTexto', '');
                      }, 1000);
                      // commit('setPistolaTexto', '');
                    } else {
                    }
                  }
                }
              })
              .then(() => {
                if (!store.getters['cuenta'].ocultarDialogoUsuario)
                  store.commit('usuarios/setEleccionUsuarioDialog', true);
              });
          } else if (user_db.role == 'tpv-comandero') {
            promesaInicio = store.dispatch('inicioTpvAlternativo').then(() => {
              funcHora();
              console.log('store.state.datosTpv.modos');
              console.log(store.state.datosTpv.modos);
              // screen.orientation.lock("portrait");
              if (router.currentRoute.fullPath !== '/comandero')
                router.push('/comandero');
            });
          } else {
            router.push('/login');
            store.commit('setRestauranteObj', false);
            store.commit('setNegocioObj', false);
            store.commit('setLoaded', true);
          }
          promesaInicio.then(async () => {
            //presencia firebase
            if (user_db.role == 'tpv' || user_db.role == 'tpv-comandero') {
              //persistencia de usuario, añadir los roles necesarios
              // Fetch the current user's ID from Firebase Authentication.
              var uid = firebase.auth().currentUser.uid;
              var ip = await store.dispatch('getIp');

              var deviceId = uid;

              //si se desconecta otro usuario

              // Create a reference to this user's specific status node.
              // This is where we will store data about being online/offline.
              var userStatusDatabaseRef = firebase
                .database()
                .ref('/status/' + deviceId);
              var refStatus = userStatusDatabaseRef.child('state');
              refStatus.on('value', function (ss) {
                if (ss.val() !== 'online') {
                  // another window went offline, so mark me still online
                  setTimeout(() => {
                    let user = firebase.auth().currentUser; //usuario firebase
                    if (!!user) refStatus.set('online');
                  }, 300);
                }
              });
              //ref.onDisconnect().remove();
              var isOfflineForDatabase = {
                uid: uid,
                ip: ip,
                negocio: store.state.negocioObj.id,
                state: 'offline',
                restMarcaConectado:
                  !!store.getters.cuenta.marcaConectado &&
                  user_db.role == 'tpv',
                rest: store.state.auth.user.restauranteTpv,
                last_changed: firebase.database.ServerValue.TIMESTAMP,
              };

              var isOnlineForDatabase = {
                uid: uid,
                ip: ip,
                negocio: store.state.negocioObj.id,
                state: 'online',
                restMarcaConectado:
                  !!store.getters.cuenta.marcaConectado &&
                  user_db.role == 'tpv',
                rest: store.state.auth.user.restauranteTpv,
                last_changed: firebase.database.ServerValue.TIMESTAMP,
              };
              console.log(isOnlineForDatabase);
              var userStatusFirestoreRef = firebase
                .firestore()
                .doc('/status/' + deviceId);
              var isOfflineForFirestore = {
                uid: uid,
                ip: ip,
                state: 'offline',
                negocio: store.state.negocioObj.id,
                restMarcaConectado:
                  !!store.getters.cuenta.marcaConectado &&
                  user_db.role == 'tpv',
                rest: store.state.auth.user.restauranteTpv,
                last_changed: firebase.firestore.FieldValue.serverTimestamp(),
              };
              var isOnlineForFirestore = {
                uid: uid,
                state: 'online',
                negocio: store.state.negocioObj.id,
                ip: ip,
                restMarcaConectado:
                  !!store.getters.cuenta.marcaConectado &&
                  user_db.role == 'tpv',
                rest: store.state.auth.user.restauranteTpv,
                last_changed: firebase.firestore.FieldValue.serverTimestamp(),
              };
              firebase
                .database()
                .ref('.info/connected')
                .on('value', function (snapshot) {
                  // If we're not currently connected, don't do anything.
                  if (snapshot.val() == false) {
                    userStatusFirestoreRef.set(isOfflineForFirestore);
                    return;
                  }
                  userStatusDatabaseRef
                    .onDisconnect()
                    .set(isOfflineForDatabase)
                    .then(function () {
                      // The promise returned from .onDisconnect().set() will
                      // resolve as soon as the server acknowledges the onDisconnect()
                      // request, NOT once we've actually disconnected:
                      // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

                      // We can now safely set ourselves as 'online' knowing that the
                      // server will mark us as offline once we lose connection.
                      userStatusDatabaseRef.set(isOnlineForDatabase);
                      userStatusFirestoreRef.set(isOnlineForFirestore);
                      // console.log(store.state.ordenadorConf)
                      // if (nuevoDispositivo) {
                      //   store.dispatch('configuracion/volcarDispositivosAlServidor', [{ tipo: 'ordenador', productId: store.state.uniqueId, productName: 'Ordenador ' + store.state.uniqueId, ...isOnlineForFirestore }])
                      // } else {
                      //   store.dispatch('configuracion/volcarDispositivosAlServidor', [{ ...store.state.ordenadorConf, ...isOnlineForFirestore }])
                      // }
                    });
                });
              userStatusFirestoreRef.onSnapshot(function (doc) {
                var isOnline = doc.data().state == 'online';
                store.commit('setOnline', isOnline);
                // ... use isOnline
              });
            }
          });
        })
        .catch((e) => console.log(e))
        .finally(() => {
          store.commit('setLoaded', true);
        });
    } else {
      if (!router.currentRoute.path.includes('/iniciocomandero')) {
        //router.push('/login')
      }
      store.commit('setLoaded', true);
    }
  }
});

const funcHora = () => {
  store.commit('setHoraActual', new Date());
  setTimeout(funcHora, 5000);
};

//directiva longpress
import LongPress from 'vue-directive-long-press';

Vue.directive('long-press', LongPress);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
