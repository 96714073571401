<template>
  <div>
    <v-dialog
      :transition="$store.state.bajaCalidad ? 'none' : 'scale-transition'"
      v-model="cobrarDialog"
      :fullscreen="cobrarDialogFullscreen"
      v-if="cobrarDialog"
    >
      <v-card
        flat
        rounded="0"
        color="blue-grey lighten-5"
      >
        <v-toolbar
          dark
          style="z-index: 1"
          height="78"
        >
          <!-- <v-spacer /> -->
          <h2
            :class="$vuetify.breakpoint.smAndDown ? 'caption' : 'body-1'"
            class="font-weight-black"
          >
            {{ titulo }}
          </h2>
          <v-spacer />
          <v-btn
            v-if="$vuetify.breakpoint.mdAndUp"
            @click="
              $store.commit(
                'pedidos/SET_IMPRIMIR_DESPUES_COBRAR',
                !$store.state.pedidos.imprimirDespuesCobrar
              )
              "
            :class="$vuetify.breakpoint.smAndDown ? 'mr-2' : 'mr-4'"
            fab
            outlined
            :disabled="descuentoGestionando || variasFormasPago"
            :color="$store.state.pedidos.imprimirDespuesCobrar ? 'primary' : ''"
          >
            <v-icon :color="$store.state.pedidos.imprimirDespuesCobrar ? 'primary' : ''">mdi-printer</v-icon>
          </v-btn>
          <v-btn
            v-if="$vuetify.breakpoint.mdAndUp"
            @click="
              $store.commit(
                'pedidos/SET_ORDEN_CAT',
                !$store.state.pedidos.ordenCat
              )
              "
            :class="$vuetify.breakpoint.smAndDown ? 'mr-2' : 'mr-4'"
            fab
            outlined
            :color="$store.state.pedidos.ordenCat ? 'primary' : ''"
          >
            <v-icon :color="$store.state.pedidos.ordenCat ? 'primary' : ''">mdi-sort</v-icon>
          </v-btn>
          <v-btn
            v-if="$vuetify.breakpoint.mdAndUp"
            @click="$store.commit('pedidos/setHistorialDialog', true)"
            :class="$vuetify.breakpoint.smAndDown ? 'mr-2' : 'mr-4'"
            fab
            outlined
            :x-small="$vuetify.breakpoint.smAndDown"
          >
            <v-icon>mdi-history</v-icon>
          </v-btn>
          <v-btn
            class="mr-4"
            outlined
            fab
            @click="abrirCliente"
          >
            <v-icon v-if="pedidoViendo.uid == '0'">mdi-account</v-icon>
            <v-icon v-else>mdi-account-check</v-icon>
          </v-btn>
          <v-btn
            fab
            :height="$vuetify.breakpoint.smAndDown ? 48 : 68"
            :width="$vuetify.breakpoint.smAndDown ? 48 : 68"
            color="primary"
            @click="cobrarDialog = false"
            :loading="cobrarLoading"
            top
            right
          >
            <v-icon
              :size="$vuetify.breakpoint.smAndDown ? 36 : 44"
              color="black"
            >mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container fluid>
          <v-row v-if="!!pedidoViendo">
            <!-- col 1 -->
            <!-- COLUMNA IZQUIERDA TPV-->
            <v-col
              v-if="$vuetify.breakpoint.mdAndUp"
              cols="4"
              class="grey lighten-5 px-0 pt-0 pb-16"
              style="height: calc(100vh - 78px); position: relative"
            >
              <div
                class="scroll-y--helper"
                style="height: 100%"
              >
                <v-overlay
                  opacity="0.8"
                  :value="variasFormasPago || descuentoGestionando"
                />

                <v-row
                  v-if="seHaDivido"
                  align="center"
                  class="orange lighten-5 ma-0"
                >
                  <v-col
                    class="grow text-uppercase font-weight-bold grey--text text--darken-2 pr-0 pt-4"
                    style="line-height: 1"
                  >
                    <v-icon
                      color="warning"
                      class="mt-n1"
                    >mdi-alert-circle</v-icon>
                    Cuenta divida
                  </v-col>
                  <v-col class="shrink">
                    <v-btn
                      v-if="!seHaPagadoDividido"
                      :disabled="seHaPagadoDividido"
                      @click="
                        pedidoViendo.cobradoProgramado = [];
                      vaDividir = false;
                      cantidadPagarModel = pendienteCobrar;
                      "
                      color="warning"
                    >
                      <v-icon
                        left
                        small
                      >mdi-swap-horizontal</v-icon>
                      Cancelar
                    </v-btn>
                    <v-btn
                      v-else
                      @click="
                        pedidoViendo.cobradoProgramado = [];
                      vaDividir = false;
                      cantidadPagarModel = pendienteCobrar;
                      "
                      color="warning"
                    >
                      <v-icon
                        left
                        small
                      >mdi-swap-horizontal</v-icon>
                      Cancelar división
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="12"
                    class="pa-0"
                  >
                    <v-divider></v-divider>
                  </v-col>
                </v-row>
                <productos-carrito
                  v-if="cobroPorProductos &&
                    !pedidoViendo.cobrado &&
                    !seHaDivido &&
                    !seHaPagadoDividido
                    "
                  :productos="productosNoCobrados"
                  accion="cobrarPorProductos"
                  @onClickProducto="clickproducto"
                  esconder-comentarios="true"
                />
                <productos-carrito
                  v-else
                  :productos="productosFinalesTodoCobrado"
                  accion="cobrar"
                  esconder-comentarios="true"
                  :pagoDividido="true"
                />
              </div>
              <v-toolbar
                color="white"
                absolute
                bottom
                left
                style="width: 100%"
              >
                <div style="width: 100%">
                  <div class="d-flex">
                    <span class="text-h6 font-weight-black">TOTAL:</span>
                    <v-spacer />
                    <span class="text-h6 font-weight-black">{{ precioTotal }}€</span>
                  </div>
                </div>
              </v-toolbar>
            </v-col>
            <!-- DRAWER COMANDERO-->
            <template v-else>
              <v-navigation-drawer
                fixed
                right
                top
                temporary
                class="grey lighten-5 elevation-0"
                width="100%"
                style="z-index: 100"
                v-model="drawerComandero"
              >
                <v-btn
                  fab
                  :height="$vuetify.breakpoint.smAndDown ? 48 : 88"
                  :width="$vuetify.breakpoint.smAndDown ? 48 : 88"
                  color="primary"
                  @click="drawerComandero = false"
                  absolute
                  right
                  class="mr-n2"
                ><v-icon
                    :size="$vuetify.breakpoint.smAndDown ? 36 : 54"
                    color="black"
                  >mdi-close</v-icon></v-btn>
                <productos-carrito
                  :productos="pedidoViendo.productos"
                  accion="cobrar"
                  class="pt-14"
                  esconder-comentarios="true"
                />
              </v-navigation-drawer>
            </template>

            <!-- col 2 -->
            <v-col
              cols="12"
              md="4"
              style="position: relative; z-index: 99"
            >
              <!-- Alertas de pedido abonado -->
              <v-alert
                v-if="!!pedidoViendo.abonoPedidoOriginalCopy &&
                  !!pedidoViendo.abonoPedidoOriginalCopy.numPedidoRestDia
                  "
                outlined
                width="100%"
                type="warning"
                prominent
                dense
                border="left"
                class="mb-0 font-weight-bold"
                style="background-color: white !important"
              >
                Abono del pedido
                {{
                  pedidoViendo.abonoPedidoOriginalCopy
                    .numPedidoRestDiaModoMostrar
                }}
              </v-alert>
              <v-alert
                v-if="!!pedidoViendo.abonoPedidoQueAbonaCopy &&
                    !!pedidoViendo.abonoPedidoQueAbonaCopy.numPedidoRestDia
                    "
                outlined
                width="100%"
                type="warning"
                prominent
                dense
                border="left"
                class="mb-0 font-weight-bold"
                style="background-color: white !important"
              >
                Abonado por el pedido
                {{
                  pedidoViendo.abonoPedidoQueAbonaCopy
                    .numPedidoRestDiaModoMostrar
                }}
              </v-alert>

              <!-- varias formas de pago -->
              <v-row dense>
                <v-col
                  cols="12"
                  v-if="variasFormasPago"
                >
                  <v-btn
                    color="primary"
                    @click="desactivarVariasFormasPago"
                    large
                    block
                    height="64"
                    class="pl-0 metodo-pago--btn caption rounded-lg black--text font-weight-black"
                    :disabled="!!pedidoViendo.cobrado"
                  >
                    <v-list-item
                      two-line
                      class="text-none"
                    >

                      <v-list-item-content>
                        <v-list-item-title class="display-1 font-weight-black text-left">{{
                          mostrarMoneda(variasFormasPagoAPagar.total)
                        }}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-list-item-action-text class="black--text caption">A pagar:
                          <span class="font-weight-black title">{{
                            mostrarMoneda(variasFormasPagoAPagar.pagar, true)
                          }}</span></v-list-item-action-text>

                        <v-list-item-action-text class="black--text caption">Resto:
                          <span class="font-weight-black title">{{
                            mostrarMoneda(variasFormasPagoAPagar.restante)
                          }}</span></v-list-item-action-text>
                      </v-list-item-action>
                    </v-list-item>
                    <v-icon
                      color="grey darken-2"
                      right
                      size="34"
                    >mdi-close</v-icon>
                  </v-btn>
                </v-col>
                <!-- gestión de descuento-->
                <template v-else-if="descuentoGestionando">
                  <v-col cols="12">
                    <v-btn
                      color="primary"
                      @click="desactivarDescuentoGestionando"
                      large
                      block
                      height="64"
                      class="pl-0 metodo-pago--btn caption rounded-lg black--text font-weight-black"
                      :disabled="!!pedidoViendo.cobrado"
                    >
                      <v-list-item
                        two-line
                        class="text-none"
                      >
                        <v-list-item-content>
                          <v-list-item-title class="display-1 font-weight-black text-left">{{ descuentoPorcentaje }}
                            %</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-list-item-action-text class="black--text caption">Descuento:
                            <span class="font-weight-black title">{{
                              mostrarMoneda(descuentoCantidad, true)
                            }}</span></v-list-item-action-text>

                          <v-list-item-action-text class="black--text caption">Total:
                            <span class="font-weight-black title">{{
                              mostrarMoneda(descuentoTotal, true)
                            }}</span></v-list-item-action-text>
                        </v-list-item-action>
                      </v-list-item>
                      <v-icon
                        color="grey darken-2"
                        right
                        size="34"
                      >mdi-close</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      color="success"
                      v-if="!this.pedidoViendo.descuentoPorcentaje"
                      @click="aplicarDescuento"
                      large
                      block
                      height="64"
                      class="pl-0 metodo-pago--btn subtitle-1 rounded-lg white--text font-weight-black"
                      :disabled="this.pedidoViendo.descuentoPorcentaje == this.descuentoPorcentaje || this.descuentoPorcentaje == 0 && !this.pedidoViendo.descuentoPorcentaje"
                    >
                      <v-icon
                        class="mr-2"
                        large
                      >mdi-check</v-icon> Aplicar descuento
                    </v-btn>
                    <v-btn
                      v-if="this.pedidoViendo.descuentoPorcentaje"
                      color="red"
                      @click="quitarDescuento"
                      large
                      block
                      height="64"
                      class="pl-0 metodo-pago--btn subtitle-1 rounded-lg white--text font-weight-black"
                    >
                      <v-icon
                        class="mr-2"
                        large
                      >mdi-delete</v-icon> Quitar descuento
                    </v-btn>
                  </v-col>
                </template>
                <template v-if="!variasFormasPago && !descuentoGestionando">
                  <v-col
                    cols="6"
                    v-if="!variasFormasPago"
                  >
                    <v-btn
                      color="primary"
                      @click="activarVariasFormasPago"
                      large
                      block
                      height="64"
                      class="metodo-pago--btn caption rounded-lg black--text font-weight-black"
                      :disabled="!!pedidoViendo.cobrado"
                    >
                      Varias formas
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="6"
                    v-if="!variasFormasPago"
                  >
                    <v-btn
                      color="pink"
                      :outlined="!pedidoViendo.descuentoPorcentaje"
                      @click="activarDescuento"
                      large
                      block
                      height="64"
                      class="metodo-pago--btn caption rounded-lg white--text font-weight-black"
                      :disabled="!!pedidoViendo.cobrado"
                    >
                      Descuento
                    </v-btn>
                  </v-col></template>
              </v-row>
              <!-- modalidad de pago -->
              <v-btn-toggle
                v-if="!descuentoGestionando"
                v-model="formaPago"
                background-color="transparent"
                style="width: 100%"
                class=""
              >
                <v-row
                  class="my-3"
                  no-gutters
                >
                  <v-col class="">
                    <v-btn
                      large
                      block
                      height="64"
                      color="warning"
                      class="metodo-pago--btn caption rounded-lg black--text font-weight-black"
                      value="efectivo"
                      :disabled="!!pedidoViendo.cobrado ||
                        (variasFormasPago && variasFormasPagoAPagar.pagar == 0)
                        || descuentoGestionando
                        "
                    >
                      Efectivo
                    </v-btn>
                  </v-col>
                  <v-col class="mx-1">
                    <v-btn
                      large
                      block
                      height="64"
                      color="warning"
                      class="metodo-pago--btn caption rounded-lg black--text font-weight-black"
                      value="tarjeta_local"
                      :disabled="!!pedidoViendo.cobrado ||
                        (variasFormasPago && variasFormasPagoAPagar.pagar == 0)
                        || descuentoGestionando"
                    >
                      Tarjeta
                    </v-btn>
                  </v-col>
                  <v-col class="">
                    <v-btn
                      :disabled="!!pedidoViendo.cobrado ||
                        (variasFormasPago && variasFormasPagoAPagar.pagar == 0)
                        || descuentoGestionando"
                      large
                      block
                      height="64"
                      color="warning"
                      class="metodo-pago--btn caption rounded-lg black--text font-weight-black"
                      value="otro"
                    >
                      OTRO
                    </v-btn>
                  </v-col>
                </v-row>
              </v-btn-toggle>

              <!-- COBRAR VERSIÓN TPV-->
              <!-- <template v-if="$vuetify.breakpoint.mdAndUp"> -->
              <template>
                <!-- Alertas versión TPV en función de lo que esté operando-->
                <v-alert
                  v-if="vaDividir"
                  type="info"
                  dense
                  colored-border
                  border="left"
                  class="mt-3 mb-2 rounded-lg"
                >Elija el número para dividir la cuenta</v-alert>
                <v-alert
                  v-else-if="variasFormasPago &&
                    formaPago != 'efectivo' &&
                    variasFormasPagoAPagar.pagar == 0
                    "
                  type="info"
                  colored-border
                  border="left"
                  height="64"
                  class="rounded-lg"
                >Marque cantidad a cobrar.
                </v-alert>
                <v-alert
                  v-else-if="variasFormasPago && !formaPago"
                  type="info"
                  colored-border
                  border="left"
                  height="64"
                  class="rounded-lg "
                >Marque forma de pago.
                </v-alert>
                <v-alert
                  v-else-if="variasFormasPago && formaPago == 'efectivo'"
                  type="success"
                  height="64"
                  colored-border
                  border="left"
                  class="rounded-lg"
                >Marque efectivo recibido.
                </v-alert>
                <v-alert
                  v-else-if="descuentoGestionando"
                  type="info"
                  height="64"
                  colored-border
                  border="left"
                  class="rounded-lg  mt-3"
                >Marque porcentaje descuento.
                </v-alert>
                <!-- Teclado TPV-->

                <KeyboardNumerico
                  :disabled="!!pedidoViendo.cobrado"
                  keyboardClass="keyboard-cobrar"
                  ref="keyboardRef"
                  @onChange="onChange"
                  @onKeyPress="onKeyPress"
                  :input="inputKeyboard"
                />

              </template>

              <!-- BOTÓN COBRAR VARIAS FORMAS DE PAGO-->
              <v-btn
                v-if="variasFormasPago"
                :loading="cobrarLoading"
                @click="cobrarVariasFormasPago"
                :disabled="!pagarValido"
                block
                x-large
                height="64"
                color="primary"
                class="text-h6 font-weight-bold black--text rounded-lg my-3"
              >
                COBRAR<v-spacer />
                {{ mostrarMoneda(variasFormasPagoAPagar.pagar) }}
                €
              </v-btn>

              <!-- BOTONES COBRAR, VER FACTURA, COBRO PARCIAL, IMPRIMIR-->
              <template v-else>
                <v-btn
                  v-if="!cobrado && vaPagarTodo"
                  :loading="cobrarLoading"
                  @click="cobrar"
                  :disabled="!pagarValido"
                  block
                  x-large
                  height="64"
                  color="primary"
                  class="text-h6 font-weight-bold black--text rounded-lg my-3"
                >
                  COBRAR <v-spacer />{{ pendienteCobrarString }}€
                </v-btn>
                <v-btn
                  v-else-if="!cobrado && !vaPagarTodo"
                  @click="cobrarParcial"
                  :disabled="!pagarValido"
                  :loading="cobrarLoading"
                  block
                  x-large
                  height="64"
                  color="primary"
                  class="text-h6 font-weight-bold black--text rounded-lg my-3"
                >
                  COBRO PARCIAL <v-spacer />{{ cantidadPagarString }}€
                </v-btn>
                <!-- <v-btn
                  @click="abrirFacturaCliente(cobradoDesglose[0])"
                  v-else-if="!!cobradoDesglose[0] && !cobradoDesglose[0].ficheroFactura
                    "
                  :disabled="pedidoViendo.cobradoDesglose.length > 1"
                  block
                  x-large
                  height="64"
                  color="primary"
                  class="text-h6 font-weight-bold black--text rounded-lg my-3"
                >
                  FACTURAR
                </v-btn> -->
                <v-btn
                  :loading="loading"
                  @click="abrirFacturaCliente(cobradoDesglose[0])"
                  v-else-if="!!cobradoDesglose[0]"
                  :disabled="pedidoViendo.cobradoDesglose.length > 1"
                  block
                  x-large
                  height="64"
                  outlined
                  color="black"
                  class="text-h6 font-weight-bold black--text rounded-lg my-3"
                >
                  <template v-if="cobradoDesglose[0].ficheroFactura">VER FACTURA</template>
                  <template v-else-if="!!cobradoDesglose[0].generarQrUrl">GENERAR FACTURA</template>
                  <template v-else>GENERAR QR FACTURA</template>

                </v-btn>
                <v-btn
                  @click="abrirFacturaCliente(cobradoDesglose[0])"
                  v-else-if="!!cobradoDesglose[0] && !!cobradoDesglose[0].ficheroFactura
                    "
                  block
                  x-large
                  height="64"
                  color="primary"
                  class="text-h6 font-weight-bold black--text rounded-lg my-3"
                >
                  VER FACTURA
                </v-btn>
                <v-btn
                  @click="imprimir"
                  v-if="!!pedidoViendo.cobrado || !seHaPagadoPorProductos"
                  block
                  x-large
                  height="64"
                  color="light-blue darken-1"
                  class="body-2 font-weight-bold black--text rounded-lg my-3"
                >
                  <v-icon
                    small
                    class="mr-2"
                  >mdi-printer</v-icon>
                  IMPRIMIR
                  {{ !!pedidoViendo.facturaCliente ? '(incluye factura)' : '' }}
                </v-btn>
                <v-btn
                  v-else-if="pedidoViendo.cobradoDesglose &&
                    pedidoViendo.cobradoDesglose.length > 0 &&
                    !cobroPorProductos
                    "
                  @click="imprimir"
                  :disabled="variasFormasPago"
                  block
                  x-large
                  height="64"
                  color="light-blue darken-1"
                  class="body-2 font-weight-bold black--text rounded-lg my-3"
                >
                  <v-icon
                    small
                    class="mr-2"
                  >mdi-printer</v-icon>
                  IMPRIMIR COMANDA COMPLETA
                </v-btn>
                <v-btn
                  v-else-if="pedidoViendo.cobradoDesglose &&
                    pedidoViendo.cobradoDesglose.length > 0 &&
                    !!cobroPorProductos
                    "
                  :disabled="variasFormasPago"
                  @click="imprimirRestante"
                  block
                  x-large
                  height="64"
                  color="light-blue darken-1"
                  class="body-2 font-weight-bold black--text rounded-lg my-3"
                >
                  <v-icon
                    small
                    class="mr-2"
                  >mdi-printer</v-icon>
                  IMPRIMIR RESTANTE POR COBRAR
                </v-btn>

                <v-btn
                  :disabled="variasFormasPago"
                  v-if="!seHaDivido &&
                    !!cobroPorProductos &&
                    !pedidoViendo.cobrado &&
                    (cobradoProgramado.length > 0 ||
                      (pedidoViendo.cobradoDesglose &&
                        pedidoViendo.cobradoDesglose.length > 0))
                    "
                  @click="cobroPorProductos = false"
                  block
                  x-large
                  height="64"
                  color="red light-4"
                  class="body-2 font-weight-bold black--text rounded-lg my-3"
                >
                  <v-icon
                    small
                    class="mr-2"
                  >mdi-eye</v-icon>
                  VER CARRITO COMPLETO
                </v-btn>
                <v-btn
                  v-else-if="!pedidoViendo.cobrado && !cobroPorProductos"
                  @click="cobroPorProductos = true"
                  :disabled="variasFormasPago"
                  block
                  x-large
                  height="64"
                  color="red darken-1"
                  class="body-2 font-weight-bold black--text rounded-lg my-3"
                >
                  <v-icon
                    small
                    class="mr-2"
                  >mdi-eye</v-icon>
                  COBRAR POR PRODUCTO
                </v-btn>
                <v-btn
                  v-if="(false && formaPago == 'tarjeta_local') ||
                    formaPago == 'otro'
                    "
                  @click="cobroPorProductos = true"
                  block
                  x-large
                  height="64"
                  color="red darken-1"
                  class="body-2 font-weight-bold black--text rounded-lg my-3"
                >
                  <v-icon
                    small
                    class="mr-2"
                  >mdi-currency-eur</v-icon>
                  PROPINA
                </v-btn>
              </template>

              <!-- VER PEDIDO VERSIÓN COMANDERO-->
              <v-btn
                v-if="$vuetify.breakpoint.smAndDown"
                block
                x-large
                height="64"
                color="primary"
                class="text-h6 font-weight-bold black--text rounded-lg my-3"
                @click="drawerComandero = true"
              >
                VER PEDIDO
              </v-btn>
            </v-col>

            <!-- col 3 -->
            <v-col
              cols="12"
              md="4"
              class="pb-0 order-first order-md-last"
              :class="$vuetify.breakpoint.mdAndUp ? 'pl-0' : ''"
              :style="$vuetify.breakpoint.mdAndUp ? 'height: calc(100vh - 78px)' : ''
                "
            >
              <div
                :class="$vuetify.breakpoint.mdAndUp ? 'scroll-y--helper' : ''"
                :style="$vuetify.breakpoint.mdAndUp ? 'height: 100%' : ''"
              >
                <v-card rounded="lg">
                  <v-list
                    class="my-0 py-0"
                    two-line
                  >
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="text-h6 font-weight-bold"><span class="font-weight-light">Ticket</span>
                          {{ numeroPidiendo }}</v-list-item-title>

                        <v-list-item-subtitle class="body-2">IVA.</v-list-item-subtitle>

                        <v-list-item-subtitle class="body-2">Subtotal</v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>

                        <v-list-item-action-text class="text-h6 font-weight-bold success--text"><span
                            class=" text-decoration-line-through subtitle-1 secondary--text mr-2"
                            v-if="pedidoViendo.descuentoPorcentaje"
                          >{{ mostrarMoneda(pedidoViendo.precioTotalPrevio) }}€</span>{{ precioTotal
                          }}€</v-list-item-action-text>
                        <v-list-item-action-text class="body-2">{{ impuestos }}€</v-list-item-action-text>
                        <v-list-item-action-text class="body-2">{{ subtotal }}€</v-list-item-action-text>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                  <v-divider v-if="!cobrado"></v-divider>
                  <v-card-text v-if="!cobrado">
                    <v-row>
                      <v-col
                        cols="6"
                        class="text-center"
                      >
                        <div class="font-weight-bold">PAGADO</div>
                        <div class="font-weight-bold text-h6 success--text">
                          {{ cantidadCobradaString }} €
                        </div>
                      </v-col>
                      <v-divider vertical></v-divider>
                      <v-col
                        cols="6"
                        class="text-center"
                        :class="campoCambiar == 'aPagar' ? 'grey lighten-2' : ''
                          "
                        @click="() => {
                          return;
                        }
                          "
                      >
                        <div class="font-weight-bold">PENDIENTE</div>
                        <div class="font-weight-bold text-h6 warning--text">
                          {{ pendienteCobrarString }} €
                          <!-- seleccionCampoCambiar('aPagar') -->
                        </div>
                      </v-col>
                      <template v-if="formaPago == 'efectivo'">
                        <v-col
                          style="position: relative; z-index: 99"
                          cols="6"
                          class="text-center"
                          :class="campoCambiar == 'entregado' ? 'grey lighten-2' : ''
                            "
                          @click="seleccionCampoCambiar('entregado')"
                        >
                          <div class="font-weight-bold">ENTREGADO</div>
                          <div class="font-weight-bold text-h5 success--text">
                            {{ entregadoString }}
                          </div>
                        </v-col>
                        <v-divider
                          vertical
                          style="position: relative; z-index: 99"
                        ></v-divider>
                        <v-col
                          cols="6"
                          class="text-center"
                          style="
                            position: relative;
                            z-index: 99;
                            background-color: white;
                          "
                        >
                          <div class="font-weight-bold">CAMBIO</div>
                          <div class="font-weight-bold text-h5 warning--text">
                            {{ cambioString }}
                          </div>
                        </v-col>
                      </template>
                    </v-row>
                  </v-card-text>
                </v-card>

                <v-card
                  class="my-3"
                  rounded="lg"
                  v-if="cobradoProgramado.length > 0 ||
                    (pedidoViendo.cobradoDesglose &&
                      pedidoViendo.cobradoDesglose.length > 0)
                    "
                >
                  <!-- POR COBRAR -->
                  <template v-if="cobradoProgramado.length > 0 && !seHaDivido">
                    <v-card
                      v-for="(p, i) in cobradoProgramado"
                      :key="'programado_' + i"
                      elevation="0"
                      rounded="lg"
                      @click="seleccionCobradoProgramado(i)"
                      class="py-0 overflow-hidden"
                    >
                      <v-list
                        :color="!!p.seleccionadoPagar && p.dividido
                          ? 'blue lighten-4'
                          : 'red lighten-5'
                          "
                        class="ma-2 py-0"
                        elevation="2"
                      >
                        <template v-if="p.productos && p.productos.length > 0">
                          <v-list-item
                            style="height: 60px"
                            class=""
                            v-if="p.dividido"
                          >
                            <v-list-item-content>
                              <v-list-item-title class="font-weight-bold">
                                {{ p.nombre }}
                              </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action class="text-right my-1">
                              <v-list-item-action-text class="subtitle-1 font-weight-black black--text">{{
                                p.cantidadPagarString
                              }}€</v-list-item-action-text>
                            </v-list-item-action></v-list-item>
                          <template v-for="(prod, j) in p.productos">
                            <v-divider
                              :key="'programado_' + i + '_' + j"
                              v-if="j > 0"
                            ></v-divider>
                            <v-list-item
                              :disabled="p.dividido"
                              :key="prod.id + '_' + i + '_' + j"
                              @click="eliminarCobradoProgramado(i, j)"
                              style="height: 60px"
                            >
                              <v-list-item-content>
                                <v-list-item-title class="font-weight-bold">
                                  {{ prod.nombre }}
                                </v-list-item-title>
                              </v-list-item-content>
                              <v-list-item-action class="text-right my-1">
                                <v-list-item-action-text class="subtitle-1 font-weight-black black--text">{{
                                  prod.cantidadPagarString
                                }}€</v-list-item-action-text>
                                <v-chip
                                  v-if="prod.cantidad !== 1"
                                  small
                                  color="primary"
                                  class="black--text border"
                                >x{{
                                  prod.cantidad }}</v-chip>
                              </v-list-item-action>
                            </v-list-item>
                          </template>
                        </template>
                        <!-- pago dividido, no por productos-->
                        <template v-else>
                          <v-list-item
                            style="height: 60px"
                            class=""
                          >
                            <v-list-item-content>
                              <v-list-item-title class="font-weight-bold">
                                {{ p.nombre }}
                              </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action class="text-right my-1">
                              <v-list-item-action-text class="subtitle-1 font-weight-black black--text">{{
                                p.cantidadPagarString
                              }}€</v-list-item-action-text>
                              <!-- <v-chip
                                v-if="p.cantidad !== 1"
                                small
                                color="primary"
                                class="black--text border"
                                >x{{ p.cantidad }}</v-chip
                              > -->
                            </v-list-item-action>
                          </v-list-item>
                        </template>
                      </v-list>
                    </v-card>
                  </template>
                  <!-- POR COBRAR PARCIAL -->
                  <template v-if="cobradoProgramado.length > 0 && !!seHaDivido">
                    <div
                      class="px-2 py-2"
                      v-for="(p, i) in cobradoProgramado"
                      :key="'programado_' + i"
                      @click="seleccionCobradoProgramado(i)"
                    >
                      <v-expansion-panels
                        focusable
                        class="rounded-lg pagado--expansion-panels"
                      >
                        <v-expansion-panel
                          class="pa-0"
                          :class="!!p.seleccionadoPagar && p.dividido
                            ? 'blue lighten-4'
                            : 'red lighten-5'
                            "
                        >
                          <v-expansion-panel-header class="pa-0 pr-2">
                            <v-list
                              class="transparent"
                              dense
                            >
                              <v-list-item
                                style="height: 60px"
                                class=""
                                v-if="p.dividido"
                              >
                                <v-list-item-content>
                                  <v-list-item-title class="font-weight-bold">
                                    {{ p.nombre }}
                                  </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action class="text-right my-1">
                                  <v-list-item-action-text class="subtitle-1 font-weight-black black--text">{{
                                    p.cantidadPagarString
                                  }}€</v-list-item-action-text>
                                </v-list-item-action></v-list-item>
                            </v-list>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content class="pagado--expansion-panels white">
                            <v-card
                              elevation="0"
                              rounded="lg"
                              class="my-0 pa-0"
                            >
                              <v-list
                                class="py-0"
                                elevation="0"
                                v-if="!!p.productos"
                                color="transparent"
                              >
                                <template v-for="(prod, j) in p.productos">
                                  <v-divider
                                    :key="'cobrado_' + i + '_' + j"
                                    v-if="j > 0"
                                  ></v-divider>
                                  <v-list-item
                                    :key="prod.id + '_' + i + '_' + j"
                                    class=""
                                    style="height: 60px"
                                  >
                                    <v-list-item-content>
                                      <v-list-item-title class="font-weight-bold">
                                        {{ (prod.nombre.es) ? prod.nombre.es : prod.nombre }}
                                      </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action class="text-right my-1">
                                      <v-list-item-action-text class="subtitle-1 font-weight-black black--text">{{
                                        mostrarMoneda(
                                          prod.precioProductoTotalUnitario *
                                          prod.cantidad
                                        )
                                      }}€</v-list-item-action-text>
                                      <v-chip
                                        v-if="prod.cantidad !== 1"
                                        small
                                        color="primary"
                                        class="black--text border"
                                      >x{{ prod.cantidad }}</v-chip>
                                    </v-list-item-action>
                                  </v-list-item>
                                </template>
                              </v-list>
                            </v-card>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </div>
                  </template>
                  <!-- COBRADOS -->
                  <template v-if="pedidoViendo.cobradoDesglose &&
                    pedidoViendo.cobradoDesglose.length > 0
                    ">
                    <div class="px-2 py-2">
                      <v-expansion-panels
                        focusable
                        class="rounded-lg pagado--expansion-panels"
                        v-model="expansionPanelCobradoDeglose"
                      >
                        <v-expansion-panel
                          v-for="(p, i) in cobradoDesglose"
                          :key="'cobrado_' + i"
                          class="green lighten-5 pa-0"
                        >
                          <v-expansion-panel-header class="pa-0 pr-2">
                            <v-list
                              class="transparent"
                              dense
                              :three-line="Array.isArray(p.formaPago)"
                            >
                              <v-list-item>
                                <v-list-item-icon class="my-auto">
                                  <v-icon
                                    v-if="p.formaPago == 'tarjeta_online' ||
                                      p.formaPago == 'tarjeta_local'
                                      "
                                    class="success--text"
                                  >mdi-credit-card-outline</v-icon>
                                  <v-icon
                                    v-else-if="p.formaPago == 'efectivo' && p.cashguard
                                      "
                                    class="success--text"
                                  >mdi-desktop-classic</v-icon>
                                  <v-icon
                                    v-else-if="p.formaPago == 'efectivo'"
                                    class="success--text"
                                  >mdi-circle-multiple-outline</v-icon>
                                  <v-icon
                                    v-else
                                    class="success--text"
                                  >mdi-contactless-payment-circle-outline</v-icon>
                                  <!-- Para dinero efectivo mdi-cash-100 -->
                                  <!-- Para Otro mdi-contactless-payment-circle-outline -->
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title
                                    v-if="Array.isArray(p.formaPago)"
                                    :class="!botonGrande ? 'body-1' : 'text-h3'"
                                  >Varias
                                    formas<v-list-item-subtitle>{{
                                      p.formaPagoNombre
                                    }}</v-list-item-subtitle></v-list-item-title>
                                  <v-list-item-title
                                    v-else
                                    :class="!botonGrande ? 'body-1' : 'text-h6'"
                                  >{{
                                    p.formaPagoNombre
                                  }}</v-list-item-title>
                                  <v-list-item-subtitle v-if="!!p.entregado">{{ p.entregado }}€ - Cambio:
                                    {{
                                      !!p.cambio ? p.cambio : '0.00'
                                    }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action
                                  class="font-weight-bold"
                                  :class="!botonGrande ? 'body-1' : 'text-h6'"
                                >
                                  {{ p.pagadoMostrar }}€
                                </v-list-item-action>
                              </v-list-item>
                            </v-list>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content class="pagado--expansion-panels white">
                            <v-card
                              elevation="0"
                              rounded="lg"
                              class="my-0 pa-0"
                            >
                              <v-list
                                class="py-0"
                                elevation="0"
                                v-if="!!p.productos"
                                color="transparent"
                              >
                                <template v-for="(prod, j) in p.productos">
                                  <v-divider
                                    :key="'cobrado_' + i + '_' + j"
                                    v-if="j > 0"
                                  ></v-divider>
                                  <v-list-item
                                    :key="prod.id + '_' + i + '_' + j"
                                    class=""
                                    style="height: 60px"
                                  >
                                    <v-list-item-content>
                                      <v-list-item-title class="font-weight-bold">
                                        {{ prod.nombre }}
                                      </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action class="text-right my-1">
                                      <v-list-item-action-text class="subtitle-1 font-weight-black black--text">{{
                                        mostrarMoneda(
                                          prod.precioProductoTotalUnitario *
                                          prod.cantidad
                                        )
                                      }}€</v-list-item-action-text>
                                      <v-chip
                                        v-if="prod.cantidad !== 1"
                                        small
                                        color="primary"
                                        class="black--text border"
                                      >x{{ prod.cantidad }}</v-chip>
                                    </v-list-item-action>
                                  </v-list-item>
                                </template>
                              </v-list>
                              <v-card-actions class="pt-2">
                                <v-row dense>
                                  <v-alert
                                    v-if="!!p.abonoContablePor"
                                    outlined
                                    width="100%"
                                    type="warning"
                                    prominent
                                    dense
                                    border="left"
                                    class="mb-0 font-weight-bold"
                                    style="background-color: white !important"
                                  >
                                    Abono del pago:
                                    {{ p.abonoContablePor }}
                                  </v-alert>
                                  <v-alert
                                    v-if="!!p.esAbono"
                                    outlined
                                    width="100%"
                                    type="warning"
                                    prominent
                                    dense
                                    border="left"
                                    class="mb-0 font-weight-bold"
                                    style="background-color: white !important"
                                  >
                                    Abona el pago:
                                    {{ pedidoViendo.abonoCodigoFactura }}
                                  </v-alert>
                                  <v-col
                                    cols="12"
                                    class="px-2"
                                  >
                                    Factura: {{ p.codigoFactura }}
                                  </v-col>
                                  <v-row
                                    no-gutters
                                    class="mx-n1 mb-n1"
                                  >
                                    <v-col cols="4">
                                      <v-btn
                                        @click="imprimirFacturaSimplificada(p)"
                                        block
                                        small
                                        height="64"
                                        color="light-blue darken-1"
                                        class="body-2 font-weight-bold black--text rounded-0"
                                      >
                                        <v-icon>mdi-printer</v-icon>
                                        <!-- IMPRIMIR -->
                                      </v-btn>
                                    </v-col>
                                    <v-col cols="4">
                                      <v-btn
                                        v-if="p.ficheroFactura"
                                        @click="abrirFacturaCliente(p)"
                                        block
                                        height="64"
                                        color="success"
                                        class="body-2 font-weight-bold black--text rounded-0"
                                      >
                                        <v-icon>mdi-eye</v-icon><!-- {{
                                        p.ficheroFactura ? "VER FACT" : "FACTURAR"
                                      }} -->
                                      </v-btn>
                                      <v-btn
                                        v-else-if="p.generarQrUrl"
                                        @click="abrirFacturaCliente(p)"
                                        block
                                        height="64"
                                        color="success"
                                        class="body-2 font-weight-bold black--text rounded-0"
                                      >
                                        <v-icon>mdi-file-pdf-box</v-icon><!-- {{
                                        p.ficheroFactura ? "VER FACT" : "FACTURAR"
                                      }} -->
                                      </v-btn>
                                      <v-btn
                                        v-else
                                        @click="abrirFacturaCliente(p)"
                                        block
                                        height="64"
                                        color="primary"
                                        class="body-2 font-weight-bold black--text rounded-0"
                                      >
                                        <v-icon>mdi-file-pdf-box</v-icon><!-- {{
                                        p.ficheroFactura ? "VER FACT" : "FACTURAR"
                                      }} -->
                                      </v-btn>


                                    </v-col>
                                    <v-col cols="4">
                                      <v-btn
                                        :disabled="!!p.abonoContablePor || !!p.esAbono
                                          "
                                        @click="crearAbono(
                                          pedidoViendo.cobradoDesglose[i],
                                          i
                                        )
                                          "
                                        block
                                        height="64"
                                        color="yellow darken-1"
                                        class="body-2 font-weight-bold black--text rounded-0"
                                      >
                                        <v-icon>mdi-currency-eur-off</v-icon>
                                        <!-- ABONO -->
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                                </v-row>
                              </v-card-actions>
                            </v-card>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </div>
                  </template>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-container>

      </v-card>
    </v-dialog>
    <!-- <v-dialog v-model="cuentaAtrasCierre" max-width="400px" persistent>
      <v-card height="220px">
        <v-card-title>Se va a cerrar en {{ cuentaAtrasSegundos }} segundos</v-card-title>
        <v-card-actions>
          <v-row align="center" class="py-0">
            <v-col cols="12" sm="12" md="6">
              <v-card elevation="4" outlined class="rounded-lg pa-4" color="primary" @click="confirmarCerrar()">
                <v-responsive aspect-ratio="1.618" class="align-center">
                  <div class="d-flex text-center flex-column text-subtitle-2 text-uppercase">
                    <v-icon class="mb-2">mdi-close</v-icon>
                    CERRAR YA
                  </div>
                </v-responsive>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-card elevation="4" outlined class="rounded-lg pa-4" color="secondary" @click="cuentaAtrasCierre = false">
                <v-responsive aspect-ratio="1.618" class="align-center">
                  <div class="d-flex text-center flex-column text-subtitle-2 text-uppercase">
                    <v-icon class="mb-2">mdi-reload</v-icon>
                    NO CERRAR
                  </div>
                </v-responsive>
              </v-card>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ProductosCarrito from './ProductosCarrito.vue';
import { mostrarMoneda, isFloat, redondeo } from '@/utils/helper.utils.js';
import KeyboardNumerico from './KeyboardNumerico';
import moment, { monthsShort } from 'moment';
import firebase from 'firebase';
import { useCounter, useIdle } from '@vueuse/core'

export default {
  components: {
    ProductosCarrito,
    KeyboardNumerico,
  },
  data: () => ({
    loading: false,
    cobrarLoading: false,
    cuentaAtrasCierre: false,
    cuentaAtrasSegundos: 30,
    cuentaAtrasSegundosInterval: null,
    expansionPanelCobradoDeglose: 0,
    cobrarProductos: [],
    abonoLoading: false,
    formaPago: null,

    variasFormasPagoContenido: [],
    variasFormasPagoAPagar: {
      pagar: 0,
      total: 0,
      restante: 0
    },
    campoCambiar: '',
    inputKeyboard: '',
    cantidadPagarModel: 0,
    entregadoModel: 0,
    drawerComandero: false,

    lastActive: null,
    idle: null,

    descuentoPorcentaje: 0,
  }),
  watch: {
    idle(v) {

      if (v) {//pasó el tiempo
        this.cobrarDialog = false;
      }
    },
    variasFormasPago(v) {
      if (!v) {
        this.variasFormasPagoContenido = [];
        this.variasFormasPagoAPagar = {};
        this.formaPago = '';
        this.inputKeyboard = '0';
      }
    },
    cuentaAtrasCierre(v) {
      if (!v) {
        this.cuentaAtrasSegundos = 30;
        if (!!this.cuentaAtrasSegundosInterval)
          clearInterval(this.cuentaAtrasSegundosInterval);
      }
    },
    cobrarDialog(v) {
      if (!v) {
        if (!!this.cuentaAtrasSegundosInterval)
          clearInterval(this.cuentaAtrasSegundosInterval);
        this.formaPago = null;
        this.descuentoGestionando = false;
        this.variasFormasPago = false;
        this.campoCambiar = '';
        this.inputKeyboard = '';
        this.vaDividir = false;
        this.desactivarDividir = false;
        this.desactivarVariasFormasPago();
        this.cantidadPagarModel = 0;
        this.entregadoModel = 0;
        this.$store.commit('pedidos/setCobrarDialogFullscreen', true);

        if (!!this.pedidoViendo.cobrado && !this.cambiandoCliente) {
          this.$store.commit('pedidos/setPedidoEditandoObj', {});
          this.$store.commit('pedidos/setPedidoEditando', null);
        } else if (!!this.pedidoViendo.cobrado && !!this.cambiandoCliente) {
          this.$store.commit('pedidos/setPedidoDialog', false);
        }
        this.$store.commit('pedidos/setPedidoEditandoObjValor', {
          key: 'cobradoProgramado',
          value: [],
        });

        this.$store.commit('pedidos/setCambiandoCliente', false);
      } else {

        if (this.cobradoDesglose.length > 0) {
          setTimeout(() => {
            this.desactivarDividir = true;

          }, 500)
        }
        let obj = useIdle(20 * 1000)
        // const { inc, count } = useCounter()
        this.lastActive = obj.lastActive
        this.idle = obj.idle;
        // const { idle, lastActive, reset } = useIdle(5 * 60 * 1000) // 5 min
        //se abre
        this.expansionPanelCobradoDeglose =
          this.cobradoDesglose.length === 1 ? 0 : null;

        this.cobroPorProductos = true;
        if (this.cobradoProgramado && this.cobradoProgramado.length > 0) {
          let fI = this.cobradoProgramado.findIndex((c) => !c.pagado);

          if (fI > -1) {
            this.seleccionCobradoProgramado(fI);
          }
        }

        this.$store.commit('pedidos/SET_IMPRIMIR_DESPUES_COBRAR', !this.configuracionTpv.minimoImprimir || parseFloat(this.pedidoViendo.precioTotal) > parseFloat(this.configuracionTpv.minimoImprimir))
      }
    },
    formaPago: function (v, oldV) {
      if (!v && this.variasFormasPago) {
        this.campoCambiar = 'aPagar';
      } else if (v === 'efectivo' && this.variasFormasPago) {
      }
      if (v === 'efectivo') {
        this.entregadoModel = 0;
        this.campoCambiar = 'entregado';
        this.inputKeyboard = '';
        if (this.$store.getters['usarCashGuard']) {
          this.$store.dispatch('cashguard/cobroEfectivo', {
            cantidad: this.vaPagarTodo
              ? this.pendienteCobrar
              : this.cantidadPagarModel,
            pedido: this.pedidoViendo.id,
          });
          this.$store.commit('cashguard/SET_CASHGUARD_BOTTOM', true);

          let accionSigue = this.variasFormasPago
            ? 'variasFormasPago'
            : this.vaPagarTodo
              ? 'cobroTotal'
              : 'cobroParcial';
          this.$store.commit('cashguard/SET_ACCION_SIGUE', accionSigue);
        }
      } else if (
        v !== 'efectivo' &&
        oldV === 'efectivo' &&
        this.$store.getters['usarCashGuard']
      ) {
        this.$store.commit('cashguard/SET_FICHERO_CASHGUARD', '');

        this.$store.dispatch('qz/pararTodasEscucha');
        this.$store.commit('cashguard/SET_CASHGUARD_BOTTOM', false);
      }
    },
    ficheroCashguardResultado: async function (v, oldV) {
      if (v != oldV && v === 'OK') {
        //puede que sea la acción salida-dinero que se gestiona en SalidaDineroDialog.vue
        switch (this.accionCashguardSigue) {
          case 'cobroTotal':
            await this.cobrar();
            this.cashguardBottom = false;
            this.$store.dispatch('cashguard/reiniciar');

            break;

          case 'variasFormasPago':
            await this.cobrarVariasFormasPago();
            this.cashguardBottom = false;
            this.$store.dispatch('cashguard/reiniciar');

            break;

          case 'cobroParcial':
            await this.cobrarParcial();
            this.cashguardBottom = false;
            this.$store.dispatch('cashguard/reiniciar');

            break;
        }
      } else if (v != oldV && v === 'KO') {
        this.cashguardBottom = false;
        this.formaPago = null;
        this.$store.dispatch('cashguard/reiniciar');
      }
    },
    eleccionUsuarioAdminAccionesRespuesta: {
      handler: function (v) {
        if (v.ok) {
          if (this.eleccionUsuarioAdminAccionesObj.accion == 'abono-pedido') {
            // this.crearAbono();
            if (this.eleccionUsuarioAdminAccionesObj.aux)
              this.crearAbonoConfirmar(
                this.eleccionUsuarioAdminAccionesObj.aux.desglose,
                this.eleccionUsuarioAdminAccionesObj.aux.i
              );
          }
          this.$store.commit(
            'usuarios/setEleccionUsuarioAdminAccionesRespuesta',
            { usuario: null, ok: false }
          );
          this.$store.commit(
            'usuarios/setEleccionUsuarioAdminAccionesObj',
            {}
          );
        }
      },
      deep: true,
    },
  },
  computed: {
    descuentoCantidad() {
      const precioTotal = this.pedidoViendo.precioTotalPrevio ? this.pedidoViendo.precioTotalPrevio : this.pedidoViendo.precioTotal
      return redondeo(precioTotal * this.descuentoPorcentaje / 100)
    },
    descuentoTotal() {
      const precioTotal = this.pedidoViendo.precioTotalPrevio ? this.pedidoViendo.precioTotalPrevio : this.pedidoViendo.precioTotal

      return redondeo(precioTotal - this.descuentoCantidad)
    },
    productosFinalesTodoCobrado() {
      return this.pedidoViendo.productos.sort((a, b) => {
        return a.recargo
          ? -1
          : b.recargo
            ? 1
            : this.$store.state.pedidos.ordenCat
              ? a.ordenCat < b.ordenCat
                ? 1
                : b.ordenCat < a.ordenCat
                  ? -1
                  : a.orden - b.orden
              : 1;
      })
    },
    accionCashguardSigue() {
      return this.$store.state.cashguard.accionSigue;
    },
    ficheroCashguardResultado: {
      get() {
        return this.$store.state.cashguard.ficheroCashguardResultado;
      },
      set(v) {
        this.$store.commit('cashguard/SET_FICHERO_CASHGUARD_RESULTADO', v);
      },
    },
    cashguardBottom: {
      get() {
        return this.$store.state.cashguard.cashguardBottom;
      },
      set(v) {
        this.$store.commit('cashguard/SET_CASHGUARD_BOTTOM', v);
      },
    },
    productosNoCobrados() {
      let productosCobrados = this.pedidoViendo.cobradoDesglose
        ? this.pedidoViendo.cobradoDesglose
          .map((c) => {
            return !!c.programado && !!c.programado.productos
              ? c.programado.productos
              : false;
          })
          .filter((p) => !!p)
          .flat()
        : [];
      let productosProgramadoCobro = this.cobradoProgramado
        .map((c) => c.productos)
        .filter((p) => !!p)
        .flat();

      const productosCobradosTodos = [
        ...productosCobrados,
        ...productosProgramadoCobro,
      ].map((p) => {
        return {
          ...p,
          array2Sorted: p.opciones ? p.opciones.slice().sort() : [],
        };
      });

      return [
        ...(this.pedidoViendo && this.pedidoViendo.productos
          ? this.pedidoViendo.productos
            .filter(
              (p) =>
                (!p.cobradoPorProducto && !p.menuId) ||
                (p.menuId &&
                  !productosCobradosTodos.find(
                    (pTodos) => pTodos.menuIdConcreto == p.menuIdConcreto
                  ))
            )
            .map((p) => {
              let coincide = productosCobradosTodos.filter((e) => {
                if (e.menuId) {
                  return e.menuIdConcreto == p.menuIdConcreto;
                } else {
                  var array2Sorted = p.opciones.slice().sort();
                  const opcionesComprobar = Array.isArray(e.opciones) ? e.opciones : []

                  return (
                    e.id === p.id &&
                    opcionesComprobar.length === p.opciones.length &&
                    opcionesComprobar
                      .slice()
                      .sort()
                      .every(function (value, index) {
                        return (
                          array2Sorted &&
                          array2Sorted[index] &&
                          value === array2Sorted[index]
                        );
                      })
                  );
                }
              });

              var cantidadRestar = 0;
              coincide.forEach((pCoincide) => {
                if (
                  p.precioProductoTotalUnitario ===
                  pCoincide.precioProductoTotalUnitario
                )
                  cantidadRestar += pCoincide.cantidad;
              });
              if (coincide.length > 0) {
                return {
                  ...p,
                  cantidad: redondeo(p.cantidad - cantidadRestar),
                  precioProductoTotal:
                    (p.cantidad - cantidadRestar) *
                    p.precioProductoTotalUnitario,
                };
              } else {
                return p;
              }
            })
          : []
        ).sort((a, b) => {
          return a.recargo
            ? -1
            : b.recargo
              ? 1
              : this.$store.state.pedidos.ordenCat
                ? a.ordenCat < b.ordenCat
                  ? 1
                  : b.ordenCat < a.ordenCat
                    ? -1
                    : a.orden - b.orden
                : 1;
        }),
      ];
    },
    productosNoCobradosImprimir() {
      //para imprimir productos restantes, hay q imprimir los que se están marcando para cobrar también
      let productosCobrados = this.pedidoViendo.cobradoDesglose
        ? this.pedidoViendo.cobradoDesglose
          .map((c) => {
            return !!c.programado && !!c.programado.productos
              ? c.programado.productos
              : false;
          })
          .filter((p) => !!p)
          .flat()
        : [];
      // let productosProgramadoCobro = this.cobradoProgramado
      //   .map((c) => c.productos)
      //   .filter((p) => !!p)
      //   .flat();

      const productosCobradosTodos = [
        ...productosCobrados,
        // ...productosProgramadoCobro,
      ].map((p) => {
        return {
          ...p,
          array2Sorted: p.opciones ? p.opciones.slice().sort() : [],
        };
      });

      var groupBy = function (xs, key) {
        return xs.reduce(function (rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      };

      return this.pedidoViendo && this.pedidoViendo.productos
        ? this.$store.getters['pedidos/CARRITO']
          .filter((p) => !p.cobradoPorProducto)
          .map((p) => {
            let coincide = productosCobradosTodos.filter((e) => {
              // return e.id === producto.id && (e.opciones).toString() === (opciones).toString();
              var array2Sorted = p.opciones.slice().sort();
              return (
                e.id === p.id &&
                e.opciones.length === p.opciones.length &&
                e.opciones
                  .slice()
                  .sort()
                  .every(function (value, index) {
                    return (
                      array2Sorted &&
                      array2Sorted[index] &&
                      value === array2Sorted[index]
                    );
                  })
              );
            });

            var cantidadRestar = 0;
            coincide.forEach((pCoincide) => {
              if (
                p.precioProductoTotalUnitario ===
                pCoincide.precioProductoTotalUnitario
              )
                cantidadRestar += pCoincide.cantidad;
            });
            if (coincide.length > 0) {
              return {
                ...p,
                cantidad: redondeo(p.cantidad - cantidadRestar),
                precioProductoTotal:
                  (p.cantidad - cantidadRestar) *
                  p.precioProductoTotalUnitario,
              };
            } else {
              return p;
            }
          })
          .sort((a, b) => {
            return a.recargo
              ? -1
              : b.recargo
                ? 1
                : this.$store.state.pedidos.ordenCat
                  ? a.ordenCat < b.ordenCat
                    ? 1
                    : b.ordenCat < a.ordenCat
                      ? -1
                      : a.orden - b.orden
                  : 1;
          })
        : [];
    },
    vaDividir: {
      get() {
        return this.$store.state.teclado.vaDividir;
      },
      set(v) {
        this.$store.commit('teclado/setVaDividir', v);
      },
    },
    desactivarDividir: {
      get() {
        return this.$store.state.teclado.desactivarDividir;
      },
      set(v) {
        this.$store.commit('teclado/setDesactivarDividir', v);
      },
    },
    variasFormasPago: {
      get() {
        return this.$store.state.teclado.variasFormasPago;
      },
      set(v) {
        this.$store.commit('teclado/setVariasFormasPago', v);
      },
    },
    descuentoGestionando: {
      get() {
        return this.$store.state.teclado.descuentoGestionando;
      },
      set(v) {
        this.$store.commit('teclado/setDescuentoGestionando', v);
      },
    },
    cobroPorProductos: {
      get() {
        return this.$store.state.teclado.cobroPorProductos;
      },
      set(v) {
        this.$store.commit('teclado/setCobroPorProductos', v);
      },
    },

    vaPagarTodo() {
      return (
        (this.cantidadPagarModel === 0 && !this.pedidoViendo.cobrado) ||
        this.cantidadPagarModel == this.pendienteCobrar
      );
    },
    cobradoProgramado: {
      get() {
        return this.pedidoViendo.cobradoProgramado
          ? this.pedidoViendo.cobradoProgramado.map((p) => {
            let cantidadPagar =
              !!Array.isArray(p.productos) &&
                p.productos.length > 0 &&
                !p.dividido
                ? p.productos
                  .map(
                    (prod) =>
                      prod.precioProductoTotalUnitario * prod.cantidad
                  )
                  .reduce((accumulator, a) => accumulator + a, 0)
                : p.cantidadPagar;
            return {
              ...p,
              productos:
                !!Array.isArray(p.productos) && p.productos.length > 0
                  ? p.productos.map((prod) => {
                    return {
                      ...prod,
                      cantidadPagar:
                        prod.precioProductoTotalUnitario * prod.cantidad,
                      cantidadPagarString: mostrarMoneda(
                        prod.precioProductoTotalUnitario * prod.cantidad
                      ),
                    };
                  })
                  : [],
              nombre:
                !!Array.isArray(p.productos) &&
                  p.productos.length > 0 &&
                  !p.dividido
                  ? 'Pago de productos'
                  : p.nombre,
              cantidadPagar: cantidadPagar,
              cantidadPagarString: mostrarMoneda(cantidadPagar),
            };
          })
          : [];
      },
      set(v) {
        this.$store.commit('pedidos/setPedidoEditandoObjValor', {
          key: 'cobradoProgramado',
          value: v,
        });
      },
    },
    cobradoDesglose() {
      return this.pedidoViendo.cobradoDesglose
        ? this.pedidoViendo.cobradoDesglose.map((c) => {
          var formaPagoMostrar = '';
          var fForma;
          if (Array.isArray(c.formaPago)) {
            formaPagoMostrar = c.variasFormasPago
              .map((fA) => {
                let fFormaA = this.$store.state.pedidos.formaPago.find(
                  (f) => f.id == fA.formaPago
                );
                return fFormaA
                  ? fFormaA.name + ' (' + fA.pagar + ')'
                  : fA.formaPago;
              })
              .join(', ');
          } else {
            fForma = this.$store.state.pedidos.formaPago.find(
              (f) => f.id == c.formaPago
            );
            if (fForma) formaPagoMostrar = fForma.name;
          }

          return {
            ...c,
            formaPagoNombre: formaPagoMostrar,
            pagadoMostrar: mostrarMoneda(c.pagado),
            productos: !!c.productos ? c.productos : [],
            codigoFactura: !!c.codigoFactura ? c.codigoFactura : '',
            entregado:
              fForma && fForma.id == 'efectivo'
                ? mostrarMoneda(c.entregado)
                : false,
            cambio:
              fForma && fForma.id == 'efectivo'
                ? mostrarMoneda(c.cambio)
                : false,
          };
        })
        : [];
    },
    titulo() {

      return this.tituloPidiendo + ' ' + (!!this.pedidoViendo && this.$vuetify.breakpoint.mdAndUp && this.pedidoViendo.fechaServidor
        ? moment(this.pedidoViendo.fechaServidor.toDate()).format('DD/MM/YYYY HH:mm')
        : '');
    },
    tituloPidiendo() {
      return this.$store.getters['pedidos/TITULO_PIDIENDO'];
    },
    numeroPidiendo() {
      return this.pedidoViendo &&
        this.pedidoViendo.numPedidoRestDiaModoMostrar
        ? this.pedidoViendo.numPedidoRestDiaModoMostrar
        : this.pedidoViendo.numPedidoRestDia
          ? this.pedidoViendo.numPedidoRestDia
          : '-';
    },
    cobrado() {
      return !!this.pedidoViendo.cobrado;
    },
    cambio() {
      let descontar = this.variasFormasPago
        ? this.variasFormasPagoAPagar.pagar
        : this.cantidadPagar;
      let aux = this.entregadoModel - descontar;
      return aux > 0 ? aux : 0;
    },
    cambioString() {
      return mostrarMoneda(this.cambio, true);
    },
    entregadoString() {
      return isNaN(mostrarMoneda(this.entregadoModel, true))
        ? mostrarMoneda(0, true)
        : mostrarMoneda(this.entregadoModel, true);
    },
    pagarValido() {
      return !this.pedidoViendo.cobrado && (
        !!this.formaPago &&
        (!this.variasFormasPago || this.variasFormasPagoAPagar.pagar > 0)
      );
    },
    cantidadPagar() {
      return this.cantidadPagarModel > 0
        ? this.cantidadPagarModel
        : this.pendienteCobrar;
      return this.variasFormasPago
        ? this.variasFormasPagoAPagar.pagar
        : this.cantidadPagarModel > 0
          ? this.cantidadPagarModel
          : this.pendienteCobrar;
    },
    cantidadPagarString() {
      return mostrarMoneda(this.cantidadPagar);
    },
    cantidadCobrada() {
      return Array.isArray(this.pedidoViendo.cobradoDesglose)
        ? this.pedidoViendo.cobradoDesglose.reduce((a, b) => a + b.pagado, 0)
        : 0;
    },
    cantidadCobradaString() {
      return mostrarMoneda(this.cantidadCobrada, true);
    },
    pendienteCobrarString() {
      return mostrarMoneda(this.pendienteCobrar);
    },
    pendienteCobrar() {
      return this.precioTotal - this.cantidadCobrada;
    },
    precioTotal() {
      return mostrarMoneda(this.pedidoViendo.precioTotal);
    },
    subtotal() {
      return mostrarMoneda(
        this.pedidoViendo.precioTotal - this.pedidoViendo.impuestosTotal
      );
    },
    impuestos() {
      return mostrarMoneda(this.pedidoViendo.impuestosTotal);
    },
    cobrarDialog: {
      get() {
        return this.$store.state.pedidos.cobrarDialog;
      },
      set(v) {
        this.$store.commit('pedidos/setCobrarDialog', v);
      },
    },
    ...mapState({
      pedidoViendo: (state) => state.pedidos.pedidoEditandoObj,
      cobrarDialogFullscreen: (state) => state.pedidos.cobrarDialogFullscreen,
      cambiandoCliente: (state) => state.pedidos.cambiandoCliente,
      eleccionUsuarioAdminAccionesRespuesta: (state) =>
        state.usuarios.eleccionUsuarioAdminAccionesRespuesta,
      eleccionUsuarioAdminAccionesObj: (state) =>
        state.usuarios.eleccionUsuarioAdminAccionesObj,
    }),
    ...mapState(["configuracionTpv"]),
    ...mapGetters({
      puedeAbonarPedido: 'usuarios/puedeAbonarPedido',
    }),
    ...mapGetters(['cuenta', 'botonGrande']),
    seHaPagadoPorProductos() {
      return (
        Array.isArray(this.pedidoViendo.cobradoDesglose) &&
        this.pedidoViendo.cobradoDesglose.filter(
          (p) => !!p.programado && !p.programado.dividido
        ).length > 0
      );
    },
    seHaPagadoDividido() {
      return (
        Array.isArray(this.pedidoViendo.cobradoDesglose) &&
        this.pedidoViendo.cobradoDesglose.filter(
          (p) => !!p.programado && !!p.programado.dividido
        ).length > 0
      );
    },
    seHaDivido() {
      //devuelve si se ha dividio el pago, tanto en cobradoDesglose como en cobradoProgramado
      return (
        (Array.isArray(this.pedidoViendo.cobradoDesglose) &&
          this.pedidoViendo.cobradoDesglose.filter((p) => !!p.dividido)
            .length > 0) ||
        (Array.isArray(this.pedidoViendo.cobradoProgramado) &&
          this.pedidoViendo.cobradoProgramado.filter((p) => !!p.dividido)
            .length > 0)
      );
    },
  },
  methods: {
    async quitarDescuento() {
      this.pedidoViendo.descuentoPorcentaje = 0;
      await this.$store.dispatch('pedidos/calcularTotales');
      await this.$store
        .dispatch('pedidos/savePedido', this.pedidoViendo)
      this.desactivarDescuentoGestionando();
    },
    async aplicarDescuento() {
      this.pedidoViendo.descuentoPorcentaje = this.descuentoPorcentaje;
      await this.$store.dispatch('pedidos/calcularTotales');
      await this.$store
        .dispatch('pedidos/savePedido', this.pedidoViendo)
      this.desactivarDescuentoGestionando();
    },
    desactivarDescuentoGestionando() {
      this.descuentoGestionando = false;
      this.descuentoPorcentaje = 0;
      this.inputKeyboard = '0';
      this.campoCambiar = '';
    },
    activarDescuento() {
      if (this.pedidoViendo.descuentoPorcentaje) {
        this.inputKeyboard = this.pedidoViendo.descuentoPorcentaje.toString();
        this.descuentoPorcentaje = this.pedidoViendo.descuentoPorcentaje;
      } else {
        this.descuentoPorcentaje = 0;
        this.inputKeyboard = '0';
      }
      this.descuentoGestionando = true;
      this.campoCambiar = 'descuentoCambiar';
    },
    generarQrFactura(c) {
      this.loading = true;
      this.$store.dispatch('pedidos/generarQrFactura', { pedido: this.pedidoViendo.id, objCobrado: c })
        .then(async (generarQrUrl) => {
          var aux = [...this.pedidoViendo.cobradoDesglose];

          let fI = aux.findIndex(
            (c2) => c2.codigoFactura == c.codigoFactura
          );

          aux[fI].generarQrUrl = generarQrUrl;
          this.$store.commit('pedidos/setPedidoEditandoObjValor', {
            key: 'cobradoDesglose',
            value: aux
          });

          await this.$store
            .dispatch('pedidos/savePedido', this.pedidoViendo)
          this.imprimirFacturaSimplificada(aux[fI])

        })

        .finally(() => this.loading = false)
    },
    cobrarVariasFormasPago() {
      this.variasFormasPagoContenido.push(
        JSON.parse(
          JSON.stringify({
            ...this.variasFormasPagoAPagar,
            formaPago: this.formaPago,
          })
        )
      );
      this.variasFormasPagoAPagar.pagar =
        this.variasFormasPagoAPagar.restante -
        this.variasFormasPagoAPagar.pagar;
      this.variasFormasPagoAPagar.restante =
        this.variasFormasPagoAPagar.pagar;
      this.formaPago = null;
      this.inputKeyboard = '0';
      this.campoCambiar = 'aPagar';

      if (this.variasFormasPagoAPagar.pagar == 0) {
        //ha pagado todo
        this.variasFormasPago = false;
        this.variasFormasPagoAPagar = {};

        if (this.cobradoProgramado.length > 0) {
          this.cobrarParcial();
        } else {
          this.cobrar();
        }
      }
    },
    desactivarVariasFormasPago() {
      this.variasFormasPago = false;
      this.cantidadPagarModel = 0
      this.variasFormasPagoAPagar = {};
      this.campoCambiar = '';
    },
    activarVariasFormasPago() {
      this.formaPago = null;
      this.inputKeyboard = '0';
      this.variasFormasPago = true;
      this.variasFormasPagoAPagar = {
        pagar: 0,
        total: this.vaPagarTodo ? this.pendienteCobrar : this.cantidadPagar,
        restante: this.vaPagarTodo
          ? this.pendienteCobrar
          : this.cantidadPagar,
      };
      this.campoCambiar = 'aPagar';
    },
    abrirCliente() {
      if (this.pedidoViendo.uid == '0') {
        // this.$store.commit("setAbrirInfoCliente", true);
        this.$store.commit('clientes/setPasoCliente', 'buscar');

        this.$store.commit('clientes/setClienteDialog', true);
        this.$store.commit('pedidos/setCambiandoCliente', true);
        // this.$store.commit("pedidos/setModoPantalla", "seleccionCliente");
        this.$store.commit('setAbrirInfoCliente', false);
        // this.$store.commit("pedidos/setCobrarDialogFullscreen", false);
        // this.$store.commit("pedidos/setPedidoDialog", true);
      } else {
        // this.$store.commit("setAbrirInfoCliente", true);
        this.$store.commit('clientes/setClienteDialog', true);
        this.$store.commit('clientes/setPasoCliente', 'formDatos');
      }
      this.$store.commit('pedidos/setFacturandoPedido', false);
    },
    mostrarMoneda(s, b = false) {
      return mostrarMoneda(s, b);
    },
    clickproducto(p, i) {

      var fI = 0;
      let aux = [...this.cobradoProgramado];
      let objDescuento = {
        precioProductoTotalUnitario: p.precioProductoTotalUnitario
      };
      if (p.descuentoPorcentaje) {
        // arrastramos el descuento al producto a la fact simplificada
        objDescuento.descuentoPersonalizado = !!p.descuentoPersonalizado;
        objDescuento.descuentoPorcentaje = p.descuentoPorcentaje;
        objDescuento.descuentoCantidadUnitario = p.descuentoCantidadUnitario
        // objDescuento.descuentoCantidad = p.descuentoCantidad
        objDescuento.precioProductoTotalUnitarioPrevio =
          p.precioProductoTotalUnitarioPrevio;
        // objDescuento.precioProductoTotalPrevio = precioProductoTotal;
        objDescuento.precioProductoTotalUnitario =
          p.precioProductoTotalUnitario
        // objDescuento.precioProductoTotal =
        //   objDescuento.precioProductoTotalUnitario * p.cantidad;
      }

      let productoAdd = {
        id: p.id,
        cantidad: p.cantidad >= 1 ? 1 : p.cantidad,
        precioProductoTotalUnitario: !!p.precioProductoTotalUnitario
          ? p.precioProductoTotalUnitario
          : p.precio,

        nombre: p.nombre,
        ...(p.menuId
          ? {
            menuId: p.menuId,
            menuIdConcreto: p.menuIdConcreto,
            menuIdGrupo: p.menuIdGrupo,
            // menuIdGrupoOrden: p.menuIdGrupoOrden,
            // menuCopy: p.menuCopy
          }
          : {
            opciones: p.opciones,
            opcionesName: p.opcionesName,
            opcionesPrecio: p.opcionesPrecio,
          }),

        impuesto: p.impuesto,
        ...objDescuento
        // impuestoCantidad: p.impuestoCantidad, este valor es el precio de todos los productos, no de los que se están pagando, que se calcula multiplicando el unitario por la cantidad pagada
      };

      if (aux.length > 0) {
        //ya hay una programación
        fI = aux.findIndex((c) => c.seleccionadoPagar);

        //if (fI <= -1) fI = aux.findIndex((c) => !c.pagado);
        if (fI > -1) {
          //buscamos el producto si existe
          if (!!aux[fI].productos) {
            let fIProducto = aux[fI].productos.findIndex(
              (pExisten) => pExisten.id == productoAdd.id
            );
            let coincide = aux[fI].productos.findIndex((e) => {
              if (productoAdd.menuId) {
                return productoAdd.menuIdConcreto == e.menuIdConcreto;
              } else {
                var array2Sorted = productoAdd.opciones.slice().sort();
                return (
                  e.id === productoAdd.id &&
                  e.opciones.length === productoAdd.opciones.length &&
                  e.opciones
                    .slice()
                    .sort()
                    .every(function (value, index) {
                      return value === array2Sorted[index];
                    })
                );
              }
            });
            if (coincide > -1) {
              aux[fI].productos[coincide].cantidad++;
            } else {
              aux[fI].productos.push(productoAdd);
            }
          }
        }
      } else {
        //no hay programación

        aux.push({
          nombre: 'Productos',
          pagado: false,
          seleccionadoPagar: true,
          productos: [productoAdd],
        });
        fI = 0;
      }

      this.cobradoProgramado = aux;
      this.seleccionCobradoProgramado(fI);
    },
    eliminarCobradoProgramado(i, j) {
      let aux = [...this.cobradoProgramado];

      aux[i].productos.splice(j, 1);
      if (aux[i].productos.length == 0) {
        aux.splice(i, 1);
        if (aux.length === 0) {
          this.cantidadPagarModel = this.pendienteCobrar;
        }
      }
      this.cobradoProgramado = aux;
    },
    seleccionCobradoProgramado(i) {
      let aux = [...this.cobradoProgramado];
      if (aux[i] && !aux[i].pagado) {
        aux.forEach((e, i) => {
          aux[i].seleccionadoPagar = false;
        });
        aux[i].seleccionadoPagar = true;
        if (aux[i].cantidadPagar > this.pendienteCobrar) {
          aux[i].pagoAjustadoFaltaba =
            aux[i].cantidadPagar - this.pendienteCobrar;
          aux[i].cantidadPagar = this.pendienteCobrar;
        }
        this.cantidadPagarModel = aux[i].cantidadPagar;
        this.cobradoProgramado = aux;
      }
    },
    abrirFacturaCliente(c) {
      if (c.ficheroFactura) {
        this.abrirFactura(c.ficheroFactura);
      } else if (c.generarQrUrl) {
        this.abrirFactura(c.generarQrUrl);
      } else {
        this.generarQrFactura(c)
      }
      // } else if (this.pedidoViendo.uid == '0') {
      //   // this.$store.commit("setAbrirInfoCliente", true);
      //   this.$store.commit('clientes/setClienteDialog', true);
      //   this.$store.commit('clientes/setPasoCliente', 'buscar');
      //   this.$store.commit('pedidos/setFacturacionContableFacturar', c);
      //   this.$store.commit('pedidos/setCambiandoCliente', true);
      //   // this.$store.commit("pedidos/setModoPantalla", "seleccionCliente");
      //   // this.$store.commit("setAbrirInfoCliente", false);
      //   // this.$store.commit("pedidos/setCobrarDialogFullscreen", false);
      //   // this.$store.commit("pedidos/setPedidoDialog", true);
      //   this.$store.commit('pedidos/setFacturandoPedido', true);
      // } else {
      //   this.$store.commit('clientes/setClienteDialog', true);
      //   this.$store.commit('clientes/setPasoCliente', 'facturar');
      //   this.$store.commit('pedidos/setFacturacionContableFacturar', c);
      // }
    },
    reiniciarCobro() {
      this.cantidadPagarModel = this.pendienteCobrar;
      this.formaPago = null;
      this.entregadoModel = 0;
    },
    abrirFactura(url) {
      window.open(url, '_blank');
    },
    imprimir() {
      this.$store.dispatch('pedidos/imprimirPedidoCaja', this.pedidoViendo);
    },
    async imprimirRestante() {
      let pedidoImprimir = await this.$store.dispatch(
        'pedidos/calcularTotalesProductosParametros',
        {
          ...this.pedidoViendo,
          productos: this.productosNoCobradosImprimir,
          restante: true,
        }
      );

      this.$store.dispatch('pedidos/imprimirPedidoCaja', pedidoImprimir);
    },
    dividirCuenta(fin) {
      var aux = [];
      this.cobradoProgramado = [];
      console.log(this.$store.getters['pedidos/PRODUCTOS_FINALES_ADAPTADOS_MENU']);
      console.log(this.productosNoCobrados);

      let productosDivididos = this.$store.getters['pedidos/PRODUCTOS_FINALES_ADAPTADOS_MENU']
        .filter((p) => p.cantidad > 0)
        .map((p) => {
          let productoAdd = {
            id: p.id,
            cantidad: p.cantidad,
            precioProductoTotalUnitario: !!p.precioProductoTotalUnitario
              ? p.precioProductoTotalUnitario
              : p.precio,
            //precioProductoTotal: p.precioProductoTotal, este valor es el precio de todos los productos, no de los que se están pagando, que se calcula multiplicando el unitario por la cantidad pagada
            nombre: p.name ? p.name : p.nombre,
            // ...(p.menuId
            //   ? {
            //     menuId: p.menuId,
            //     menuIdConcreto: p.menuIdConcreto,
            //     menuIdGrupo: p.menuIdGrupo,
            //     menuIdGrupoOrden: p.menuIdGrupoOrden,
            //     menuCopy: p.menuCopy
            //   }
            //   : {
            //     opciones: p.opciones,
            //     opcionesName: p.opcionesName,
            //     opcionesPrecio: p.opcionesPrecio,
            //   }),

            impuesto: p.impuesto,
            // impuestoCantidad: p.impuestoCantidad, este valor es el precio de todos los productos, no de los que se están pagando, que se calcula multiplicando el unitario por la cantidad pagada
          };
          return productoAdd;
        });
      this.cobradoProgramado = [];
      let sobrante = redondeo(
        this.pendienteCobrar - redondeo(this.pendienteCobrar / fin) * fin
      );
      for (var i = 0; i < fin; i++) {
        //aqui

        let cantidadPagarAux =
          redondeo(this.pendienteCobrar / fin) +
          (i + 1 == fin && Math.abs(sobrante) >= 0.01 ? sobrante : 0);
        //ajustamos la cantidad del último producto para que sume la cantidad concreta

        let productosDivididosPago = productosDivididos.map((p) => {
          let cantidadAux =
            i + 1 == fin
              ? redondeo(
                redondeo(p.cantidad / fin, 4) +
                (p.cantidad - redondeo(p.cantidad / fin, 4) * fin),
                4
              )
              : redondeo(p.cantidad / fin, 4);
          return {
            ...p,
            cantidad: cantidadAux,
          };
        });

        let sumaProductos = productosDivididosPago
          .map((prod) =>
            redondeo(prod.precioProductoTotalUnitario * prod.cantidad)
          )
          .reduce((accumulator, a) => accumulator + a, 0);
        aux.push({
          cantidadPagar: cantidadPagarAux,
          nombre:
            i +
            1 +
            ' -  ' +
            this.pendienteCobrarString +
            ' dividido entre ' +
            fin,
          pagado: false,
          seleccionadoPagar: false,
          productos: [
            ...productosDivididosPago,
            ...(Math.abs(redondeo(sumaProductos - cantidadPagarAux)) >= 0.01
              ? [
                {
                  //añadimos resto que falta para cuadra la cuenta
                  id: null,
                  cantidad: 1,
                  precioProductoTotalUnitario:
                    cantidadPagarAux - sumaProductos,
                  nombre: 'Ajuste de redondeo',
                  // sumaProductos +
                  // " " +
                  // cantidadPagarAux,
                  opciones: [],
                  opcionesName: [],
                  opcionesPrecio: [],
                  impuesto: productosDivididos[0].impuesto,
                  // impuestoCantidad: p.impuestoCantidad, este valor es el precio de todos los productos, no de los que se están pagando, que se calcula multiplicando el unitario por la cantidad pagada
                },
              ]
              : []),
          ],
          dividido: true,
        });
      }
      this.cobradoProgramado = aux;
      this.cantidadPagarModel = this.cobradoProgramado[0].cantidadPagar;
      this.cobradoProgramado[0].seleccionadoPagar = true;
    },
    onKeyPress(boton) {
      let productosDivididos;
      if (this.vaDividir && boton != '{bksp}') {
        this.inputKeyboard = '0';
        let fin = parseInt(boton);
        let aux = [];
        if (fin == 1) {
          this.pedidoViendo.cobradoProgramado = [];
          this.cantidadPagarModel = this.pendienteCobrar;
          this.vaDividir = false;
        } else {
          this.dividirCuenta(fin);
          this.vaDividir = false;
        }
      } else if (this.variasFormasPago) {
      } else {
        let arrayDivision;
        switch (boton) {
          case '{cobroPorProductos}':
            this.cobroPorProductos = true;
            break;
          case '{bksp}':
            if (this.vaDividir) this.vaDividir = false;
            //if (this.cobroPorProductos) this.cobroPorProductos = false;
            break;
          case 'C':
            this.inputKeyboard = '0';
            break;
          case '/':
            this.inputKeyboard = '';
            this.vaDividir = true;
            break;

          case '/2':
            this.dividirCuenta(2);
            break;
          case '/3':
            this.dividirCuenta(3);

            break;
        }
      }
    },
    onChange(input) {
      if (input.charAt(input.length - 1) == 'C') {
        this.inputKeyboard = '0';
      } else if (!this.vaDividir) {
        this.inputKeyboard = input;
      }
      if (isFloat(parseFloat(this.inputKeyboard))) this.inputKeyboard = '0';
      if (this.variasFormasPago) {
        switch (this.campoCambiar) {
          case 'entregado':
            this.entregadoModel = parseInt(this.inputKeyboard) / 100;
            break;
          case 'aPagar':
            let cant = parseInt(this.inputKeyboard) / 100;
            if (cant > this.variasFormasPagoAPagar.restante) {
              this.cantidadPagarModel = this.pendienteCobrar;
              this.variasFormasPagoAPagar.pagar =
                this.variasFormasPagoAPagar.restante;

              this.inputKeyboard = '0';
            } else {
              this.cantidadPagarModel = cant;
              this.variasFormasPagoAPagar.pagar = cant;
            }
            break;
        }
      } else {

        switch (this.campoCambiar) {

          case 'entregado':
            this.entregadoModel = parseInt(this.inputKeyboard) / 100;
            break;
          case 'descuentoCambiar':
            let cantDesc = parseInt(this.inputKeyboard)

            if (cantDesc > 100 || cantDesc < 0) {
              this.descuentoPorcentaje = 0;
              this.inputKeyboard = '0';
            } else {
              this.descuentoPorcentaje = cantDesc
            }
            break;
          case 'aPagar':
            let cant = parseInt(this.inputKeyboard) / 100;
            if (cant > this.pendienteCobrar) {
              this.cantidadPagarModel = this.pendienteCobrar;
              this.inputKeyboard = '0';
            } else {
              this.cantidadPagarModel = cant;
            }
            break;
        }
      }
    },
    seleccionCampoCambiar(s) {
      this.campoCambiar = s;
      this.inputKeyboard = '';
    },
    imprimirFacturaSimplificada(desglose) {
      console.log(desglose)
      this.$store.dispatch('pedidos/imprimirFacturaSimplificada', {
        pedido: { ...this.pedidoViendo },
        factSimplificada: desglose,
      });
    },
    async cobrarParcial() {
      this.cobrarLoading = true;
      this.desactivarDividir = true;
      this.$store.commit('pedidos/setPedidoEditandoObjValor', {
        key: 'historial',
        value: Array.isArray(this.pedidoViendo.historial)
          ? [
            ...this.pedidoViendo.historial,
            {
              fecha: moment().unix(),
              usuario: this.$store.state.usuarios.usuarioActual,
              mensaje:
                'Cobrado parcial desde TPV ' +
                this.cantidadPagarString +
                ' €',
            },
          ]
          : [
            {
              fecha: moment().unix(),
              usuario: this.$store.state.usuarios.usuarioActual,
              mensaje:
                'Cobrado parcial desde TPV ' +
                this.cantidadPagarString +
                ' €',
            },
          ],
      });
      var formaPagoCalculada =
        this.variasFormasPagoContenido.length > 0
          ? this.variasFormasPagoContenido.length == 1
            ? this.variasFormasPagoContenido[0].formaPago
            : this.variasFormasPagoContenido.map((f) => f.formaPago)
          : this.formaPago;
      this.$store.commit('pedidos/setPedidoEditandoObjValor', {
        key: 'forma_pago',
        value: formaPagoCalculada,
      });
      var cantidadPagar =
        this.variasFormasPagoContenido.length > 0
          ? parseFloat(this.variasFormasPagoContenido[0].total)
          : parseFloat(this.cantidadPagar);
      let cobradoAux = {
        formaPago: formaPagoCalculada,
        pagado: cantidadPagar,
      };
      if (this.variasFormasPagoContenido.length > 0) {
        cobradoAux.variasFormasPago = this.variasFormasPagoContenido;
        this.variasFormasPagoContenido = [];
      }
      if (this.formaPago == 'efectivo' && !this.cashguardBottom) {
        cobradoAux.entregado = parseFloat(this.entregadoModel);
        cobradoAux.cambio = parseFloat(this.cambio);
      } else if (this.formaPago == 'efectivo' && this.cashguardBottom) {
        cobradoAux.entregado =
          this.$store.getters[
          'cashguard/ficheroCashguardContenidoFicheroArrayUltimo'
          ][0];
        cobradoAux.cambio = Math.abs(
          this.$store.getters[
          'cashguard/ficheroCashguardContenidoFicheroArrayUltimo'
          ][2]
        );

        cobradoAux.cashguard = true;
      }

      let fI = this.cobradoProgramado.findIndex((c) => c.seleccionadoPagar);
      if (fI > -1) {
        cobradoAux.programado = this.cobradoProgramado[fI];
        cobradoAux.productos =
          cobradoAux.programado && cobradoAux.programado.productos
            ? cobradoAux.programado.productos
            : [];
      }

      //descuento
      if (this.pedidoViendo.descuentoPorcentaje) {
        cobradoAux.descuentoPorcentaje = this.pedidoViendo.descuentoPorcentaje
        cobradoAux.descuentoCantidad = cobradoAux.productos.reduce((acc, obj) => {
          return acc + obj.descuentoCantidadUnitario * obj.cantidad
        }, 0)
        cobradoAux.precioTotalPrevio = cobradoAux.productos.reduce((acc, obj) => {
          return acc + obj.precioProductoTotalUnitarioPrevio * obj.cantidad
        }, 0)
      }
      //base imponible e impuestos en el pago
      var baseImponiblePago = 0;
      var impuestosDesglosePago = [];
      var impuestosTotalesPago = 0;

      cobradoAux.productos = cobradoAux.productos.map((prd) => {
        let objDescuento = {
          precioProductoTotalUnitario: prd.precioProductoTotalUnitario
        };
        if (prd.descuentoPersonalizado) {
          // arrastramos el descuento al producto a la fact simplificada
          objDescuento.descuentoPersonalizado = !!prd.descuentoPersonalizado;
          objDescuento.descuentoPorcentaje = prd.descuentoPorcentaje;
          objDescuento.descuentoCantidadUnitario = prd.descuentoCantidadUnitario
          // objDescuento.descuentoCantidad = p.descuentoCantidad
          objDescuento.precioProductoTotalUnitarioPrevio =
            prd.precioProductoTotalUnitarioPrevio;
          // objDescuento.precioProductoTotalPrevio = precioProductoTotal;
          objDescuento.precioProductoTotalUnitario =
            prd.precioProductoTotalUnitario
          // objDescuento.precioProductoTotal =
          //   objDescuento.precioProductoTotalUnitario * p.cantidad;
        }
        let baseImponible = redondeo(
          (prd.precioProductoTotalUnitario * prd.cantidad) /
          (parseFloat(prd.impuesto) + 1)
        );
        let impuestosCantidad =
          prd.precioProductoTotalUnitario * prd.cantidad - baseImponible;
        baseImponiblePago += baseImponible;
        impuestosTotalesPago += impuestosCantidad;
        let fI = impuestosDesglosePago.findIndex(
          (i) => i.impuesto === prd.impuesto
        );
        if (fI > -1) {
          impuestosDesglosePago[fI].cantidad += impuestosCantidad;
        } else {
          impuestosDesglosePago.push({
            impuesto: prd.impuesto,
            cantidad: impuestosCantidad,
          });
        }

        return {
          ...prd,
          baseImponible: baseImponible,
          impuestoCantidad: impuestosCantidad,
          ...objDescuento
        };
      });
      cobradoAux.baseImponiblePago = redondeo(baseImponiblePago);
      cobradoAux.impuestosDesglosePago = impuestosDesglosePago;
      cobradoAux.impuestosTotalesPago = redondeo(impuestosTotalesPago);
      cobradoAux.moment = moment().unix();

      //crear contable
      let datosContable = {
        ...cobradoAux,
      };
      delete datosContable.programado;
      let codigoFactura = await this.$store.dispatch(
        'contable/crearApunteContable',
        {
          pedido: this.pedidoViendo.id,
          serie: 'tpv',
          ...datosContable,
        }
      );
      cobradoAux.codigoFactura = codigoFactura;
      cobradoAux.ficheroFactura = '';
      this.$store.commit('pedidos/setPedidoEditandoObjValor', {
        key: 'cobradoDesglose',
        value: Array.isArray(this.pedidoViendo.cobradoDesglose)
          ? [...this.pedidoViendo.cobradoDesglose, cobradoAux]
          : [cobradoAux],
      });

      this.cobradoProgramado.splice(fI, 1);
      // this.cobradoProgramado = [];
      this.reiniciarCobro();
      if (
        !!this.cobradoProgramado[fI + 1] &&
        !this.cobradoProgramado[fI + 1].pagado
      ) {
        this.seleccionCobradoProgramado(0);
      }

      if (this.cantidadPagarModel === 0) {
        this.formaPago = 'multiple';
        this.cobrar(false);
        this.reiniciarCobro();
        this.cobradoProgramado = [];
      }
      setTimeout(() => {
        if (this.$store.state.pedidos.imprimirDespuesCobrar)
          this.imprimirFacturaSimplificada(cobradoAux);
      }, 1500);
      this.cobrarLoading = false;
    },

    //cobrar
    async cobrar() {
      this.cobrarLoading = true;
      var formaPagoCalculada =
        this.variasFormasPagoContenido.length > 0
          ? this.variasFormasPagoContenido.length == 1
            ? this.variasFormasPagoContenido[0].formaPago
            : this.variasFormasPagoContenido.map((f) => f.formaPago)
          : this.formaPago;
      this.$store.commit('pedidos/setPedidoEditandoObjValor', {
        key: 'forma_pago',
        value: formaPagoCalculada,
      });
      this.$store.commit('pedidos/setPedidoEditandoObjValor', {
        key: 'cobrado',
        value: moment().unix(),
      });
      this.$store.commit('pedidos/setPedidoEditandoObjValor', {
        key: 'cobradoProgramado',
        value: [],
      });
      this.$store.commit('pedidos/setPedidoEditandoObjValor', {
        key: 'historial',
        value: Array.isArray(this.pedidoViendo.historial)
          ? [
            ...this.pedidoViendo.historial,
            {
              fecha: moment().unix(),
              usuario: this.$store.state.usuarios.usuarioActual,
              mensaje: 'Cobrado desde TPV',
            },
          ]
          : [
            {
              fecha: moment().unix(),
              usuario: this.$store.state.usuarios.usuarioActual,
              mensaje: 'Cobrado desde TPV',
            },
          ],
      });
      //no venimos de parcial
      var cantidadPagar =
        this.variasFormasPagoContenido.length > 0
          ? parseFloat(this.variasFormasPagoContenido[0].total)
          : parseFloat(this.cantidadPagar);
      let cobradoAux = {
        formaPago: formaPagoCalculada,
        pagado: cantidadPagar,
      };
      if (this.variasFormasPagoContenido.length > 0) {
        cobradoAux.variasFormasPago = this.variasFormasPagoContenido;
        this.variasFormasPagoContenido = [];
      }
      if (this.formaPago == 'efectivo' && !this.cashguardBottom) {
        cobradoAux.entregado = parseFloat(this.entregadoModel);
        cobradoAux.cambio = parseFloat(this.cambio);
      } else if (this.formaPago == 'efectivo' && this.cashguardBottom) {
        cobradoAux.entregado =
          this.$store.getters[
          'cashguard/ficheroCashguardContenidoFicheroArrayUltimo'
          ][0];
        cobradoAux.cambio = Math.abs(
          this.$store.getters[
          'cashguard/ficheroCashguardContenidoFicheroArrayUltimo'
          ][2]
        );

        cobradoAux.cashguard = true;
      }
      let prodFinales = await this.$store.dispatch(
        'pedidos/carritoArregladoMenu',
        { ...this.pedidoViendo, productos: this.productosNoCobrados }
      );



      cobradoAux.productos = prodFinales
        .filter((p) => p.cantidad != 0)
        .map((p) => {
          let objDescuento = {
            precioProductoTotalUnitario: p.precioProductoTotalUnitario
          };
          if (p.descuentoPersonalizado) {
            // arrastramos el descuento al producto a la fact simplificada
            objDescuento.descuentoPersonalizado = !!p.descuentoPersonalizado;
            objDescuento.descuentoPorcentaje = p.descuentoPorcentaje;
            objDescuento.descuentoCantidadUnitario = p.descuentoCantidadUnitario
            // objDescuento.descuentoCantidad = p.descuentoCantidad
            objDescuento.precioProductoTotalUnitarioPrevio =
              p.precioProductoTotalUnitarioPrevio;
            // objDescuento.precioProductoTotalPrevio = precioProductoTotal;
            objDescuento.precioProductoTotalUnitario =
              p.precioProductoTotalUnitario
            // objDescuento.precioProductoTotal =
            //   objDescuento.precioProductoTotalUnitario * p.cantidad;
          }
          return {
            id: p.id,
            cantidad: p.cantidad,
            precioProductoTotalUnitario: p.precioProductoTotalUnitario,
            precioProductoTotalUnitario: p.precioProductoTotalUnitario,
            //precioProductoTotal: p.precioProductoTotal,// este valor es el precio de todos los productos, no de los que se están pagando, que se calcula multiplicando el unitario por la cantidad pagada
            nombre: p.name ? p.name.es : p.nombre,

            ...(p.menuId
              ? {
                menuId: p.menuId,
                menuIdConcreto: p.menuIdConcreto,
                menuIdGrupo: p.menuIdGrupo,
                // menuIdGrupoOrden: p.menuIdGrupoOrden,
                // menuCopy: p.menuCopy
              }
              : {
                opciones: p.opciones,
                opcionesName: p.opcionesName,
                opcionesPrecio: p.opcionesPrecio,
              }),
            impuesto: p.impuesto,
            ...objDescuento
            // impuestoCantidad: p.impuestoCantidad, este valor es el precio de todos los productos, no de los que se están pagando, que se calcula multiplicando el unitario por la cantidad pagada
          };
        });

      //descuento
      if (this.pedidoViendo.descuentoPorcentaje) {
        cobradoAux.descuentoPorcentaje = this.pedidoViendo.descuentoPorcentaje
        cobradoAux.descuentoCantidad = cobradoAux.productos.reduce((acc, obj) => {
          return acc + obj.descuentoCantidadUnitario * obj.cantidad
        }, 0)
        cobradoAux.precioTotalPrevio = cobradoAux.productos.reduce((acc, obj) => {
          return acc + obj.precioProductoTotalUnitarioPrevio * obj.cantidad
        }, 0)
      }
      //base imponible e impuestos en el pago
      var baseImponiblePago = 0;
      var impuestosDesglosePago = [];
      var impuestosTotalesPago = 0;
      cobradoAux.productos = cobradoAux.productos.map((prd) => {
        console.log("prd");
        console.log(prd);
        let baseImponible = redondeo(
          (prd.precioProductoTotalUnitario * prd.cantidad) /
          (parseFloat(prd.impuesto) + 1)
        );
        let impuestosCantidad =
          prd.precioProductoTotalUnitario * prd.cantidad - baseImponible;
        baseImponiblePago += baseImponible;
        impuestosTotalesPago += impuestosCantidad;
        let fI = impuestosDesglosePago.findIndex(
          (i) => i.impuesto === prd.impuesto
        );
        if (fI > -1) {
          impuestosDesglosePago.cantidad += impuestosCantidad;
        } else {
          impuestosDesglosePago.push({
            impuesto: prd.impuesto,
            cantidad: impuestosCantidad,
          });
        }

        return {
          ...prd,
          baseImponible: baseImponible,
          impuestoCantidad: impuestosCantidad,
        };
      });
      cobradoAux.baseImponiblePago = baseImponiblePago;
      cobradoAux.impuestosDesglosePago = impuestosDesglosePago;
      cobradoAux.impuestosTotalesPago = impuestosTotalesPago;
      cobradoAux.moment = moment().unix();

      //crear contable

      let datosContable = {
        ...cobradoAux,
        //todos lso prroductos
      };
      delete datosContable.programado;
      let codigoFactura = await this.$store.dispatch(
        'contable/crearApunteContable',
        {
          pedido: this.pedidoViendo.id,
          serie: 'tpv',
          ...datosContable,
        }
      );

      cobradoAux.codigoFactura = codigoFactura;
      cobradoAux.ficheroFactura = '';

      this.$store.commit('pedidos/setPedidoEditandoObjValor', {
        key: 'cobradoDesglose',
        value: Array.isArray(this.pedidoViendo.cobradoDesglose)
          ? [...this.pedidoViendo.cobradoDesglose, cobradoAux]
          : [cobradoAux],
      });

      this.$store
        .dispatch('pedidos/savePedido', this.pedidoViendo)
        .then(() => {
          if (!!this.pedidoViendo.mesa && this.pedidoViendo.modo == 'mesa') {
            this.$store.dispatch(
              'pedidos/liberarMesa',
              this.pedidoViendo.mesa
            );
          }
          //this.$store.commit("pedidos/setPedidoDialog", false);
          //this.$store.dispatch("calcularCierre");


          if (this.$store.state.pedidos.imprimirDespuesCobrar)
            return this.$store.dispatch('pedidos/imprimirFacturaSimplificada', {
              pedido: this.pedidoViendo,
              factSimplificada: cobradoAux,
            });
        })
        .then(() => {
          if (
            this.formaPago == 'efectivo' ||
            (this.$store.getters['cuenta'] &&
              this.$store.getters['cuenta'].abreCajonSiempre &&
              !this.$store.getters['auth/isComandero'])
          ) {
            setTimeout(() => {
              this.$store.dispatch('abrirCajon');
            }, 1500);
          }
        })
        .then(() => {
          // this.cobrarDialog = false;
          this.$store.commit('pedidos/setPedidoDialog', false);

          if (
            !!this.$store.getters['cuenta'].cuentaAtrasCobrado &&
            !this.$store.getters['auth/isComandero']
          ) {
          } else {
            this.confirmarCerrar();
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => (this.cobrarLoading = false));
    },
    cerrarYAbrir() {
      const id = this.pedidoViendo.id;
      this.confirmarCerrar();
      setTimeout(() => {
        this.$store.commit('pedidos/setCobrarDialog', true);

        this.$store.dispatch('pedidos/setPedidoObj', id);
      }, 500);
    },
    confirmarCerrar() {
      if (!!this.cuentaAtrasSegundosInterval)
        clearInterval(this.cuentaAtrasSegundosInterval);
      this.cobrarDialog = false;
      this.cuentaAtrasCierre = false;
      this.$store.commit('pedidos/setPedidoDialog', false);
    },
    cerrarYAbrir() {
      const id = this.pedidoViendo.id;
      this.confirmarCerrar();
      setTimeout(() => {
        this.$store.commit('pedidos/setCobrarDialog', true);

        this.$store.dispatch('pedidos/setPedidoObj', id);
      }, 1500);
    },
    confirmarCerrar() {
      if (!!this.cuentaAtrasSegundosInterval)
        clearInterval(this.cuentaAtrasSegundosInterval);
      this.cobrarDialog = false;
      this.cuentaAtrasCierre = false;
      this.$store.commit('pedidos/setPedidoDialog', false);
      this.$store.commit('pedidos/setPedidoDialog', false);
    },
    crearAbonoConfirmar(desglose, i) {
      this.abonoLoading = true;
      return this.$store
        .dispatch('pedidos/crearAbono', {
          pedido: this.pedidoViendo,
          desglose: desglose,
        })
        .then((abono) => {
          //desglose.abonoContablePor = abono.codigoFactura;
          let cobradoDesgloseAux = [...this.pedidoViendo.cobradoDesglose];
          cobradoDesgloseAux[i].abonoContablePor =
            abono.cobradoDesglose[0].codigoFactura;

          return this.$store.dispatch('pedidos/savePedido', {
            id: this.pedidoViendo.id,
            abonoCreado: moment().unix(),
            cobradoDesglose: cobradoDesgloseAux,
            abonoPedidoQueAbona: abono.id,
            abonoPedidoQueAbonaCopy: {
              id: abono.id,
              numPedidoRestDia: abono.numPedidoRestDia,
              numPedidoRestDiaModo: abono.numPedidoRestDiaModo,
              numPedidoRestDiaModoMostrar: abono.numPedidoRestDiaModoMostrar,
              numPedidoRest: abono.numPedidoRest,
            },
          });
        })
        .then(() => {
          this.cobrarDialog = false;
        })
        .finally(() => (this.abonoLoading = false));
    },
    crearAbono(desglose, i) {
      this.$store.commit('pedidos/SET_DEVOLUCION_CONFIRMAR_DIALOG', true);
      this.$store.commit('pedidos/SET_DEVOLUCION_CONFIRMAR_OBJ', { desglose: JSON.parse(JSON.stringify(desglose)), pedidoObj: JSON.parse(JSON.stringify(this.pedidoViendo)), indice: i });
      return;
      if (this.puedeAbonarPedido) {
        this.$confirm(
          '¿Seguro que quieres crear un abono? Esta acción no se puede deshacer'
        ).then((r) => {
          if (r) {
            this.crearAbonoConfirmar(desglose, i);
          }
        });
      } else {
        this.$store.commit(
          'usuarios/setEleccionUsuarioAdminAccionesDialog',
          true
        );
        this.$store.commit('usuarios/setEleccionUsuarioAdminAccionesObj', {
          accion: 'abono-pedido',
          pedido: this.pedidoViendo.numPedidoRestDiaModoMostrar,
          precioTotal: desglose.pagado,
          aux: {
            i: i,
            desglose: desglose,
          },
        });
      }
      return;
    },
  },
  mounted() {



  },
};
</script>

<style lang="scss">
.overlay {
  z-index: 50;
  background: #000;
  /* for "disabled" effect */
  opacity: 1;
  pointer-events: none;
}

.cobrar--input {
  &.v-text-field--filled {
    >.v-input__control {
      >.v-input__slot {
        background-color: transparent;
      }
    }

    input {
      color: rgba(0, 0, 0, .6);
    }
  }

  input {
    text-align: center;
  }
}

button.v-btn {
  &.metodo-pago--btn {
    &.v-btn--active {
      background-color: #00f4d9 !important;

      &::before {
        opacity: 0;
      }
    }
  }
}

.pagado--expansion-panels {
  .v-expansion-panel-content__wrap {
    padding: 0 !important;
  }

  &.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)>.v-expansion-panel--active {
    border-radius: 8px !important;
  }
}

.border {
  border: 1px solid #000;
}
</style>
