import { estados } from '@/utils/estadosPedidos.utils.js';
export default {
  pedidosListener: null,
  pedidosRealizandoTpvListener: null,
  pedidosArray: [],
  pedidosRealizandoTpv: [],
  pedidosImprimir: [],
  pedidosImprimirGestionando: [], //si el pedido se está gestionando para imprimir
  pedidosDirectorioMatriz: [],
  estados: estados,
  formaPago: [
    {
      id: 'tarjeta_online',
      name: 'Tarjeta Online',
      modo: ['domicilio', 'recoger'],
    },
    {
      id: 'bizum',
      name: 'Bizum',
      modo: ['domicilio', 'recoger'],
    },
    {
      id: 'paypal',
      name: 'Paypal',
      modo: ['domicilio', 'recoger'],
    },
    {
      id: 'tarjeta_domicilio',
      name: 'Tarjeta a domicilio',
      modo: ['domicilio'],
    },
    {
      id: 'efectivo',
      name: 'Efectivo',
      modo: ['domicilio'],
    },
    {
      id: 'local',
      name: 'En local',
      modo: ['recoger'],
    },
    {
      id: 'tarjeta_local',
      name: 'Tarjeta en local',
      modo: [],
    },
    {
      id: 'otro',
      name: 'Otro',
      modo: [],
    },
  ],
  cambioDisponible: [
    {
      id: 10,
      name: '10 €',
    },
    {
      id: 20,
      name: '20 €',
    },
    {
      id: 40,
      name: '40 €',
    },
    {
      id: 50,
      name: '50 €',
    },
    {
      id: 100,
      name: '100 €',
    },
  ],
  estados: [
    { text: 'Finalizado', value: 'finalizado' },
    { text: 'En Curso', value: '' },
  ],
  modos: [
    { text: 'Recoger', value: 'recoger' },
    { text: 'Domicilio', value: 'domicilio' },
    { text: 'Plano', value: 'plano' },
    { text: 'Llevar', value: 'llevar' },
    // { text: "Barra", value: "barra" },
    // { text: "Mesa", value: "mesa" },
  ],
  eliminarMotivos: [
    {
      text: 'Solicitado por dueño negocio',
      value: 'solicitado-negocio',
      icon: 'mdi-store',
    },
    {
      text: 'Solicitado por el usuario',
      value: 'solicitado-usuario',
      icon: 'mdi-account',
    },
    {
      text: 'Fallo de la aplicación',
      value: 'fallo-aplicacion',
      icon: 'mdi-bug',
    },
  ],
  siguienteNumRestDia: null,
  siguienteNumRestDiaModo: {},
  siguienteNumRest: null,
  siguienteNumRestDiaListener: null,
  siguienteNumRestListener: null,
  abriendoNoGrabar: false,
  pedidoEditando: null, //id pedido editando
  pedidoEditandoObj: {}, //objeto pedido editando
  pedidoEditandoFlag: null, //para parar reactividad, saber si se ha grrabado
  cobrarDialog: false,
  cobrarDialogFullscreen: true,
  modoPantalla: 'seleccionModo', //seleccionModo, seleccionMesa, seleccionComensales, seleccionCliente, pedidoCarrito
  pedidoDialog: false,
  accionesDialog: false,
  facturacionDialog: false,
  facturacionContableFacturar: {},
  cambiandoCliente: false, //marca si estamos cambiando el cliente del pedido
  facturandoPedido: false,
  moverPedidoModoDialog: false,
  descuentosDialog: false,
  historialDialog: false,
  abonoDialog: false,
  grabandoPedido: false,

  editarProductoObj: null, //indica el producto que se está editando de carrito
  editarProductoMenuObj: null, //indica el producto que se está editando de carrito
  editarProductoMenuObjCambiandoOpciones: null,

  editarProductoDescuento: false,
  editarProductoDescuentoCantidad: 10, //porcentaje

  repartoEstadoItems: [
    {
      text: 'En espera',
      value: 'WAIT',
      glovo: false,
      msg: 'No se ha gestionado el envío',
    },
    {
      text: 'Zona reparto válida',
      value: 'CHECK_ZONE_OK',
      glovo: false,
      msg: 'El pedido se puede repartir en esa zona',
    },
    {
      text: 'Zona reparto no válida',
      value: 'CHECK_ZONE_ERROR',
      glovo: false,
      msg: 'El pedido NO se puede repartir en esa zona',
    },
    {
      text: 'Creado',
      value: 'CREATED',
      glovo: true,
      // msg: 'The order has been successfully created and has passed all validations',
      msg: 'El pedido fué correctamente creado',
    },
    {
      text: 'Rechazado',
      value: 'REJECTED',
      glovo: true,
      // msg: 'The order could not be created due to an unexpected issue, such as the system being fully saturated or a technical error',
      msg: 'El pedido no se ha podido crear por problemas técnicos',
    },
    {
      text: 'Programado',
      value: 'SCHEDULED',
      glovo: true,
      // msg: 'State after CREATED if the order has a pickup time scheduled. Waiting for activation time',
      msg: 'El pedido está programado para su hora de recogida',
    },
    {
      text: 'Activado y esperando repartidor',
      textShort: 'Esperando repartidor',
      value: 'ACTIVATED',
      glovo: true,
      // msg: 'After creation or reaching scheduled time, the order is activated and searching for a courier',
      msg: 'Después de la creación, el pedido está activo y se está buscando repartidor',
    },
    {
      text: 'Aceptado por repartidor',
      value: 'ACCEPTED',
      glovo: true,
      // msg: 'The order has been accepted by the courier and is being worked on',
      msg: 'El pedido ha sido aceptado por un repartidor y va de camino',
    },
    {
      text: 'El repartidor está en el lugar esperando para recoger',
      textShort: 'Repartidor en recogida',
      value: 'WAITING_FOR_PICKUP',
      glovo: true,
      msg: 'El repartidor ha entrado en la zona de recogida. < 100m',
    },
    {
      text: 'En reparto',
      value: 'PICKED',
      glovo: true,
      msg: 'El repartidor ha recogido el pedido y está en reparto',
    },
    {
      text: 'En el lugar de entrega',
      value: 'WAITING_FOR_DELIVERY',
      glovo: true,
      // msg: 'The courier has entered the delivery zone (distance threshold < 100m)',
      msg: 'El repartidor ha entrado en la zona de entrega. < 100m',
    },
    {
      text: 'Entregado',
      value: 'DELIVERED',
      glovo: true,
      // msg: 'The order has been successfully delivered',
      msg: 'El pedido ha sido correctamente entregado',
    },
    {
      text: 'Rechazado',
      value: 'CANCELLED',
      glovo: true,
      // msg: 'El pedido ha sido cancelado',
      msg: 'El pedido ha sido cancelado',
    },
    {
      text: 'Programado',
      value: 'NOT_DELIVERED_NOT_RETURNED',
      glovo: true,
      // msg: 'The courier was unable to deliver the order and the products are being returned to the pickup point. Orders enter this flow if arrangements for integration are to execute returns',
      msg: 'El repartidor no estaba disponible para repartir el pedido y los van de camino al lugar de origen. El pedido debe ser reembolsado',
    },
    {
      text: 'Programado',
      value: 'RETURNED',
      glovo: true,
      // msg: 'The courier has returned the products to the pickup point'
      msg: 'El repartidor ha devuelto los productos al punto de origen',
    },
  ],
  ordenCat: false, //ordenar carrito por categoría o tiempo
  pedidosPendienteImprimirListener: null,

  cobrarSinProductos: false,
  cobrarSinProductosObj: {
    id: null,
  },
  imprimirDespuesCobrar: true,
  mostrarTodasOpciones: false, //muestra todas las opciones del panel
  drawerComandero: false,
  impresionCocinaTotal: [],

  devolucionDialog: false,
  devolucionConfirmarDialog: false,
  devolucionConfirmarObj: null,

  buscarPedidoObj: null,
  buscarPedidoDialog: false,
};
