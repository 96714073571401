<template>
  <v-row
    no-gutters
    class=""
    style="max-height: 100%"
  >
    <v-col
      cols="12"
      md="8"
      lg="9"
      class="scroll-y--helper"
      style="max-height: 100%"
    >
      <v-overlay
        opacity="0.8"
        :value="editandoProductos && !editarProductoMenuObjCambiandoOpciones"
      >
      </v-overlay>

      <div :class="$vuetify.breakpoint.mdAndDown ? '' : ''">
        <producto-pedir />
      </div>
    </v-col>

    <template v-if="$vuetify.breakpoint.mdAndUp">
      <v-col
        cols="7"
        md="4"
        lg="3"
        class="pa-0"
        style="position: relative; z-index: 999"
      ><v-card
          height="100%"
          width="100%"
          class="mx-auto border"
        >
          <v-navigation-drawer
            absolute
            width="100%"
            permanent
            class="grey lighten-5 elevation-0"
          >
            <productos-carrito :productos="productosMostrar" />
            <template
              v-slot:append
              v-if="!editandoProductos"
            >
              <div class="pa-0">
                <v-btn
                  class="text-h6 black--text"
                  x-large
                  color="primary"
                  @click="$store.commit('pedidos/setCobrarDialog', true)"
                  block
                  :disabled="!pedidoEditandoObj.productos ||
                    pedidoEditandoObj.productos.length < 1 ||
                    !pedidoEditandoObj.id
                    "
                >
                  Cobrar
                  <v-spacer></v-spacer>
                  <span class="font-weight-black">{{ precioTotal }} €</span>
                </v-btn>
              </div>

              <v-row
                class=""
                no-gutters
                v-if="cuenta.glovoOnDemand && pedidoEditandoObj.modo == 'domicilio'"
              >
                <v-col
                  cols="12"
                  class="pr-0"
                >

                  <v-btn
                    v-if="pedidoEditandoObj.repartoEstado == 'CHECK_ZONE_OK' ||
                      pedidoEditandoObj.repartoEstado == 'EXECUTABLE'
                      "
                    class="text-h6 white--text"
                    :loading="loading"
                    x-large
                    color="success accent-2 "
                    @click="crearRepartoGlovo"
                    block
                    :disabled="!pedidoEditandoObj.productos ||
                      pedidoEditandoObj.productos.length < 1 ||
                      !pedidoEditandoObj.id
                      "
                  >
                    <v-icon class="mr-2">mdi-check</v-icon>CREAR REPARTO GLOVO
                  </v-btn>
                  <v-btn
                    v-else-if="pedidoEditandoObj.repartoEstado == 'CREATED'"
                    class="text-h6 black--text"
                    x-large
                    :href="pedidoEditandoObj.repartoTrackingEnlace"
                    target="_blank"
                    color="success accent-2 white--text"
                    :loading="loading"
                    block
                    :disabled="!pedidoEditandoObj.productos ||
                      pedidoEditandoObj.productos.length < 1 ||
                      !pedidoEditandoObj.id
                      "
                  >
                    <v-icon class="mr-2">mdi-info</v-icon>VER REPARTO GLOVO
                  </v-btn>
                  <v-btn
                    v-else-if="pedidoEditandoObj.repartoEstado == 'OUT_OF_WORKING_AREA_DELIVERY_ADDRESS' ||
                      pedidoEditandoObj.repartoEstado == 'RETURNED' ||
                      pedidoEditandoObj.repartoEstado == 'NOT_DELIVERED_NOT_RETURNED' ||
                      pedidoEditandoObj.repartoEstado == 'REJECTED' ||
                      pedidoEditandoObj.repartoEstado == 'CHECK_ZONE_OK'

                      "
                    :loading="loading"
                    class="text-h6 black--text"
                    x-large
                    color="cyan accent-2"
                    @click="consultarReparto()"
                    block
                  >
                    <v-icon class="mr-2">mdi-alert-circle</v-icon>VOLVER CONSULTAR GLOVO
                  </v-btn>
                  <v-btn
                    v-else
                    :loading="loading"
                    class="text-h6 black--text"
                    x-large
                    color="cyan accent-2"
                    @click="consultarReparto()"
                    block
                  >
                    <v-icon class="mr-2">mdi-bike</v-icon>CONSULTAR GLOVO
                  </v-btn>


                </v-col>
              </v-row>
              <v-row
                class=""
                no-gutters
                v-else-if="cuenta.cobrarDirecto"
              >
                <v-col
                  cols="6"
                  class="pr-0"
                >
                  <v-btn
                    class="text-h6 black--text"
                    x-large
                    color="cyan accent-2"
                    @click="cobrarDirecto('efectivo')"
                    block
                    :disabled="!pedidoEditandoObj.productos ||
                      pedidoEditandoObj.productos.length < 1 ||
                      !pedidoEditandoObj.id
                      "
                  >
                    <v-icon>mdi-hand-coin-outline</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn
                    class="text-h6 black--text"
                    x-large
                    color="orange accent-1"
                    @click="cobrarDirecto('tarjeta_local')"
                    block
                    :disabled="!pedidoEditandoObj.productos ||
                      pedidoEditandoObj.productos.length < 1 ||
                      !pedidoEditandoObj.id
                      "
                  >
                    <v-icon>mdi-credit-card</v-icon>
                  </v-btn>
                </v-col>
              </v-row>

              <div class="pa-0">
                <cobrar />
              </div>
            </template>
          </v-navigation-drawer>
        </v-card>
      </v-col>
    </template>
    <template v-else>
      <v-navigation-drawer
        fixed
        right
        top
        temporary
        class="grey lighten-5 elevation-0"
        width="100%"
        v-model="drawerComandero"
      >
        <v-btn
          v-if="!editandoProductos"
          fab
          :height="$vuetify.breakpoint.smAndDown ? 48 : 88"
          :width="$vuetify.breakpoint.smAndDown ? 48 : 88"
          color="primary"
          @click="
            !!$store.state.pedidos.editarProductoObj
              ? ($store.state.pedidos.editarProductoObj = false)
              : (drawerComandero = false)
            "
          absolute
          right
          class="mr-n2 mt-1"
        ><v-icon
            :size="$vuetify.breakpoint.smAndDown ? 36 : 54"
            color="black"
          >mdi-close</v-icon></v-btn>
        <v-list-item v-if="$vuetify.breakpoint.smAndDown">
          <v-list-item-content>
            <v-list-item-title class="text-sm-h4 text-h5 font-weight-black cyan--text text--lighten-2">
              PEDIDO
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <productos-carrito :productos="productosMostrar" />
        <template
          v-slot:append
          v-if="!$store.state.pedidos.editarProductoObj && !$store.state.pedidos.editarProductoMenuObj"
        >
          <div class="pa-0">
            <v-btn
              class="text-h6 black--text"
              x-large
              color="primary"
              @click="$store.commit('pedidos/setCobrarDialog', true)"
              block
              :disabled="!pedidoEditandoObj.productos ||
                pedidoEditandoObj.productos.length < 1
                "
            >
              Cobrar
              <v-spacer></v-spacer>
              <span class="font-weight-black">{{ precioTotal }} €</span>
            </v-btn>
          </div>
          <div class="pa-0">
            <cobrar />
          </div>
        </template>
      </v-navigation-drawer>
      <v-sheet
        class="pedido--bottom-sheet pa-3"
        width="100%"
        color="grey lighten-5"
      >
        <v-btn
          block
          large
          color="primary black--text"
          @click.stop="drawerComandero = true"
        >
          Ver pedido
        </v-btn>
      </v-sheet>
    </template>
  </v-row>
</template>

<script>
import { restSinNegocio } from '@/utils/restaurantes.utils.js';

import moment from "moment-timezone";
import { mapState } from "vuex";
import ProductoPedir from "../components/ProductoPedir.vue";
import ProductosCarrito from "../components/ProductosCarrito.vue";
import { mostrarMoneda, redondeo } from "@/utils/helper.utils.js";
import Cobrar from "./Cobrar.vue";
import ClienteDatos from "./ClienteDatos.vue";
import DireccionDatos from "./DireccionDatos.vue";
import firebase from "firebase";
import { mapGetters } from "vuex";



export default {
  components: {
    ProductoPedir,
    ProductosCarrito,
    Cobrar,
    ClienteDatos,
    DireccionDatos,
  },
  data() {
    return {
      creando: false,
      pedidoCopy: null,
      timeoutGrabar: [],
      loading: false,
      // drawerComandero: false,
      // abriendoNoGrabar: false, //centinela
    };
  },
  watch: {
    pedidoEditando(v) { },

    pedidoEditandoObj: {
      handler(nVal, oVal) {
        this.pedidoCopy = { ...nVal }; // para manejarlo antes de destruir,
        if (!!nVal.cobrado && !this.$store.state.pedidos.cobrarDialog) {
          this.$store.commit("pedidos/setPedidoDialog", false);
          // this.$store.commit("pedidos/setCobrarDialog", true);
        } else if (this.pedidoEditandoObj && this.pedidoEditandoObj.id) {
          if (this.timeoutGrabar.length > 0) {
            this.timeoutGrabar.map((t) => {
              clearTimeout(t);
            });

            this.timeoutGrabar = [];
          }

          this.timeoutGrabar.push(
            setTimeout(() => {
              if (!!this.pedidoEditandoObj && !!this.pedidoEditandoObj.id) {
                if (this.abriendoNoGrabar) {
                  this.abriendoNoGrabar = false;
                } else {
                  this.grabarPedido();
                }
              }
            }, 500)
          );
        } else if (
          !this.pedidoEditandoObj.id &&
          this.pedidoEditandoObj.productos &&
          this.pedidoEditandoObj.productos.length > 0 &&
          !this.creando &&
          this.pedidoEditandoObj.modo
        ) {
          // pedido nuevo
          this.creando = true;

          this.$store
            .dispatch("pedidos/addNuevoPedido", this.pedidoEditandoObj)
            .then((r) => {
              this.creando = false;

              this.$store.commit("pedidos/setPedidoEditando", r[0].id);
              this.$store.commit("pedidos/setPedidoEditandoObjValor", {
                key: "id",
                value: r[0].id,
              });
              this.$store.commit("pedidos/setPedidoEditandoObjValor", {
                key: "numPedidoRest",
                value: r[0].numPedidoRest,
              });
              this.$store.commit("pedidos/setPedidoEditandoObjValor", {
                key: "numPedidoRestDia",
                value: r[0].numPedidoRestDia,
              });
              this.$store.commit("pedidos/setPedidoEditandoObjValor", {
                key: "numPedidoRestDiaModo",
                value: r[0].numPedidoRestDiaModo,
              });
              this.$store.commit("pedidos/setPedidoEditandoObjValor", {
                key: "numPedidoRestDiaModoMostrar",
                value: r[0].numPedidoRestDiaModoMostrar,
              });
              this.grabarPedido();
            })
            .catch((e) => console.log(e));
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState(["configuracionTpv"]),
    editandoProductos() {
      return (
        !!this.$store.state.pedidos.editarProductoObj ||
        !!this.$store.state.pedidos.editarProductoMenuObj
      );
    },
    abriendoNoGrabar: {
      get() {
        return this.$store.state.pedidos.abriendoNoGrabar;
      },
      set(v) {
        this.$store.commit("pedidos/setAbriendoNoGrabar", v);
      },
    },
    drawerComandero: {
      get() {
        return this.$store.state.pedidos.drawerComandero;
      },
      set(v) {
        this.$store.commit("pedidos/SET_DRAWER_COMANDERO", v);
      },
    },
    productosMostrar() {
      let productosCobrados = (
        this.pedidoEditandoObj.cobradoDesglose
          ? this.pedidoEditandoObj.cobradoDesglose
            .map((c) => {
              return !!c.programado && !!c.programado.productos
                ? c.programado.productos
                : false;
            })
            .filter((p) => !!p)
            .flat()
          : []
      ).map((p) => {
        return {
          ...p,
          array2Sorted: p.opciones ? p.opciones.slice().sort() : [],
        };
      });

      return this.pedidoEditandoObj && this.pedidoEditandoObj.productos
        ? this.pedidoEditandoObj.productos
          .filter((p) => !p.cobradoPorProducto)
          .map((p) => {
            let coincideImpresion = this.pedidoEditandoObj.impresionCocina
              ? this.pedidoEditandoObj.impresionCocina.filter((e) => {
                return true;
                // return e.id === producto.id && (e.opciones).toString() === (opciones).toString();
                var array2Sorted = p.opciones.slice().sort();
                return (
                  e.id === p.id &&
                  e.opciones.length === p.opciones.length &&
                  e.opciones
                    .slice()
                    .sort()
                    .every(function (value, index) {
                      return (
                        array2Sorted &&
                        array2Sorted[index] &&
                        value === array2Sorted[index]
                      );
                    })
                );
              })
              : []; //filter coincide impresion
            let coincide = productosCobrados.filter((e) => {
              // return e.id === producto.id && (e.opciones).toString() === (opciones).toString();
              var array2Sorted = p.opciones.slice().sort();
              console.log(e);
              const opcionesComprobar = Array.isArray(e.opciones) ? e.opciones : []
              return (
                e.id === p.id &&

                opcionesComprobar.length === p.opciones.length &&
                opcionesComprobar
                  .slice()
                  .sort()
                  .every(function (value, index) {
                    return (
                      array2Sorted &&
                      array2Sorted[index] &&
                      value === array2Sorted[index]
                    );
                  })
              );
            }); //filter

            var cantidadRestar = 0;

            coincide.forEach((pCoincide) => {
              if (
                p.precioProductoTotalUnitario ===
                pCoincide.precioProductoTotalUnitario
              )
                cantidadRestar += pCoincide.cantidad;
            });

            if (coincide.length > 0) {
              return {
                ...p,
                cantidadCobrada: cantidadRestar,
                //cantidadImpresa: cantidadImpresa,
                precioProductoTotalCobrado:
                  cantidadRestar * p.precioProductoTotalUnitario,
              };
            } else {
              return p;
            }
          })
          .sort((a, b) => {
            return a.recargo
              ? -1
              : b.recargo
                ? 1
                : this.$store.state.pedidos.ordenCat
                  ? a.ordenCat < b.ordenCat
                    ? 1
                    : b.ordenCat < a.ordenCat
                      ? -1
                      : a.orden - b.orden
                  : 1;
          })
        : [];
    },
    ...mapGetters({
      cuenta: "cuenta",
    }),
    pedidoDialog() {
      return this.$store.state.pedidos.pedidoDialog;
    },
    modos() {
      return this.$store.state.pedidos.modos;
    },
    precioTotal() {
      return mostrarMoneda(this.pedidoEditandoObj.precioTotal, true);
    },
    subtotal() {
      return mostrarMoneda(
        this.pedidoEditandoObj.precioTotal -
        this.pedidoEditandoObj.impuestosTotal
      );
    },
    impuestos() {
      return mostrarMoneda(this.pedidoEditandoObj.impuestosTotal);
    },
    pedidoEditando() {
      return this.$store.state.pedidos.pedidoEditando;
    },
    pedidoEditandoObj: {
      get() {
        return this.$store.state.pedidos.pedidoEditandoObj;
      },
      set(v) {
        this.$store.commit("pedidos/setPedidoEditandoObj", v);
      },
    },
    editarProductoMenuObjCambiandoOpciones: {
      get() {
        return this.$store.state.pedidos.editarProductoMenuObjCambiandoOpciones;
      },
      set(v) {
        this.$store.commit(
          "pedidos/SET_EDITAR_PRODUCTO_MENU_OBJ_CAMBIANDO_OPCIONES",
          v
        );
      },
    },
    // pedidoDialog(){
    //     return this.$store.state.pedidos.pedidoDialog;
    // }
  },
  methods: {
    crearRepartoGlovo() {
      const app = firebase.app();
      // const functions = app.functions("europe-west1");
      const functions = app.functions();
      var p = functions.httpsCallable('httpPedidosCrearReparto');
      this.loading = true;
      return p({ pedido: this.pedidoEditandoObj.id })
        .then((r) => {

          this.pedidoEditandoObj.repartoEstado = r.data.data.data

        })
        .catch((e) => {
          return { status: 'fail', message: e };
        })
        .finally(() => this.loading = false)
    },
    consultarReparto() {
      const app = firebase.app();
      // const functions = app.functions("europe-west1");
      // firebase.functions().useFunctionsEmulator('http://localhost:5001');

      const functions = app.functions();
      var p = functions.httpsCallable('httpPedidosConsultarReparto');
      this.loading = true;
      return p({ pedido: this.pedidoEditandoObj })
        .then((r) => {
          this.pedidoEditandoObj.repartoEstado = r.data.data.data
        })
        .catch((e) => {
          return { status: 'fail', message: e };
        })
        .finally(() => this.loading = false)

    },
    async cobrarDirecto(formaPago) {
      var liberarMesa =
        !!this.pedidoEditandoObj.mesa && this.pedidoEditandoObj.modo == "mesa";
      var mesa = this.pedidoEditandoObj.mesa
        ? this.pedidoEditandoObj.mesa
        : false;

      this.$store.commit(
        "pedidos/SET_IMPRIMIR_DESPUES_COBRAR",
        !this.configuracionTpv.minimoImprimir ||
        parseFloat(this.pedidoEditandoObj.precioTotal) >
        parseFloat(this.configuracionTpv.minimoImprimir)
      );
      var pedidoAux = { ...this.pedidoEditandoObj };

      // this.$store.commit('pedidos/setPedidoEditandoObjValor', {
      //   key: 'forma_pago',
      //   value: formaPago,
      // });
      // this.$store.commit('pedidos/setPedidoEditandoObjValor', {
      //   key: 'cobrado',
      //   value: moment().unix(),
      // });
      // this.$store.commit('pedidos/setPedidoEditandoObjValor', {
      //   key: 'cobradoProgramado',
      //   value: [],
      // });
      // this.$store.commit('pedidos/setPedidoEditandoObjValor', {
      //   key: 'historial',
      //   value: Array.isArray(this.pedidoEditandoObj.historial)
      //     ? [
      //       ...this.pedidoEditandoObj.historial,
      //       {
      //         fecha: moment().unix(),
      //         usuario: this.$store.state.usuarios.usuarioActual,
      //         mensaje: 'Cobrado desde TPV',
      //       },
      //     ]
      //     : [
      //       {
      //         fecha: moment().unix(),
      //         usuario: this.$store.state.usuarios.usuarioActual,
      //         mensaje: 'Cobrado desde TPV',
      //       },
      //     ],
      // });
      var cantidadPagar = this.pedidoEditandoObj.precioTotal;
      let cobradoAux = {
        formaPago: formaPago,
        pagado: cantidadPagar,
      };
      let prodFinales = await this.$store.dispatch(
        "pedidos/carritoArregladoMenu",
        pedidoAux
      );
      console.log(prodFinales);

      cobradoAux.productos = prodFinales
        .filter((p) => p.cantidad != 0)
        .map((p) => {
          let objDescuento = {
            precioProductoTotalUnitario: p.precioProductoTotalUnitario
          };
          console.log(p);
          if (p.descuentoPersonalizado) {
            // arrastramos el descuento al producto a la fact simplificada
            objDescuento.descuentoPersonalizado = !!p.descuentoPersonalizado;
            objDescuento.descuentoPorcentaje = p.descuentoPorcentaje;
            objDescuento.descuentoCantidadUnitario = p.descuentoCantidadUnitario
            // objDescuento.descuentoCantidad = p.descuentoCantidad
            objDescuento.precioProductoTotalUnitarioPrevio =
              p.precioProductoTotalUnitarioPrevio;
            // objDescuento.precioProductoTotalPrevio = precioProductoTotal;
            objDescuento.precioProductoTotalUnitario =
              p.precioProductoTotalUnitario
            // objDescuento.precioProductoTotal =
            //   objDescuento.precioProductoTotalUnitario * p.cantidad;
          }
          return {
            id: p.id,
            cantidad: p.cantidad,
            precioProductoTotalUnitario: p.precioProductoTotalUnitario,
            //precioProductoTotal: p.precioProductoTotal,// este valor es el precio de todos los productos, no de los que se están pagando, que se calcula multiplicando el unitario por la cantidad pagada
            nombre: p.name ? p.name : p.nombre,

            ...(p.menuId
              ? {
                menuId: p.menuId,
                menuIdConcreto: p.menuIdConcreto,
                menuIdGrupo: p.menuIdGrupo,
                menuIdGrupoOrden: !!p.menuIdGrupoOrden ? p.menuIdGrupoOrden : 0,
                menuCopy: p.menuCopy
              }
              : {
                opciones: p.opciones,
                opcionesName: p.opcionesName,
                opcionesPrecio: p.opcionesPrecio,
              }),
            impuesto: p.impuesto,
            ...objDescuento
            // impuestoCantidad: p.impuestoCantidad, este valor es el precio de todos los productos, no de los que se están pagando, que se calcula multiplicando el unitario por la cantidad pagada
          };
        });

      //base imponible e impuestos en el pago
      var baseImponiblePago = 0;
      var impuestosDesglosePago = [];
      var impuestosTotalesPago = 0;
      cobradoAux.productos = cobradoAux.productos.map((prd) => {
        let baseImponible = redondeo(
          (prd.precioProductoTotalUnitario * prd.cantidad) /
          (parseFloat(prd.impuesto) + 1)
        );
        let impuestosCantidad =
          prd.precioProductoTotalUnitario * prd.cantidad - baseImponible;
        baseImponiblePago += baseImponible;
        impuestosTotalesPago += impuestosCantidad;
        let fI = impuestosDesglosePago.findIndex(
          (i) => i.impuesto === prd.impuesto
        );
        if (fI > -1) {
          impuestosDesglosePago.cantidad += impuestosCantidad;
        } else {
          impuestosDesglosePago.push({
            impuesto: prd.impuesto,
            cantidad: impuestosCantidad,
          });
        }

        return {
          ...prd,
          baseImponible: baseImponible,
          impuestoCantidad: impuestosCantidad,
        };
      });
      cobradoAux.baseImponiblePago = baseImponiblePago;
      cobradoAux.impuestosDesglosePago = impuestosDesglosePago;
      cobradoAux.impuestosTotalesPago = impuestosTotalesPago;
      cobradoAux.moment = moment().unix();

      //crear contable

      let datosContable = {
        ...cobradoAux,
        //todos lso prroductos
      };
      console.log(datosContable);

      delete datosContable.programado;
      let codigoFactura = await this.$store.dispatch(
        "contable/crearApunteContable",
        {
          pedido: this.pedidoEditandoObj.id,
          serie: "tpv",
          ...datosContable,
        }
      );

      cobradoAux.codigoFactura = codigoFactura;
      cobradoAux.ficheroFactura = "";

      pedidoAux = {
        ...pedidoAux,
        forma_pago: formaPago,
        cobrado: moment().unix(),
        cobradoProgramado: [],
        historial: Array.isArray(this.pedidoEditandoObj.historial)
          ? [
            ...this.pedidoEditandoObj.historial,
            {
              fecha: moment().unix(),
              usuario: this.$store.state.usuarios.usuarioActual,
              mensaje: "Cobrado desde TPV",
            },
          ]
          : [
            {
              fecha: moment().unix(),
              usuario: this.$store.state.usuarios.usuarioActual,
              mensaje: "Cobrado desde TPV",
            },
          ],
        cantidadPagar: this.pedidoEditandoObj.precioTotal,
        cobradoAux: {
          formaPago: formaPago,
          pagado: cantidadPagar,
        },
        cobradoDesglose: Array.isArray(this.pedidoEditandoObj.cobradoDesglose)
          ? [...this.pedidoEditandoObj.cobradoDesglose, cobradoAux]
          : [cobradoAux],
      };

      // this.$store.commit('pedidos/setPedidoEditandoObjValor', {
      //   key: 'cobradoDesglose',
      //   value: Array.isArray(this.pedidoEditandoObj.cobradoDesglose)
      //     ? [...this.pedidoEditandoObj.cobradoDesglose, cobradoAux]
      //     : [cobradoAux],
      // });

      this.$store
        .dispatch("pedidos/savePedido", pedidoAux)
        .then(() => {
          if (liberarMesa) {
            this.$store.dispatch("pedidos/liberarMesa", mesa);
          }
          //this.$store.commit("pedidos/setPedidoDialog", false);
          //this.$store.dispatch("calcularCierre");

          if (this.$store.state.pedidos.imprimirDespuesCobrar)
            return this.$store.dispatch("pedidos/imprimirFacturaSimplificada", {
              pedido: pedidoAux,
              factSimplificada: cobradoAux,
            });
        })
        .then(() => {
          if (
            formaPago == "efectivo" ||
            (this.$store.getters["cuenta"] &&
              this.$store.getters["cuenta"].abreCajonSiempre &&
              !this.$store.getters["auth/isComandero"])
          ) {
            setTimeout(() => {
              this.$store.dispatch("abrirCajon");
            }, 1500);
          }
        })
        .then(() => {
          // this.cobrarDialog = false;
          this.$store.commit("pedidos/setPedidoDialog", false);
        });
    },
    cambiarCliente() {
      this.$store.commit("pedidos/setModoPantalla", "seleccionCliente");
    },
    grabarPedido() {
      this.$store.dispatch("pedidos/savePedido", this.pedidoEditandoObj);
      // .finally(() => ());
    },
    goSeleccionarCliente() {
      this.cliente.dialog = true;
    },
    nuevoPedido() {
      this.$store.commit("pedidos/setPedidoEditandoObj", {
        ...this.pedidoEditandoObj,
        canalVenta: "tpv",
      });
      this.pedidoEditandoObj = {
        ...this.pedidoEditandoObj,
        modo:
          this.pedidoEditandoObj && this.pedidoEditandoObj.modo
            ? this.pedidoEditandoObj.modo
            : null,
        id: null,
        cuentaTpv: this.$store.state.auth.user.email,
        copyCuentaTpv: {
          email: this.$store.getters["cuenta"].email,
          nombre: this.$store.getters["cuenta"].nombre,
        },
        fechaInicio: new Date(),
        fechaServidor: firebase.firestore.Timestamp.now(),
        zcierre: this.$store.state.zcierre,
        xcierre: this.$store.state.xcierre,
        usuarioTpv: this.$store.state.usuarios.usuarioActual,
        comentarioPedidoTpv: "",
        nombrePersonalizadoTpv: "",
        cobradoDesglose: [],
        cobradoProgramado: [],
        copyUsuarioTpv: {
          nombre: this.$store.state.usuarios.usuarioActualObj.nombre,
        },
        historial: [
          {
            fecha: moment().unix(),
            mensaje: "comienzo pedido",
            usuario: this.$store.state.usuarios.usuarioActual,
          },
        ],
        estado: "tpv-realizando",
        canalVenta: "tpv",
        precioTotal: 0,
        impuestosTotal: 0,
        numPedidoRest: null,
        numPedidoRestDia: null,
        numPedidoRestDiaModo: null,
        negocio: this.$store.state.restauranteObj.negocio,
        // restaurante: this.$store.state.restauranteObj.id,
        restaurante: restSinNegocio(this.$store.state.restauranteObj),
        copyRest: {
          nombre: this.$store.state.restauranteObj.nombre
            ? this.$store.state.restauranteObj.nombre
            : "",
          cif: this.$store.state.restauranteObj.cif
            ? this.$store.state.restauranteObj.cif
            : "",
          telefono: this.$store.state.restauranteObj.telefono
            ? this.$store.state.restauranteObj.telefono
            : "",
          direccion: this.$store.state.restauranteObj.direccion
            ? this.$store.state.restauranteObj.direccion
            : "",
          tiempoDomicilio:
            this.$store.state.restauranteObj &&
              this.$store.state.restauranteObj.tiempoDomicilio
              ? this.$store.state.restauranteObj.tiempoDomicilio
              : null,
          tiempoRecogida:
            this.$store.state.restauranteObj &&
              this.$store.state.restauranteObj.tiempoRecogida
              ? this.$store.state.restauranteObj.tiempoRecogida
              : null,
          confGeneral: {
            zonaHoraria:
              this.$store.state.restauranteObj.confGeneral &&
                this.$store.state.restauranteObj.confGeneral.zonaHoraria
                ? this.$store.state.restauranteObj.confGeneral.zonaHoraria
                : null,
          },
        },
        copyNegocio: {
          nombre: this.$store.state.negocioObj.nombre,
        },
        productos: [],
        menus: [],
      };
      if (!!this.pedidoEditandoObj.direccion) {
        this.pedidoEditandoObj.direccion = this.pedidoEditandoObj.direccion;
      }
      this.$store.commit(
        "pedidos/setPedidoEditandoObj",
        this.pedidoEditandoObj
      );
      this.$store.commit(
        "pedidos/setPedidoEditando",
        this.pedidoEditandoObj.id
      );
    },
  },
  beforeDestroy() {
    if (
      this.pedidoCopy &&
      this.pedidoCopy.id &&
      this.cuenta &&
      this.cuenta.imprimirCocinaAuto
    ) {
      this.$store.commit("impresion/setPedidoOcupado", this.pedidoCopy.id);
      this.$store
        .dispatch("pedidos/imprimirPedidoCocina", {
          pedido: this.pedidoCopy,
          impresionCocina: this.pedidoCopy.impresionCocina
            ? JSON.parse(JSON.stringify(this.pedidoCopy.impresionCocina))
            : [],
          filtrarImpresos: true,
        })
        .finally(() => {
          this.$store.commit("impresion/setPedidoOcupado", null);
        });
    }
    this.$store.commit("setMostrarFabAdd", true);
    //if (!!this.timeoutGrabar) clearTimeout(this.timeoutGrabar);
    //return this.pedido && this.pedido.id ? this.grabarPedido() : true;
  },

  mounted() {
    this.$store.commit("setMostrarFabAdd", false);
    // let idpedido = this.$route.params.idpedido;
    let idpedido = this.$store.state.pedidos.pedidoEditando;

    if (idpedido) {
      this.$store.dispatch("pedidos/setPedidoObj", idpedido).then(() => {
        this.abriendoNoGrabar = true;
        // this.pedidoEditandoObj = this.$store.state.pedidos.pedidoEditandoObj;
      });
      // this.pedido = this.$store.getters["pedidos/pedidoEditandoObj"];
    } else {
      //nuevo
      this.nuevoPedido();
    }
  },
};
</script>

<style lang="scss">
.overlay {
  z-index: 50;
  background: #000;
  /* for "disabled" effect */
  opacity: 1;
  pointer-events: none;
}

.scroll-y--helper {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 100%;
}

.border {
  border: 1px solid #000;
}

.pedido--bottom-sheet {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}
</style>
