const QZ_CORTE_OLD = '\x1B' + '\x69';
const QZ_CORTE = '\x1d' + '\x56' + '\x31';
export const comandosF = function (ticket) {
  let offset = isNaN(parseInt(ticket.offset)) ? 0 : parseInt(ticket.offset);
  return {
    QZ_INIT: '\x1B' + '\x40',
    QZ_CORTE_OLD: QZ_CORTE_OLD,
    QZ_CORTE: '\x1d' + '\x56' + '\x31',
    QZ_ABRIR_CAJON: '\x1b' + '\x70' + '\0x00' + '\x19' + '\xfa',
    QZ_ALIGN_CENTER: '\x1B' + '\x61' + '\x31', // center align
    QZ_BOLD: '\x1B' + '\x45' + '\x0D',
    QZ_LINEA: '\x0a',
    QZ_FUENTE_MEDIANA: '\x1d' + '\x21' + '\x10',
    QZ_FUENTE_GRANDE: '\x1d' + '\x21' + '\x11',
    QZ_FUENTE_NORMAL: '\x1d' + '\x21' + '\x00',
    QZ_BOLD_NORMAL: '\x1b' + '\x21' + '\x00',
    QZ_FUENTE_MAS_GRANDE: '\x1d' + '\x21' + '\x22',
    QZ_FUENTE_ENORME: '\x1d' + '\x21' + '\x33',
    QZ_ALIGN_LEFT: '\x1b' + '\x61' + '\x00',
    QZ_ALIGN_RIGHT: '\x1b' + '\x61' + '\x02',
    QZ_INVERTIR: '\x1d' + '\x42' + '\x01',
    QZ_STOP_INVERTIR: '\x1d' + '\x42' + '\x00',
    QZ_FONT_HACHATADA: '\x1b' + '\x21' + '\x34',
    QZ_ESPACIO_ENTRE_CARACTER: '\x1b' + '\x20' + '\x02',
    QZ_LINEA_HORIZONTAL:
      '-'.padStart((ticket.size == '58' ? 32 : 48) + offset, '-') + '\x0A', // Para 80mm - 48  y para 58mm - 32
    QZ_CORTE:
      ticket.corte == '69'
        ? QZ_CORTE_OLD
        : ticket.corte == 'no-cortar'
        ? ''
        : QZ_CORTE,
  };
};

export const valoresTicketDinamico = (ticket) => {
  let offset = isNaN(parseInt(ticket.offset)) ? 0 : parseInt(ticket.offset);
  return {
    substring: (ticket.size == '58' ? 16 : 26) + offset, //por defecto 80
    substringCocina: (ticket.size == '58' ? 12 : 20) + offset, //por defecto 80
    zCierreConcepto: (ticket.size == '58' ? 27 : 43) + offset, //por defecto 80
    zCierreListadoTicket5: (ticket.size == '58' ? 5 : 10) + offset, //por defecto 80
    zCierreListadoTicket4: (ticket.size == '58' ? 8 : 12) + offset, //por defecto 80
    zCierreListadoTicket7: (ticket.size == '58' ? 11 : 18) + offset, //por defecto 80
    zCierreMultiPagoTarjetaLocal: (ticket.size == '58' ? 12 : 19) + offset, //por defecto 80
    finLinea: (ticket.size == '58' ? 18 : 34) + offset, //por defecto 80
    resumenTicket: (ticket.size == '58' ? 3 : 5) + offset, //por defecto 80
    resumenFecha: (ticket.size == '58' ? 7 : 13) + offset, //por defecto 80
    resumenTotales: (ticket.size == '58' ? 7 : 10) + offset, //por defecto 80
  };
};
