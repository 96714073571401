<template>
  <v-row>
    <v-col
      cols="6"
      md="3"
      @click="$store.commit('setAbrirCalendario', true)"
    >
      <v-hover v-slot:default="{ hover }">
        <v-card
          elevation="4"
          outlined
          class="rounded-lg"
          :style="hover ? 'cursor: pointer' : ''"
          :color="hover ? 'grey lighten-4' : 'white'"
        >
          <!-- <v-badge
                :content="$store.getters['calendario/numeroReservasNoConfirmadas'].toString()"
              >
              </v-badge> -->

          <v-responsive
            aspect-ratio="1.618"
            class="align-center"
          >
            <v-list-item-content>
              <div class="text-center pb-6">
                <!--                 <v-btn absolute outlined dark x-small fab right color="pink">
                  <h3>
                    {{
                      $store.getters[
                        "calendario/numeroReservasNoConfirmadas"
                      ].toString()
                    }}
                  </h3>
                </v-btn> -->
                <v-icon
                  class="my-4"
                  color="teal darken-4"
                  :size="$vuetify.breakpoint.mdAndDown ? '35' : '45'"
                >mdi-calendar</v-icon>
                <div
                  class="custom-badge calendario-badge primary elevation-3 d-flex align-center justify-center font-weight-bold"
                >
                  {{
                    $store.getters[
                      'calendario/numeroReservasNoConfirmadas'
                    ].toString()
                  }}
                </div>
              </div>
            </v-list-item-content>
            <v-card-actions class="grey lighten-4 acciones-footer acciones--border-top">
              <span class="text-uppercase mx-auto font-weight-bold teal--text text--darken-4 subtitle-2">Reservas</span>
            </v-card-actions>
          </v-responsive>
        </v-card>
      </v-hover>
    </v-col>
    <v-col
      cols="6"
      md="3"
      @click="$router.push('/comanderos')"
    >
      <v-hover v-slot:default="{ hover }">
        <v-card
          elevation="4"
          outlined
          class="rounded-lg"
          :style="hover ? 'cursor: pointer' : ''"
          :color="hover ? 'grey lighten-4' : 'white'"
        >
          <v-responsive
            aspect-ratio="1.618"
            class="align-center"
          >
            <v-list-item-content>
              <div class="text-center pb-6">
                <v-icon
                  class="my-4"
                  color="teal darken-4"
                  :size="$vuetify.breakpoint.mdAndDown ? '35' : '45'"
                >mdi-cellphone-text</v-icon>
              </div>
            </v-list-item-content>
            <v-card-actions class="grey lighten-4 acciones-footer acciones--border-top">
              <span class="text-uppercase mx-auto font-weight-bold teal--text text--darken-4 subtitle-2">Comanderos</span>
            </v-card-actions>
          </v-responsive>
        </v-card>
      </v-hover>
    </v-col>
    <!--     <v-col cols="6" md="3" @click="$router.push('/clientes')">
      <v-hover v-slot:default="{ hover }">
        <v-card
          elevation="4"
          outlined
          class="rounded-lg"
          :style="hover ? 'cursor: pointer' : ''"
          :color="hover ? 'grey lighten-4' : 'white'"
        >
          <v-responsive aspect-ratio="1.618" class="align-center">
            <v-list-item-content>
              <div class="text-center pb-6">
                <!-- <v-btn absolute outlined dark x-small fab right color="pink">
                  <h3>{{ $store.getters["pedidos/numPedidos"].toString() }}</h3>
                </v-btn> -->

    <!--   <v-icon
                  class="my-4"
                  color="teal darken-4"
                  :size="$vuetify.breakpoint.mdAndDown ? '35' : '45'"
                  >mdi-account</v-icon
                >
              </div>
            </v-list-item-content>
            <v-card-actions
              class="grey lighten-4 acciones-footer acciones--border-top"
            >
              <span
                class="
                  text-uppercase
                  mx-auto
                  font-weight-bold
                  teal--text
                  text--darken-4
                  subtitle-2
                "
                >Clientes</span
              >
            </v-card-actions>
          </v-responsive>
        </v-card>
      </v-hover>
    </v-col> -->
    <v-col
      cols="6"
      md="3"
      class
      v-if="false"
      @click="$store.commit('productos/SET_PRODUCTOS_DIALOG', true)"
    >
      <v-hover v-slot:default="{ hover }">
        <v-card
          disabled
          elevation="4"
          outlined
          class="rounded-lg"
          :style="hover ? 'cursor: pointer' : ''"
          :color="hover ? 'grey lighten-4' : 'white'"
        >
          <v-responsive
            aspect-ratio="1.618"
            class="align-center"
          >
            <v-list-item-content>
              <div class="text-center pb-6">
                <v-icon
                  class="my-4"
                  color="teal darken-4"
                  :size="$vuetify.breakpoint.mdAndDown ? '35' : '45'"
                >mdi-food</v-icon>
              </div>
            </v-list-item-content>
            <v-card-actions class="grey lighten-4 acciones-footer acciones--border-top">
              <span class="text-uppercase mx-auto font-weight-bold teal--text text--darken-4 subtitle-2">Productos</span>
            </v-card-actions>
          </v-responsive>
        </v-card>
      </v-hover>
    </v-col>
    <v-col
      cols="6"
      md="3"
      class
      @click="
                    /*
                    $router.push(
                      '/pedidos-internos'
                    ) */ $store.commit('internos/SET_INTERNOS_DIALOG', true)
        "
    >
      <v-hover v-slot:default="{ hover }">
        <v-card
          elevation="4"
          outlined
          class="rounded-lg"
          :style="hover ? 'cursor: pointer' : ''"
          :color="hover ? 'grey lighten-4' : 'white'"
        >
          <v-responsive
            aspect-ratio="1.618"
            class="align-center"
          >
            <v-list-item-content>
              <div class="text-center pb-6">
                <v-icon
                  class="my-4"
                  color="teal darken-4"
                  :size="$vuetify.breakpoint.mdAndDown ? '35' : '45'"
                >mdi-cart-outline</v-icon>
              </div>
            </v-list-item-content>
            <v-card-actions class="grey lighten-4 acciones-footer acciones--border-top">
              <span class="text-uppercase mx-auto font-weight-bold teal--text text--darken-4 subtitle-2">Pedidos
                Internos</span>
            </v-card-actions>
          </v-responsive>
        </v-card>
      </v-hover>
    </v-col>
    <v-col
      cols="6"
      md="3"
      class
      @click="$router.push('/notificaciones')"
    >
      <v-hover v-slot:default="{ hover }">
        <v-card
          elevation="4"
          outlined
          class="rounded-lg"
          :style="hover ? 'cursor: pointer' : ''"
          :color="hover ? 'grey lighten-4' : 'white'"
        >
          <v-responsive
            aspect-ratio="1.618"
            class="align-center"
          >
            <v-list-item-content>
              <div class="text-center pb-6">
                <v-icon
                  class="my-4"
                  color="teal darken-4"
                  :size="$vuetify.breakpoint.mdAndDown ? '35' : '45'"
                >mdi-bullhorn-outline</v-icon>
              </div>
            </v-list-item-content>
            <v-card-actions class="grey lighten-4 acciones-footer acciones--border-top">
              <span
                class="text-uppercase mx-auto font-weight-bold teal--text text--darken-4 subtitle-2">Notificaciones</span>
            </v-card-actions>
          </v-responsive>
        </v-card>
      </v-hover>
    </v-col>
    <!-- <v-col cols="6" md="3" class @click="$router.push('/configuracion')">
      <v-hover v-slot:default="{ hover }">
        <v-card
          elevation="4"
          outlined
          class="rounded-lg"
          :style="hover ? 'cursor: pointer' : ''"
          :color="hover ? 'grey lighten-4' : 'white'"
        >
          <v-responsive aspect-ratio="1.618" class="align-center">
            <v-list-item-content>
              <div class="text-center pb-6">
                <v-icon
                  class="my-4"
                  color="teal darken-4"
                  :size="$vuetify.breakpoint.mdAndDown ? '35' : '45'"
                  >mdi-cog</v-icon
                >
              </div>
            </v-list-item-content>
            <v-card-actions
              class="grey lighten-4 acciones-footer acciones--border-top"
            >
              <span
                class="
                  text-uppercase
                  mx-auto
                  font-weight-bold
                  teal--text
                  text--darken-4
                  subtitle-2
                "
                >Configuración</span
              >
            </v-card-actions>
          </v-responsive>
        </v-card>
      </v-hover>
    </v-col> -->
    <!--     <v-col cols="6" md="3" @click="$router.push('/configuracion/dispositivos')">
      <v-hover v-slot:default="{ hover }">
        <v-card
          elevation="4"
          outlined
          class="rounded-lg"
          :style="hover ? 'cursor: pointer' : ''"
          :color="hover ? 'grey lighten-4' : 'white'"
        >
          <v-responsive aspect-ratio="1.618" class="align-center">
            <v-list-item-content>
              <div class="text-center pb-6">
                <!-- <v-btn absolute outlined dark x-small fab right color="pink">
                <h3>{{ $store.getters["pedidos/numPedidos"].toString() }}</h3>
              </v-btn> -->
    <!--
                <v-icon
                  class="my-4"
                  color="teal darken-4"
                  :size="$vuetify.breakpoint.mdAndDown ? '35' : '45'"
                  >mdi-printer</v-icon
                >
              </div>
            </v-list-item-content>
            <v-card-actions
              class="grey lighten-4 acciones-footer acciones--border-top"
            >
              <span
                class="
                  text-uppercase
                  mx-auto
                  font-weight-bold
                  teal--text
                  text--darken-4
                  subtitle-2
                "
                >Dispositivos</span
              >
            </v-card-actions>
          </v-responsive>
        </v-card>
      </v-hover>
    </v-col> -->
    <v-col
      cols="6"
      md="3"
    >
      <v-hover v-slot:default="{ hover }">
        <v-card
          elevation="4"
          outlined
          class="rounded-lg"
          :style="hover ? 'cursor: pointer' : ''"
          :color="hover ? 'red darken-2' : 'red'"
          @click="logout"
        >
          <v-responsive
            aspect-ratio="1.618"
            class="align-center"
          >
            <v-list-item-content>
              <div class="text-center pb-6">
                <!-- <v-btn absolute outlined dark x-small fab right color="pink">
                  <h3>{{ $store.getters["pedidos/numPedidos"].toString() }}</h3>
                </v-btn> -->

                <v-icon
                  class="my-4"
                  color="white"
                  :size="$vuetify.breakpoint.mdAndDown ? '35' : '45'"
                >mdi-power</v-icon>
              </div>
            </v-list-item-content>
            <v-card-actions class="red darken-2 acciones-footer acciones--border-top">
              <span class="text-uppercase mx-auto font-weight-bold white--text subtitle-2">Desconectar</span>
            </v-card-actions>
          </v-responsive>
        </v-card>
      </v-hover>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapState({
      isLoaded: (state) => state.loaded,
    }),
  },
  methods: {
    // ...mapActions({
    // }),
    cargaInicial() {
      //this.$store.dispatch("inicioTpv");
    },
    btnCuadrado(e) {
      let res =
        document.getElementsByClassName('mainBtn')[e] == undefined
          ? 200
          : document.getElementsByClassName('mainBtn')[e]['clientWidth'];
      return res;
    },
    ...mapActions({
      logout: 'auth/firebaseLogout',
    }),
  },
  mounted() {
    this.$store.commit('setTabMenu');
  },
};
</script>
<style lang="scss">
.v-card__actions.acciones-footer.acciones--border-top {
  border-top: 1px solid rgba(0, 0, 0, .12) !important;
}

.border {
  border: 1px solid #000;
}

.borderB {
  border: 1px solid #fff;
}

$medidaBadge: 30px;

.custom-badge {
  position: absolute;
  min-width: $medidaBadge;
  min-height: $medidaBadge - 10;
  padding: 0 8px;
  color: #004d40;
  /* teal darken-4 */
  border-radius: $medidaBadge/2 !important;

  &.calendario-badge {
    top: 2px;
    right: 4px;
  }
}
</style>
