import moment from 'moment';
import store from '@/store';

export const diasSemana = [
  {
    value: 'lunes',
    text: 'Lunes',
  },
  {
    value: 'martes',
    text: 'Martes',
  },
  {
    value: 'miercoles',
    text: 'Miércoles',
  },
  {
    value: 'jueves',
    text: 'Jueves',
  },
  {
    value: 'viernes',
    text: 'Viernes',
  },
  {
    value: 'sabado',
    text: 'Sábado',
  },
  {
    value: 'domingo',
    text: 'Domingo',
  },
];

export const parDiasComparar = (dia = null) => {
  if (!dia) {
    dia = moment();
  }
  var horaActual = dia.hour();

  let hora = store.state.horaInicio;
  var res = [];
  let m = dia.hour(parseInt(hora)).minute(0).seconds(0);
  if (horaActual >= hora) {
    let hoy = m.toDate();
    let tomorrow = m.add(1, 'days').toDate();
    res = [hoy, tomorrow];
  } else {
    let hoy = m.toDate();
    let ayer = m.subtract(1, 'days').toDate();
    res = [ayer, hoy];
  }
  return res;
};

/** [domicilio, recogida, apertura] */
export const arrayHorariosRest = (restObj) => {
  const horarios = restObj.horarios;
  const tipos = horarios.tipos;
  var d = new Date();
  let dia = d.getDay();
  dia = dia - 1;
  dia = dia == -1 ? 6 : dia;
  var devolver = [false, false, false]; //domicilio, recogida, apertura
  let horarioDia = horarios.semana[dia];
  if (horarioDia.domicilio) {
    let d = horarioDia.domicilio;
    let found = tipos.find((t) => t.nombre == d);
    if (found) {
      devolver[0] = found.rangos;
    }
  }
  if (horarioDia.recoger) {
    let d = horarioDia.recoger;
    let found = tipos.find((t) => t.nombre == d);
    if (found) {
      devolver[1] = found.rangos;
    }
  }
  if (horarioDia.apertura) {
    let d = horarioDia.apertura;
    let found = tipos.find((t) => t.nombre == d);
    if (found) {
      devolver[2] = found.rangos;
    }
  }
  return devolver;
};
