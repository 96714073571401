<template>
  <v-row id="comanderos">
    <v-col
      cols="12"
      class="pb-0"
    >
      <h1 class="mb-4 mt-2 display-1 text-center">Comanderos</h1>
    </v-col>
    <v-col
      cols="12"
      md="8"
      xl="6"
      class="mx-auto pt-0"
    >
      <v-list
        elevation="1"
        class="rounded-lg"
      >
        <v-list-item
          v-for="c in comanderos"
          :key="c.uid"
          :class="c > 1 ? 'border--list-item' : ''"
        >
          <v-list-item-title>{{ c.nombre }}</v-list-item-title>
          <v-list-item-action>
            <v-btn
              rounded
              large
              color="primary"
              class="black--text"
              @click="abrirLoginComandero(c)"
            ><v-icon :large="$vuetify.breakpoint.mdAndUp">mdi-qrcode-scan</v-icon></v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-dialog
        :fullscreen="$vuetify.breakpoint.smAndDown"
        v-model="loginComandero"
        max-width="400"
        overlay-opacity="0.90"
        content-class="elevation-0"
      >
        <v-card
          class="transparent"
          elevation="0"
        >
          <v-card-title
            :class="$vuetify.breakpoint.smAndDown
              ? 'rounded-0'
              : 'rounded-t-lg'
              "
            class="py-3 pr-3 white"
          >
            <h2
              :class="$vuetify.breakpoint.smAndDown ? 'caption'
                : $vuetify.breakpoint.mdAndDown
                  ? 'title'
                  : 'display-1'"
              class="font-weight-black"
            >Inicio de sesión</h2>
            <v-spacer />
            <v-btn
              fab
              :height="$vuetify.breakpoint.smAndDown ? 48 : 68"
              :width="$vuetify.breakpoint.smAndDown ? 48 : 68"
              color="primary"
              @click="loginComandero = false"
              top
              right
            >
              <v-icon
                :size="$vuetify.breakpoint.smAndDown ? 36 : 44"
                color="black"
              >mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="blue-grey lighten-5 pt-4 rounded-b-lg">
            Escanea el siguiente código QR para iniciar sesión con tu usuario.
            <div class="text-center mt-8">
              <vue-qr
                :text="urlLogin"
                :size="200"
              ></vue-qr>
              {{ urlLogin }}
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>


<script>
import { mapState } from "vuex";
import VueQr from "vue-qr";
export default {
  components: { VueQr },
  data() {
    return {
      loginComandero: false,
      urlLogin: "",
    };
  },
  computed: {
    ...mapState(["datosTpv"]),
    comanderos() {

      return this.datosTpv.cuentas
        .filter((c) => c.role == "tpv-comandero")
        .map((c) => {
          return c;
        });
    },
  },
  methods: {
    abrirLoginComandero(c) {
      this.$store.dispatch("permitirLoginComandero", c.id).then((r) => {
        this.loginComandero = true;
        let rest = this.$store.state.auth.user.restauranteTpv;
        var hostAux = window.location.href.split("/");
        var host = hostAux[0] + "//" + hostAux[2];

        this.urlLogin =
          host + "/iniciocomandero/" + rest + "/" + c.id + "/" + r;
      });
    },
  },
};
</script>

<style></style>