<template>
  <div>
    <v-row class="">
      <v-col
        cols="6"
        sm="4"
        md="2"
        class="pa-2"
      >
        <v-hover v-slot:default="{ hover }">
          <v-card
            :disabled="disabledNuevo"
            elevation="4"
            class="rounded-lg ficha-generica"
            @click="nuevoPedido"
            :color="hover ? 'primary lighten-4' : 'primary borde-blanco'"
          >
            <v-responsive
              :aspect-ratio="1.618"
              class="align-center"
            >
              <v-card-text
                class="text-uppercase font-weight-black text-center"
                :class="$vuetify.breakpoint.mdAndDown ? '' : 'text-h4'"
              >
                <v-row
                  justify="center"
                  align="center"
                  style="flex-direction: column"
                >
                  <v-btn
                    v-if="!disabledNuevo"
                    outlined
                    fab
                    class=""
                    x-small
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                  <div class="font-weight-bold body-2 black--text">
                    <span v-if="!disabledNuevo">Nuevo Pedido interno</span>
                    <span v-else>(Pedido en curso)</span>
                  </div>
                </v-row>
              </v-card-text>
            </v-responsive>
          </v-card>
        </v-hover>
      </v-col>

      <v-col
        v-for="ped in pedidos"
        :key="ped.id"
        cols="6"
        sm="4"
        md="2"
        class="pa-2"
      >
        <v-hover v-slot:default="{ hover }">
          <v-card
            elevation="4"
            class="rounded-lg"
            :class="ped.clase"
            @click="goPedido(ped)"
            :color="hover ? 'secondary lighten-4' : 'borde-blanco'"
          >
            <v-responsive
              :aspect-ratio="1.618"
              class="align-center"
            >
              <v-img :src="ped.img">
                <v-card-text
                  style="height: 100%"
                  class="text-uppercase font-weight-black text-center pa-0"
                >
                  <v-row
                    class="pa-0 ma-0"
                    style="height: 100%"
                  >
                    <v-col class="font-weight-bold body-2 my-auto">
                      <span>{{ ped.fechaInicio }}</span>
                    </v-col>
                  </v-row>
                  <div
                    class="
                      card--numero-pedido
                      mt-lg-2
                      mr-lg-1
                      pa-2
                      font-weight-black
                      cyan--text
                      text--lighten-2
                    "
                    :style="$vuetify.breakpoint.mdAndDown
                        ? 'font-size: 2rem !important;'
                        : $vuetify.breakpoint.lgAndUp
                          ? 'font-size: 3.2rem !important;'
                          : ''
                      "
                  >
                    <span v-if="ped.numPedidoInternos <= 9">0</span>{{ ped.numPedidoInternos }}
                  </div>
                </v-card-text>
              </v-img>
            </v-responsive>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <v-footer
      height="78"
      app
      class="pa-0"
    >
      <v-toolbar
        dark
        height="78"
        flat
        class="ma-0"
        fluid
      > </v-toolbar>
    </v-footer>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  watch: {
    paso(v) {
      if (v == "listado") {
        this.$store.dispatch("internos/getPedidos");
      }
    },
  },
  computed: {
    disabledNuevo() {
      return (
        this.pedidos.filter((p) => p.estado.value == "realizando").length > 0
      );
    },
    ...mapState([
      "modoPaginacion",
      "pedidosPaginacionPedidos",
      "paginaPaginacionPedidos",
    ]),
    ...mapGetters({
      pedidos: "internos/getPedidosListado",
    }),
    paso: {
      get() {
        return this.$store.state.internos.paso;
      },
      set(v) {
        this.$store.commit("internos/SET_PASO", v);
      },
    },
  },
  methods: {
    getPedidos() {
      var _vm = this;
      _vm.loading = true;
      this.$store
        .dispatch("internos/getPedidos")
        .then(() => { })
        .finally(() => {
          _vm.loading = false;
        });
    },
    nuevoPedido() {
      this.paso = "form";
    },
    goPedido(ped) {

      this.$store
        .dispatch("internos/getPedidoInterno", ped.id)
        .then((r) => {
          this.$store.commit("internos/setPedidoEnCurso", r);
          this.paso = "form";
        })
        .catch((e) => {
          console.log(e);
          this.$router.push("/pedidos-internos");
        });
    },
  },
  beforeDestroy() {
    this.$store.commit("setMostrarFabAdd", true);
  },
  mounted() {
    this.getPedidos();
    this.$store.commit("setMostrarFabAdd", false);
  },
};
</script>

<style lang="scss" scoped>.pedido-open {
  border: 2px solid #32cd32 !important;
}

.pedido-wait {
  border: 2px solid #87cefa !important;
}

.pedido-ready {
  border: 2px solid #ffa500 !important;
}

.pedido-close {
  border: 2px solid #a52a2a !important;
}</style>