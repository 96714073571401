const qz = require('qz-tray');
import { db, functions } from '@/firebase';

qz.security.setCertificatePromise(function (resolve, reject) {
  //Preferred method - from server
  //        fetch("assets/signing/digital-certificate.txt", {cache: 'no-store', headers: {'Content-Type': 'text/plain'}})
  //          .then(function(data) { data.ok ? resolve(data.text()) : reject(data.text()); });

  //Alternate method 1 - anonymous
  //        resolve();  // remove this line in live environment

  //Alternate method 2 - direct

  resolve(
    '-----BEGIN CERTIFICATE-----\n' +
      'MIIDrjCCApYCCQDcmE5pTv/CWzANBgkqhkiG9w0BAQsFADCBlzELMAkGA1UEBhMC\n' +
      'RVMxDzANBgNVBAgMBk1BTEFHQTETMBEGA1UEBwwKRlVFTkdJUk9MQTEUMBIGA1UE\n' +
      'CgwLTUVXT1NZU1RFTVMxEDAOBgNVBAsMB0ZVTkVBVFMxFDASBgNVBAMMC2Z1bmVh\n' +
      'dHMuYXBwMSQwIgYJKoZIhvcNAQkBFhVpbmZvQG13ZXdvc3lzdGVtcy5jb20wIBcN\n' +
      'MjIwNTEzMTYxMzI5WhgPMjA1MzExMDUxNjEzMjlaMIGXMQswCQYDVQQGEwJFUzEP\n' +
      'MA0GA1UECAwGTUFMQUdBMRMwEQYDVQQHDApGVUVOR0lST0xBMRQwEgYDVQQKDAtN\n' +
      'RVdPU1lTVEVNUzEQMA4GA1UECwwHRlVORUFUUzEUMBIGA1UEAwwLZnVuZWF0cy5h\n' +
      'cHAxJDAiBgkqhkiG9w0BCQEWFWluZm9AbXdld29zeXN0ZW1zLmNvbTCCASIwDQYJ\n' +
      'KoZIhvcNAQEBBQADggEPADCCAQoCggEBAMjXhljAHHPi4sT2nmVfbFP5n9TOBFSd\n' +
      'grux40LZMYY0TQvrtsi3zjyvZ3M4kVWKgiwhD6Jpm5wkjXwCwIPCKH4TuZ6BJ1rO\n' +
      'AAhuoolryE2oKxgyKtQffsYOLBzVRqTXunNsosInyVtXLCH9Q3+aQpjffCEgtsyY\n' +
      'ypHkko2qQrdHJOVfEBHNqFeZ89GSxeX7GPzKVmMV4LGTHqqEo2O62o6/k6yVYsim\n' +
      '2D5NfByX7IADVXkPGAnTwMirUqBLxlMjDFwUJ+aYBpBCKTHDIqG89LMc2Mys4UYC\n' +
      'vcUgvywrxt84yL4u0Xyulm2jTjBTyns5aygolpYFxPwXOUNJdtKtA5MCAwEAATAN\n' +
      'BgkqhkiG9w0BAQsFAAOCAQEAbGLuff7o7PY8zS0BS3DqoaFxyB+hzxKyn7xr/9Aj\n' +
      'NH2LwOmIfwHgZctnmLC+HwIMcAlKoYNAVOLPZdGHS6/XlQKovWAfdgngGj5AI91y\n' +
      'tLgrANlx1uQIHAzm6a8SlFnYG472Du1Pcm5bu54zfuEy3QrcCiIatYt9Vi0WpCyY\n' +
      'KgGziEopeMgLi6zzifflbWbeB0Et9t9H3kKA/u4HO8oN0hSn3+0xxeGVttiOE18z\n' +
      'gNVdBkqsTovkqJDYZJD+92zNZvAOuoLQsR8mmpdupe+LQZNX1nuXdq1Jl6+H/FwK\n' +
      'uoMVeg5HWEICE1eTccNUgFhqJIdLq5QovW1NEld63L5GDA==\n' +
      '-----END CERTIFICATE-----\n' +
      ''
  );
});

qz.security.setSignatureAlgorithm('SHA512'); // Since 2.1

function launchQZ() {
  if (!qz.websocket.isActive()) {
    window.location.assign('qz:launch');
    //Retry 5 times, pausing 1 second between each attempt
    startConnection({ retries: 5, delay: 1 });
  }
}

function startConnection(config) {
  let host = '';
  let usingSecure = true;
  // Connect to a print-server instance, if specified
  if (host != '' && host != 'localhost') {
    if (config) {
      config.host = host;
      config.usingSecure = usingSecure;
    } else {
      config = { host: host, usingSecure: usingSecure };
    }
  }
  if (!qz.websocket.isActive()) {
    // updateState('Waiting', 'default');

    return qz.websocket
      .connect(config)
      .then(function () {
        // updateState('Active', 'success');
        // findVersion();
      })
      .catch(handleConnectionError);
  } else {
    // displayMessage('An active connection with QZ already exists.', 'alert-warning');
  }
}

function endConnection() {
  if (qz.websocket.isActive()) {
    qz.websocket
      .disconnect()
      .then(function () {
        // updateState('Inactive', 'default');
      })
      .catch(handleConnectionError);
  } else {
    // displayMessage('No active connection with QZ exists.', 'alert-warning');
  }
}

function handleConnectionError(err) {
  console.log(err);
  // updateState('Error', 'danger');

  // if (err.target != undefined) {
  //     if (err.target.readyState >= 2) { //if CLOSING or CLOSED
  //         displayError("Connection to QZ Tray was closed");
  //     } else {
  //         displayError("A connection error occurred, check log for details");
  //         console.error(err);
  //     }
  // } else {
  //     displayError(err);
  // }
}

export default {
  conectar({ rootState, dispatch, commit }) {
    qz.file.setFileCallbacks(async function (streamEvent) {
      if (streamEvent.type !== 'ERROR') {
        let arrayAux = streamEvent.file.split('\\');
        let fichero = arrayAux[arrayAux.length - 1];

        let ficheroPartido = fichero.split('.');
        let ficheroEstadoPartido =
          rootState.cashguard.ficheroCashguard.split('.');
        if (ficheroPartido[0] == ficheroEstadoPartido[0]) {
          let contenidoFichero = await dispatch('leerFichero', fichero);

          commit(
            'cashguard/SET_FICHERO_CASHGUARD_RESULTADO',
            ficheroPartido[1],
            {
              root: true,
            }
          );
          commit(
            'cashguard/SET_FICHERO_CASHGUARD_CONTENIDO_FICHERO',
            contenidoFichero,
            {
              root: true,
            }
          );
        }
        // Data is optional, toggled off by setting params to null
      } else {
        console.error(streamEvent.message);
      }
    });
    return launchQZ();
  },
  getPrinters({ rootState, rootGetters }) {
    return qz.printers.find().then((r) => {
      let id = rootGetters.cuenta.id;
      let obj = {};
      obj[id] = r;
      return db
        .collection('restaurantes')
        .doc(rootState.auth.user.restauranteTpv)
        .collection('tpv')
        .doc('secreto')
        .collection('dispositivosqz')
        .doc('impresoras')
        .set(obj, {
          merge: true,
        });
    });
  },
  async imprimirTicket(
    { dispatch, rootGetters },
    { data, impresora, logo, factura, copias = 1 }
  ) {
    // var config = qz.configs.create("Xprinter USB Printer P");
    if (!qz.websocket.isActive()) {
      await dispatch('conectar');
    }

    var config = qz.configs.create(
      impresora.replace('_' + rootGetters['cuenta'].id, ''),
      {
        copies: copias,
        colorType: 'grayscale',
      }
    );

    // let r = data.map(d => {
    //     return new TextDecoder().decode([d])
    // })
    // let r = new TextDecoder()
    //   .decode(data)
    //   .split("\n")
    //   .map((r) => {
    //     return r + "\n";
    //   });
    // console.log(r);
    var imprimir = data;

    return qz.print(config, imprimir).catch(function (e) {
      console.error(e);
    });
  },
  devices() {
    return qz.networking.devices().then((r) => {});
  },
  escribirFichero({}, { fichero, ruta }) {
    return qz.file
      .write('C:/ImaCG_Logic/Integration/' + ruta, {
        data: fichero,
        sandbox: false,
        shared: true,
      })
      .then((r) => {
        return r;
      });
  },
  leerFichero({}, ruta) {
    return qz.file
      .read('C:/ImaCG_Logic/Integration/' + ruta, {
        sandbox: false,
        shared: true,
      })
      .then((r) => {
        return r;
      });
  },
  listarFicheros({}) {
    return qz.file
      .list('C:/ImaCG_Logic/Integration', { sandbox: false, shared: true })
      .then((r) => {
        return r;
      });
  },
  escucharCambios({ rootState }) {
    return qz.file
      .startListening('C:/ImaCG_Logic/Integration')
      .then((r) => {})
      .catch((e) => {
        console.log(e);
      });
  },
  pararTodasEscucha() {
    return qz.file.stopListening().then((r) => {});
  },
};
