import moment from 'moment-timezone';
import { mostrarMoneda, redondeo } from '@/utils/helper.utils.js';
import { quitarAcentos, idToTexto, lineaQz } from './utils';

import { comandosF, valoresTicketDinamico } from './comandos';
export default function (obj) {
  const { pedidos, resumen, restaurante, fechas = null, logo, ticket } = obj;
  const ticketDinamico = valoresTicketDinamico(ticket);

  const comandos = comandosF(ticket);
  var logoImprimir = logo
    ? {
        type: 'raw',
        format: 'image',
        data: 'data:image/png;base64,' + logo,
        options: { language: 'ESCPOS', dotDensity: 'double' },
      }
    : false;
  var devolverQz = [comandos.QZ_INIT, comandos.QZ_ALIGN_CENTER];
  if (logoImprimir) devolverQz.push(logoImprimir);

  devolverQz.push(comandos.QZ_INIT);
  devolverQz.push(comandos.QZ_ALIGN_CENTER);
  devolverQz.push(
    lineaQz(
      restaurante.nombreNegocio == restaurante.nombre
        ? restaurante.nombre
        : restaurante.nombreNegocio + ' - ' + restaurante.nombre
    )
  );
  devolverQz.push(lineaQz(restaurante.direccion));
  devolverQz.push(lineaQz(restaurante.cif + ' | ' + restaurante.telefono));
  devolverQz.push(comandos.QZ_BOLD);
  devolverQz.push(comandos.QZ_BOLD);
  devolverQz.push(comandos.QZ_FUENTE_GRANDE);
  devolverQz.push(comandos.QZ_LINEA);
  devolverQz.push(comandos.QZ_INVERTIR);
  devolverQz.push(comandos.QZ_ALIGN_CENTER);
  devolverQz.push(lineaQz('RESUMEN CIERRE'));
  devolverQz.push(comandos.QZ_INIT);
  devolverQz.push(comandos.QZ_LINEA);
  devolverQz.push(comandos.QZ_BOLD);
  devolverQz.push(comandos.QZ_ALIGN_CENTER);
  devolverQz.push(comandos.QZ_FUENTE_NORMAL);
  devolverQz.push(
    lineaQz(
      moment(fechas[0], 'DD-MM-YYYY').format('DD-MM-YY') +
        ' | ' +
        moment(fechas[1], 'DD-MM-YYYY').format('DD-MM-YY')
    )
  );
  devolverQz.push(comandos.QZ_LINEA);
  devolverQz.push(comandos.QZ_BOLD);
  devolverQz.push(comandos.QZ_FUENTE_NORMAL);
  devolverQz.push(comandos.QZ_ALIGN_LEFT);
  if (ticketDinamico.mostrarNumTickets) {
    devolverQz.push(
      lineaQz(
        '#'.padEnd(ticketDinamico.resumenTicket) + // Para 80mm - 10  y para 58mm - 5
          'FECHA'.padEnd(ticketDinamico.resumenFecha) + // Para 80mm - 10  y para 58mm - 5
          'SUBTOTAL'.padStart(ticketDinamico.resumenTotales) + // Para 80mm - 12  y para 58mm - 8
          'IVA'.padStart(ticketDinamico.resumenTotales) + // Para 80mm - 18  y para 58mm - 11
          'TOTAL'.padStart(ticketDinamico.resumenTotales) // Para 80mm - 18  y para 58mm - 11
      )
    );
  } else {
    devolverQz.push(
      lineaQz(
        'FECHA'.padEnd(ticketDinamico.resumenFecha) + // Para 80mm - 10  y para 58mm - 5
          'SUBTOTAL'.padStart(ticketDinamico.resumenTotales) + // Para 80mm - 12  y para 58mm - 8
          'IVA'.padStart(ticketDinamico.resumenTotales) + // Para 80mm - 18  y para 58mm - 11
          'TOTAL'.padStart(ticketDinamico.resumenTotales) // Para 80mm - 18  y para 58mm - 11
      )
    );
  }

  devolverQz.push(comandos.QZ_LINEA_HORIZONTAL);
  resumen.forEach((ped) => {
    if (ped.contable)
      if (ticketDinamico.mostrarNumTickets) {
        devolverQz.push(
          lineaQz(
            ped.contable.tickets
              .toString()
              .padEnd(ticketDinamico.resumenTicket) +
              moment(ped.inicio, 'DD/MM/YYYY HH:mm')
                .format('DD/MM/YY')
                .padEnd(ticketDinamico.resumenFecha) +
              mostrarMoneda(ped.contable.subtotal.toString()).padStart(
                ticketDinamico.resumenTotales
              ) +
              mostrarMoneda(ped.contable.impuestos.toString()).padStart(
                ticketDinamico.resumenTotales
              ) +
              mostrarMoneda(ped.contable.total.toString()).padStart(
                ticketDinamico.resumenTotales
              )
          )
        );
      } else {
        devolverQz.push(
          lineaQz(
            moment(ped.inicio, 'DD/MM/YYYY HH:mm')
              .format('DD/MM/YY')
              .padEnd(ticketDinamico.resumenFecha) +
              mostrarMoneda(ped.contable.subtotal.toString()).padStart(
                ticketDinamico.resumenTotales
              ) +
              mostrarMoneda(ped.contable.impuestos.toString()).padStart(
                ticketDinamico.resumenTotales
              ) +
              mostrarMoneda(ped.contable.total.toString()).padStart(
                ticketDinamico.resumenTotales
              )
          )
        );
      }
  });

  devolverQz.push(comandos.QZ_LINEA);
  devolverQz.push(comandos.QZ_LINEA);
  devolverQz.push(comandos.QZ_LINEA);
  devolverQz.push(comandos.QZ_LINEA);
  devolverQz.push(comandos.QZ_LINEA);
  devolverQz.push(comandos.QZ_LINEA);
  devolverQz.push(comandos.QZ_LINEA);
  devolverQz.push(comandos.QZ_LINEA);
  devolverQz.push(comandos.QZ_CORTE);
  return devolverQz;
}
